import React from "react";

import { ReactComponent as BeyazEşyaIcon } from "../../assets/svgs/categories/beyazesyaicon.svg";
import { ReactComponent as DigerIcon } from "../../assets/svgs/categories/digericon.svg";
import { ReactComponent as ElektrikliEvAletleriIcon } from "../../assets/svgs/categories/elektrikliicon.svg";
import { ReactComponent as FotoKameraIcon } from "../../assets/svgs/categories/fotoicon.svg";
import { ReactComponent as KlimaIcon } from "../../assets/svgs/categories/klimaicon.svg";
import { ReactComponent as LaptopIcon } from "../../assets/svgs/categories/laptopicon.svg";
import { ReactComponent as OyunKonsollarıIcon } from "../../assets/svgs/categories/oyunicon.svg";
import { ReactComponent as TelefonTelefonAksesuarlarıIcon } from "../../assets/svgs/categories/telefonicon.svg";
import { ReactComponent as TVGörüntüSistemleriIcon } from "../../assets/svgs/categories/tvicon.svg";
import { ReactComponent as YazıcılarProjeksiyonIcon } from "../../assets/svgs/categories/yaziciicon.svg";

import categories from "@/lib/utils/data/categories";

function ProductIcon({ productType, ...props }) {
  const category = categories.find((c) => c.label === productType)?.id;

  if (category) {
    switch (category) {
      case 1:
        return <LaptopIcon {...props} />;
      case 2:
        return <YazıcılarProjeksiyonIcon {...props} />;
      case 3:
        return <TelefonTelefonAksesuarlarıIcon {...props} />;
      case 4:
        return <TVGörüntüSistemleriIcon {...props} />;
      case 5:
        return <BeyazEşyaIcon {...props} />;
      case 6:
        return <KlimaIcon {...props} />;
      case 7:
        return <ElektrikliEvAletleriIcon {...props} />;
      case 8:
        return <FotoKameraIcon {...props} />;
      case 9:
        return <OyunKonsollarıIcon {...props} />;
      case 10:
        return <DigerIcon {...props} />;
      default:
        return <DigerIcon {...props} />;
    }
  } else {
    return <DigerIcon {...props} />;
  }
}

export default ProductIcon;
