import { useToast } from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BrandsSection from "@/components/Auth/Login/BrandsSection";
import FeaturesSection from "@/components/Auth/Login/FeaturesSection";
import LoginHeroDesktop from "@/components/Auth/Login/LoginHeroDesktop";
import LoginHeroMobile from "@/components/Auth/Login/LoginHeroMobile";
import LoginModal from "@/components/Auth/Login/LoginModal";
import OtpVerifyDialog from "@/components/Auth/Login/OtpVerifyDialog";
import ServicesSection from "@/components/Auth/Login/ServicesSection";
import Footer from "@/components/common/Footer";

import * as yup from "yup";

import axios from "@/lib/api/axios";
import { parseError } from "@/lib/utils";
import useUser from "@/lib/utils/hooks/use-user";

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [open, onOpenChange] = useState(false);
  const [openOtpVerify, onOpenOtpVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginMethod, setLoginMethod] = useState(
    localStorage.getItem("userCountry") === "UZ" ? 1 : 0,
  );
  const [otpData, setOtpData] = useState(null);

  const loginFormSchemaPhone = yup.object().shape({
    countryCode: yup
      .string()
      .required(t("common.formErrors.countryCodeRequired")),
    phone: yup.string().required(t("common.formErrors.phoneRequired")),
    //.max(10, t("common.formErrors.phoneMax")),
  });

  const loginFormSchemaEmail = yup.object().shape({
    email: yup
      .string(t("common.formErrors.emailInvalid"))
      .email(t("common.formErrors.emailInvalid"))
      .required(t("common.formErrors.emailRequired")),
  });

  const form = useForm({
    resolver: yupResolver(
      loginMethod === 0 ? loginFormSchemaPhone : loginFormSchemaEmail,
    ),
    defaultValues: {
      countryCode: "TR",
      phone: "",
      email: "",
    },
  });

  useEffect(() => {
    if (loginMethod === 0) {
      form.reset({ email: "", countryCode: "TR", phone: "" }); // Sadece loginMethod değiştiğinde resetle
    } else {
      form.reset({ email: "", countryCode: "TR", phone: "" });
    }
  }, [loginMethod, form]);

  const handleLoginPhoneMethod = (data) => {
    setLoading(true);

    const body = {
      countryCode: data.countryCode,
      phone: data.phone,
    };

    axios
      .post(`/otp/generate`, body)
      .then((res) => {
        setOtpData({
          email: data.email,
          phone: data.phone,
          countryCode: data.countryCode,
        });
        onOpenOtpVerify(true);
      })
      .catch((err) => {
        const errorText = parseError(err.response?.data?.errors);
        toast({
          description: errorText,
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLoginEmailMethod = (data) => {
    setLoading(true);

    const body = {
      email: data.email,
    };

    axios
      .post(`/otp/generate`, body)
      .then((res) => {
        setOtpData({
          email: data.email,
          phone: data.phone,
          countryCode: data.countryCode,
        });
        onOpenOtpVerify(true);
      })
      .catch((err) => {
        const errorText = parseError(err.response.data.errors);
        toast({
          description: errorText,
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onVerify = async (otpCode) => {
    setLoading(true);
    try {
      const res = await axios.post("/otp/login", {
        email: otpData.email,
        phone: otpData.phone,
        countryCode: otpData.countryCode,
        otpCode: otpCode,
      });

      localStorage.setItem("token", res.data.jwtToken.substring(7));
      toast({
        description: t("authPages.loginSuccess"),
        variant: "success",
      });
      setTimeout(() => {
        window.location.href = location.state?.from || "/";
      }, 1250);
    } catch (err) {
      toast({
        description: t("authPages.invalidOtp"),
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    loginMethod === 0
      ? handleLoginPhoneMethod(data)
      : handleLoginEmailMethod(data);
  };

  const triggerLoginModal = () => onOpenChange(true);

  const { user } = useUser();

  if (user) navigate("/");

  return (
    <>
      <Helmet>
        <title>{t("authPages.egarantiLogin")}</title>
        <meta
          name="description"
          content="egaranti tüketicilerin garanti süreçlerini tamamen ücretsiz bir şekilde dijital olarak yönetebilmeleri için firmalara yazılım çözümü sağlar."
        />
      </Helmet>
      <div className="flex min-h-screen w-full flex-col bg-[#F9FAFC]">
        <LoginHeroMobile
          form={form}
          loginMethod={loginMethod}
          setLoginMethod={setLoginMethod}
          onSubmit={onSubmit}
          loading={loading}
        />
        <LoginHeroDesktop
          form={form}
          loginMethod={loginMethod}
          setLoginMethod={setLoginMethod}
          onSubmit={onSubmit}
          loading={loading}
        />
        <main className="mx-auto mt-12 flex w-full max-w-6xl flex-col gap-9 p-6 ">
          <ServicesSection triggerLoginModal={triggerLoginModal} />
          <BrandsSection />
          <FeaturesSection triggerLoginModal={triggerLoginModal} />
          <LoginModal
            loginMethod={loginMethod}
            setLoginMethod={setLoginMethod}
            onSubmit={onSubmit}
            open={open}
            onOpenChange={onOpenChange}
            form={form}
            loading={loading}
          />
          {openOtpVerify && (
            <OtpVerifyDialog
              email={otpData.email}
              phone={otpData.phone}
              countryCode={otpData.countryCode}
              open={openOtpVerify}
              onOpenChange={onOpenOtpVerify}
              onVerify={onVerify}
              loading={loading}
            />
          )}
        </main>
        <Footer />
      </div>
    </>
  );
}
