import axios from "./axios";

export const getAddress = (id) => {
  return axios.get(`/individual-customer-addresses/${id}`);
};

export const getAllAddress = () => {
  return axios.get(`/individual-customer-addresses`);
};

export const deleteAddress = (id) => {
  return axios.delete(`/individual-customer-addresses/${id}`);
};

export const postAddress = (data) => {
  return axios.post(`/individual-customer-addresses`, emptyStringToNull(data));
};

export const putAddress = (addressId, data) => {
  return axios.put(
    `/individual-customer-addresses/${addressId}`,
    emptyStringToNull(data),
  );
};

export const emptyStringToNull = (data) => {
  for (let key in data) {
    if (data[key] === "") {
      data[key] = null;
    }
  }
  return data;
};
