import React from "react";
import { useNavigate } from "react-router-dom";

import axios from "../../lib/api/axios";
import { ReactComponent as XIcon } from "../../assets/svgs/xicon.svg";

import moment from "moment";

const NotificationItem = ({
  notification,
  handleReadOneNotification,
  getAllNotifications,
  getUnreadNotificationsCount,
}) => {
  const navigateTo = useNavigate();

  const handleClickNotification = () => {
    handleReadOneNotification(notification.id);
    if (notification?.type === "SERVICE_CLAIM") {
      navigateTo(`/claims/service/${notification?.resourceId}`);
    } else if (notification?.type === "SETUP_CLAIM") {
      navigateTo(`/claims/setup/${notification?.resourceId}`);
    } else if (notification?.type === "SERVICE_RECORD") {
      navigateTo(`/claims/service-record/${notification?.resourceId}`);
    }
  };

  const handleDeleteNotification = () => {
    axios
      .delete(`/individual-customer-notifications/${notification.id}`)
      .then((res) => {
        getAllNotifications();
        getUnreadNotificationsCount();
      });
  };

  return (
    <>
      <div
        onClick={() => {
          handleClickNotification();
        }}
        className={`relative flex cursor-pointer items-start justify-between gap-3 border-b border-[#E3E7EF] bg-white px-4 py-6 last-of-type:border-none last-of-type:pb-0 ${
          notification.status === "UNREAD" ? "!bg-[#F7F9FC]" : ""
        }`}
      >
        {/* Delete button */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteNotification();
          }}
          className="not-sr-only absolute right-2 top-4 flex items-center  justify-center rounded-full p-2"
        >
          <XIcon className="h-3 w-3" />
        </button>
        {notification.status === "UNREAD" && (
          <span className="not-sr-only absolute left-2 top-4 h-2 w-2 rounded-full bg-[#12B76A]"></span>
        )}
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18" cy="18" r="18" fill="#12B76A" />
          <path
            d="M23.455 16.2953V14.4544C23.455 13.3088 23.455 12.736 23.232 12.2985C23.0359 11.9136 22.723 11.6007 22.3382 11.4046C21.9006 11.1816 21.3278 11.1816 20.1823 11.1816H15.8186C14.6731 11.1816 14.1003 11.1816 13.6627 11.4046C13.2779 11.6007 12.9649 11.9136 12.7688 12.2985C12.5459 12.736 12.5459 13.3088 12.5459 14.4544V21.5453C12.5459 22.6908 12.5459 23.2636 12.7688 23.7012C12.9649 24.086 13.2779 24.399 13.6627 24.5951C14.1003 24.818 14.6731 24.818 15.8186 24.818H19.3641M19.3641 17.318H15.2732M16.6368 20.0453H15.2732M20.7277 14.5907H15.2732M21.0686 20.0468C21.1888 19.7053 21.4259 19.4173 21.738 19.2339C22.0501 19.0505 22.4171 18.9834 22.7739 19.0446C23.1307 19.1058 23.4543 19.2913 23.6875 19.5683C23.9206 19.8452 24.0482 20.1958 24.0477 20.5578C24.0477 21.5797 22.5147 22.0907 22.5147 22.0907M22.5345 24.1362H22.5413"
            stroke="#ECFDF3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div className="flex w-full flex-col justify-between gap-1">
          <h2 className="font-medium text-[#111729]">{notification?.title}</h2>
          <p className="text-[#111729]">{notification?.message}</p>
          <span className="text-sm text-[#677389]">
            {moment(notification?.createdAt).locale("tr").fromNow()}
          </span>
        </div>
      </div>
    </>
  );
};

export default NotificationItem;
