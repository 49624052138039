import { Button } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

import LoginForm from "./LoginForm";
import LoginMethodSwitcher from "./LoginMethodSwitcher";

const LoginCard = ({
  form,
  loginMethod,
  setLoginMethod,
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="m-5 flex w-full max-w-sm flex-col gap-4 rounded-lg bg-white p-4 shadow-sm">
      <h2 className="text-xl font-semibold">{t("authPages.login")}</h2>
      <h3 className="text-[#677389]">{t("authPages.manageAll")}</h3>
      <LoginMethodSwitcher
        setLoginMethod={setLoginMethod}
        loginMethod={
          localStorage.getItem("userCountry") === "UZ" ? 1 : loginMethod
        }
        disabled={localStorage.getItem("userCountry") === "UZ"}
      />
      <LoginForm onSubmit={onSubmit} loginMethod={loginMethod} form={form} />
      <Button onClick={form.handleSubmit(onSubmit)} disabled={loading}>
        {t("authPages.login")}
      </Button>
      <span className="text-center text-[14px] text-[#667085]" href="/register">
        {t("authPages.dontYouHaveAnAccount")}
        <a className="ml-1 text-[#0049E6]" href="/register">
          {t("authPages.register")}
        </a>
      </span>
    </div>
  );
};

export default LoginCard;
