import { Label } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

import LanguageSwitcherForPage from "@/components/common/LanguageSwitcher/LanguageSwitcherForPage";

const LanguagePage = () => {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen bg-[#f9fafc] p-7">
      <Label>{t("accountPage.card3Title")}</Label>
      <LanguageSwitcherForPage />
    </div>
  );
};

export default LanguagePage;
