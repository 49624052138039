import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
} from "@egaranti/components";

import { useTranslation } from "react-i18next";

import moment from "moment";

/* eslint-disable react/prop-types */
const PreviewClaimDialog = ({
  onOpenChange,
  open,
  handleCreateClaim,
  selectedProduct,
  availableDates,
}) => {
  const { t } = useTranslation();

  return (
    <AlertDialog onOpenChange={onOpenChange} open={open}>
      <AlertDialogContent className="max-w-[388px] gap-0 px-4">
        <div className="flex flex-col items-center gap-4 p-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="72"
            height="72"
            fill="none"
            viewBox="0 0 72 72"
          >
            <rect
              width="64"
              height="64"
              x="4"
              y="4"
              fill="#ECFDF3"
              rx="32"
            ></rect>
            <path
              stroke="#12B76A"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
              d="M48 33.333H24m24 3.334v-4.934c0-2.24 0-3.36-.436-4.216a4 4 0 00-1.748-1.748c-.856-.436-1.976-.436-4.216-.436H30.4c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748C24 28.373 24 29.493 24 31.733v11.2c0 2.24 0 3.36.436 4.216a4 4 0 001.748 1.748c.856.436 1.976.436 4.216.436H36m5.333-26.666V28m-10.666-5.334V28m8.666 17.333L42 48l6-6"
            ></path>
            <rect
              width="64"
              height="64"
              x="4"
              y="4"
              stroke="#D1FADF"
              strokeWidth="8"
              rx="32"
            ></rect>
          </svg>
          <h1 className="text-center font-semibold text-[#111729]">
            {t("claimsPage.createSetupClaimRequest")}
          </h1>
          <span className="font-semibold text-[#4A5567]">
            {t("claimsPage.company")}: {selectedProduct.merchantName}
          </span>
          <span className="font-semibold text-[#4A5567]">
            {t("claimsPage.selectedDates")}{" "}
          </span>
          <ul className="flex list-disc flex-col gap-2 text-[#4A5567]">
            {availableDates.map((item, index) => {
              return (
                <li key={index}>
                  <span className="text-sm font-medium">
                    {moment(item.day).format("DD.MM.YYYY")} -{" "}
                    {item.morning ? "08.00-12.00" : ""}{" "}
                    {item.afternoon ? "12.00-16.00" : ""}{" "}
                    {item.evening ? "16.00-20.00" : ""}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <AlertDialogFooter className="m-0 mt-6">
          <AlertDialogCancel className="w-full">
            {t("common.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              className="w-full"
              onClick={() => {
                handleCreateClaim();
                onOpenChange();
              }}
            >
              {t("common.confirm")}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default PreviewClaimDialog;
