import React from "react";

import { motion } from "framer-motion";
import { t } from "i18next";

const Stepper = ({
  steps = [
    { title: t("productsPage.productSelection") },
    { title: t("productsPage.planSelection") },
  ],
  step = 0,
}) => {
  return (
    <div className="mx-auto my-10 flex w-full max-w-sm select-none items-center justify-center gap-16 md:gap-24">
      {steps.map((option, index) => (
        <div
          className={`flex items-center justify-between opacity-60 ${
            step >= index && "!opacity-100"
          }`}
          key={index}
        >
          <div className="flex flex-col items-center gap-2">
            <div
              style={{ backgroundColor: step > index && "#12B76A" }}
              className="relative flex h-8 w-8 items-center justify-center rounded-full bg-[#E3E7EF]"
            >
              <div
                className="z-20 flex h-8 w-8 items-center justify-center rounded-full"
                style={{ backgroundColor: step > index && "#12B76A" }}
              >
                {step > index ? <GreenCheck /> : <BlackDot />}
              </div>
              {index !== steps.length - 1 && (
                <div
                  style={{ backgroundColor: step > index && "#111729" }}
                  className="absolute left-0 top-4 z-0 h-1 w-[150px] bg-[#E3E7EF] md:w-[190px]"
                />
              )}
            </div>
            <span className="text-nowrap text-sm font-medium text-[#111729] md:text-base">
              {option.title}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;

const BlackDot = () => {
  return (
    <div className="z-10 flex h-6 w-6 items-center justify-center rounded-full bg-[#F2F5F9]">
      <div className="h-2 w-2 rounded-full bg-[#111729]" />
    </div>
  );
};

const GreenCheck = () => {
  return (
    <motion.svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        initial={{ fillOpacity: 0 }}
        animate={{ fillOpacity: 1 }}
        transition={{ duration: 0.75 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4319 6.67058L8.96877 12.908L7.25371 11.0756C6.93777 10.7777 6.44131 10.7597 6.08024 11.0124C5.7282 11.2742 5.62891 11.7345 5.84555 12.1046L7.87655 15.4084C8.07513 15.7153 8.41815 15.9049 8.80629 15.9049C9.17639 15.9049 9.52843 15.7153 9.72701 15.4084C10.052 14.9841 16.2533 7.5913 16.2533 7.5913C17.0657 6.76084 16.0818 6.02968 15.4319 6.66155V6.67058Z"
        fill="white"
      />
    </motion.svg>
  );
};
