import React from "react";

import { ReactComponent as EgarantiLogoWhite } from "../../../assets/svgs/egarantilogowhite.svg";
import LoginCard from "./LoginCard";

import LanguageSwitcher from "@/components/common/LanguageSwitcher";

import bannerTexture from "@/assets/images/bannertexture.png";
import bannerImg1 from "@/assets/images/loginbanner.png";

const LoginHeroDesktop = ({
  form,
  loginMethod,
  setLoginMethod,
  onSubmit,
  loading,
}) => {
  return (
    <div
      style={{
        background: `linear-gradient(to right, rgba(12, 31, 73, 1), rgba(16, 84, 224, 0.8), rgba(125, 156, 224, 0.8)), url(${bannerTexture})`,
      }}
      className="group hidden flex-col  items-start rounded-b-2xl p-20 pt-6 md:flex"
    >
      <header className="mx-auto flex w-full max-w-7xl items-center justify-between pb-12">
        <EgarantiLogoWhite className="w-32" />
        <div className="mr-4 rounded-lg bg-white">
          <LanguageSwitcher className="text-white" />
        </div>
      </header>
      <div className="mx-auto flex w-full max-w-7xl items-center justify-between">
        <img
          className="w-80 transition-transform duration-500 group-hover:scale-110 lg:w-96"
          src={bannerImg1}
          alt="egarantiye hoş geldin"
        />
        <LoginCard
          form={form}
          loginMethod={loginMethod}
          setLoginMethod={setLoginMethod}
          onSubmit={onSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default LoginHeroDesktop;
