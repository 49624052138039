import React from "react";

import { motion } from "framer-motion";

const MessageBox = ({ message }) => {
  const downloadFile = (path) => {
    window.open(path, "_blank");
  };

  return (
    <div
      key={message.id}
      className="flex flex-col rounded-lg bg-[#F9FAFC] py-4"
    >
      <div className="flex cursor-pointer items-center justify-start p-4">
        <div className="ml-2 flex items-center gap-4">
          <h3 className="flex gap-2 text-sm font-semibold text-[#111729]">
            {message.sender === "MERCHANT" && (
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.25033 8.16667H3.83366C3.36695 8.16667 3.13359 8.16667 2.95533 8.25749C2.79853 8.33739 2.67105 8.46487 2.59115 8.62167C2.50033 8.79993 2.50033 9.03329 2.50033 9.5V16.5M13.7503 8.16667H16.167C16.6337 8.16667 16.8671 8.16667 17.0453 8.25749C17.2021 8.33739 17.3296 8.46487 17.4095 8.62167C17.5003 8.79993 17.5003 9.03329 17.5003 9.5V16.5M13.7503 16.5V4.16667C13.7503 3.23325 13.7503 2.76654 13.5687 2.41002C13.4089 2.09641 13.1539 1.84144 12.8403 1.68166C12.4838 1.5 12.0171 1.5 11.0837 1.5H8.91699C7.98357 1.5 7.51686 1.5 7.16034 1.68166C6.84674 1.84144 6.59177 2.09641 6.43198 2.41002C6.25033 2.76654 6.25033 3.23325 6.25033 4.16667V16.5M18.3337 16.5H1.66699M9.16699 4.83333H10.8337M9.16699 8.16667H10.8337M9.16699 11.5H10.8337"
                  stroke="#111729"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            {message.sender === "MERCHANT"
              ? message.merchantName
              : message.individualCustomerFullName}
          </h3>
          <span className="text-xs text-[#677389]">{message.createdAt}</span>
        </div>
      </div>
      <motion.div className="overflow-hidden">
        <div className={`flex flex-col gap-5 rounded-lg px-8 pb-5`}>
          {message.message && (
            <p className="mt-4 break-words">{message.message}</p>
          )}
          {/* File */}
          {message?.path && (
            <div className="my-4 w-full max-w-[400px] rounded-lg border border-[#E3E7EF] bg-white p-4">
              <div
                onClick={() => downloadFile(message.path)}
                aria-label="Dosya indir"
                role="button"
                className="flex items-center justify-between gap-2"
              >
                <div className="flex items-center gap-3">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="32"
                      height="32"
                      rx="16"
                      fill="#D1FADF"
                    />
                    <path
                      d="M20.6666 12.6663C21.2866 12.6663 21.5966 12.6663 21.8509 12.7345C22.5411 12.9194 23.0802 13.4585 23.2651 14.1487C23.3333 14.403 23.3333 14.713 23.3333 15.333V21.4663C23.3333 22.5864 23.3333 23.1465 23.1153 23.5743C22.9236 23.9506 22.6176 24.2566 22.2413 24.4484C21.8134 24.6663 21.2534 24.6663 20.1333 24.6663H15.8666C14.7465 24.6663 14.1865 24.6663 13.7586 24.4484C13.3823 24.2566 13.0764 23.9506 12.8846 23.5743C12.6666 23.1465 12.6666 22.5864 12.6666 21.4663V15.333C12.6666 14.713 12.6666 14.403 12.7348 14.1487C12.9197 13.4585 13.4588 12.9194 14.149 12.7345C14.4033 12.6663 14.7133 12.6663 15.3333 12.6663M16 19.9997L17.3333 21.333L20.3333 18.333M16.4 13.9997H19.6C19.9733 13.9997 20.16 13.9997 20.3026 13.927C20.4281 13.8631 20.53 13.7611 20.594 13.6357C20.6666 13.4931 20.6666 13.3064 20.6666 12.933V12.3997C20.6666 12.0263 20.6666 11.8396 20.594 11.697C20.53 11.5716 20.4281 11.4696 20.3026 11.4057C20.16 11.333 19.9733 11.333 19.6 11.333H16.4C16.0266 11.333 15.8399 11.333 15.6973 11.4057C15.5719 11.4696 15.4699 11.5716 15.406 11.697C15.3333 11.8396 15.3333 12.0263 15.3333 12.3997V12.933C15.3333 13.3064 15.3333 13.4931 15.406 13.6357C15.4699 13.7611 15.5719 13.8631 15.6973 13.927C15.8399 13.9997 16.0266 13.9997 16.4 13.9997Z"
                      stroke="#12B76A"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="2"
                      y="2"
                      width="32"
                      height="32"
                      rx="16"
                      stroke="#ECFDF3"
                      strokeWidth="4"
                    />
                  </svg>
                  <div className="flex flex-col gap-1">
                    <p className="text-[#111729]">Belge</p>
                    <span className="text-sm text-[#677389]"></span>
                  </div>
                </div>
                <button aria-label="Dosyayı indir">
                  <svg
                    width="18"
                    height="18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.667 7.333 16.5 1.5m0 0h-5m5 0v5m-9.167 4.167L1.5 16.5m0 0h5m-5 0v-5"
                      stroke="#111729"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          )}
          {/* File */}
        </div>
      </motion.div>
    </div>
  );
};

export default MessageBox;
