import { Button, Tag } from "@egaranti/components";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ProductIcon from "../ProductIcon";

const ProductDetails = ({ product, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (loading) {
    return (
      <div className="animate-pulse flex flex-col items-center justify-center border-b pb-8 pt-2">
        <div className="h-16 w-16 rounded-full bg-gray-200"></div>
        <div className="mt-3 flex w-full flex-col items-center gap-2">
          <div className="h-4 w-32 rounded bg-gray-200"></div>
          <div className="h-6 w-48 rounded bg-gray-200"></div>
          <div className="mt-2 h-8 w-40 rounded bg-gray-200"></div>
          <div className="mt-2 h-10 w-32 rounded bg-gray-200"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center border-b pb-8 pt-2">
      <ProductIcon productType={product.category} />
      <div className="mt-3 flex flex-col gap-2">
        <h2 className="text-center text-base font-medium text-[#677389]">
          {t(
            product?.category?.split(">").pop().trim() || product?.categoryName,
          )}
        </h2>
        <h2 className="text-center text-xl font-semibold">{product.name}</h2>
        {product.extendedWarranty?.extendedWarrantyYear && (
          <div className="flex flex-col gap-2">
            <Tag className="mt-2" size="sm" variant="green">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49935 10.1667V6.16666M6.49935 8.16666H10.4993M13.8327 8.5C13.8327 11.7723 10.2634 14.1523 8.96468 14.9099C8.81708 14.996 8.74328 15.0391 8.63914 15.0614C8.55831 15.0787 8.44039 15.0787 8.35956 15.0614C8.25541 15.0391 8.18162 14.996 8.03402 14.9099C6.73532 14.1523 3.16602 11.7723 3.16602 8.5V5.31173C3.16602 4.77872 3.16602 4.51222 3.25319 4.28313C3.3302 4.08076 3.45534 3.90018 3.61779 3.75702C3.80168 3.59495 4.05121 3.50138 4.55029 3.31423L8.12482 1.97378C8.26341 1.9218 8.33271 1.89582 8.404 1.88552C8.46724 1.87638 8.53146 1.87638 8.59469 1.88552C8.66598 1.89582 8.73528 1.9218 8.87388 1.97378L12.4484 3.31423C12.9475 3.50138 13.197 3.59495 13.3809 3.75702C13.5434 3.90018 13.6685 4.08076 13.7455 4.28313C13.8327 4.51222 13.8327 4.77872 13.8327 5.31173V8.5Z"
                  stroke="#054F31"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              {t("productsPage.extendedWarrantyTag", {
                years: product?.extendedWarranty?.extendedWarrantyYear,
              })}
            </Tag>
            <Button
              size="sm"
              onClick={() =>
                navigate(
                  `/file-preview?url=${product?.extendedWarranty?.policyDocumentUrl}`,
                )
              }
            >
              {t("extendedWarrantyPage.download")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetails;
