/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
} from "@egaranti/components";

import { useTranslation } from "react-i18next";

//import IParaLogo from "@/assets/icons/ipara-logo.svg?react";
import { useHookFormMask } from "use-mask-input";

const CreditCardForm = ({
  form,
  onSubmit,
  actionText,
  isCvcRequired = true,
}) => {
  const { t } = useTranslation();
  const handleFocus = (e) => {
    form.setValue("focus", e.target.name);
  };

  const registerWithMask = useHookFormMask(form.register);

  return (
    <div className="grid grid-cols-1">
      <div className="">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <div className="flex flex-col items-center gap-4 md:flex-row">
              <FormField
                control={form.control}
                name="cardHolderName"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("payment.addCreditCard.cardHolderName")}</Label>
                    <FormControl>
                      <Input onFocus={handleFocus} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="cardNumber"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("payment.addCreditCard.cardNumber")}</Label>
                    <FormControl>
                      <Input
                        type="tel"
                        onFocus={handleFocus}
                        {...field}
                        {...registerWithMask("cardNumber", {
                          mask: "9999 9999 9999 9999",
                          placeholder: "1234 5678 1234 5678",
                        })}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex items-center gap-4">
              <FormField
                control={form.control}
                name="expireDate"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("payment.addCreditCard.expireDate")}</Label>
                    <FormControl>
                      <Input
                        type="tel"
                        onFocus={handleFocus}
                        {...field}
                        {...registerWithMask("expireDate", {
                          mask: "99/99",
                          placeholder: "MM/YY",
                        })}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isCvcRequired && (
                <FormField
                  control={form.control}
                  name="cvc"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>CVC</Label>
                      <FormControl>
                        <Input
                          onFocus={handleFocus}
                          {...field}
                          {...registerWithMask("cvc", {
                            mask: "999",
                            placeholder: "CVC",
                          })}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>

            {/* <div className="my-4 flex items-center gap-2">
          <IParaLogo />
          <span className="text-sm font-medium text-[#677389]">
            {t("payment.addCreditCard.payWith")}
          </span>
        </div> */}
            <p className="my-4 text-sm text-[#677389]">
              {t("payment.addCreditCard.description")}
            </p>
            <div className="flex flex-col gap-2 py-5">
              <Label className="flex items-start gap-2 text-sm text-[#364153]">
                <Checkbox
                  checked={form.watch("storeCardAfterSuccessPayment")}
                  onClick={() => {
                    form.setValue(
                      "storeCardAfterSuccessPayment",
                      !form.watch("storeCardAfterSuccessPayment"),
                    );
                  }}
                />
                {t("payment.addCreditCard.storeCardAfterSuccessPayment")}
              </Label>
              <div>
                <Label className="flex items-start gap-2 text-sm text-[#364153]">
                  <Checkbox
                    checked={form.watch("techpoint")}
                    onClick={() => {
                      form.setValue("techpoint", !form.watch("techpoint"));
                    }}
                  />
                  {t("payment.addCreditCard.techpoint")}{" "}
                  <a
                    className="text-sm font-semibold text-[#0049E6]"
                    href="https://cdn.egaranti.com/static/E-Garanti_Uzat%C4%B1lm%C4%B1s%CC%A7_I%CC%87htiyari_Garanti_O%CC%88nbilgilendirme_Formu.pdf"
                    target="_blank"
                  >
                    {t("extendedWarrantyPage.download")}
                  </a>
                </Label>
              </div>
              {form.watch("storeCardAfterSuccessPayment") && (
                <FormField
                  control={form.control}
                  name="cardAlias"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>{t("payment.addCreditCard.cardAlias")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
          </form>
        </Form>
        <Button
          disabled={
            form.formState.isSubmitting ||
            !form.watch("addressId") ||
            !form.watch("techpoint")
          }
          onClick={form.handleSubmit(onSubmit)}
        >
          {actionText}
        </Button>
      </div>
    </div>
  );
};

export default CreditCardForm;
