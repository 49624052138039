import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "../../../lib/api/axios";
import AuthHeader from "../../../components/Auth/AuthHeader";

import * as yup from "yup";

import { parseError } from "@/lib/utils";

const ForgotPasswordVerify = () => {
  const navigateTo = useNavigate();
  const token = useLocation().search.split("=")[1];
  const { toast } = useToast();
  const { t } = useTranslation();

  const updatePasswordSchema = yup.object({
    password: yup
      .string()
      .required(t("common.formErrors.passwordRequired"))
      .min(8, t("common.formErrors.passwordMin")),
    confirmPassword: yup
      .string()
      .min(8, t("common.formErrors.confirmPasswordMin"))
      .required(t("common.formErrors.confirmPasswordRequired"))
      .oneOf(
        [yup.ref("password")],
        t("common.formErrors.confirmPasswordMatch"),
      ),
  });

  const form = useForm({
    resolver: yupResolver(updatePasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = (data) => {
    axios
      .post(`/reset/password/mail`, { ...data, hash: token })
      .then((res) => {
        toast({
          description: t("authPages.passwordResetSuccess"),
          variant: "success",
        });
        setTimeout(() => {
          navigateTo("/login");
        }, 2500);
      })
      .catch((err) => {
        const errorText = parseError(err.response.data.errors);
        toast({
          description: errorText,
          variant: "error",
        });
      });
  };

  return (
    <div className="flex min-h-screen w-full bg-[#F9FAFC]">
      <div className="flex w-full flex-1 flex-col items-center justify-between px-7 pt-6">
        <AuthHeader />
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="relative flex h-full w-full items-center justify-center"
          >
            <div className="flex w-full max-w-[400px] flex-col gap-5 rounded-lg md:bg-white md:p-6 md:shadow-default">
              <div className="mb-2 flex flex-col gap-3">
                <h2 className="text-[24px] font-semibold">
                  {t("authPages.forgotPassword")}
                </h2>
                <h3 className="text-[16px] font-normal text-[#677389]">
                  {t("authPages.enterNewPassword")}
                </h3>
              </div>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("authPages.password")}</Label>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("authPages.confirmPassword")}</Label>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button size="lg">{t("common.save")}</Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPasswordVerify;
