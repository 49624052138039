import React from "react";

const FilterPill = ({ text, onClick }) => {
  return (
    <button
      aria-label="Filtreleri Temizle"
      onClick={onClick}
      className="flex items-center justify-center rounded-full bg-[#E3E7EF] px-3 py-2 font-medium hover:opacity-75"
    >
      <span className="whitespace-nowrap text-xs text-[#111729]">{text}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="ml-1 h-3 w-3 text-[#111729]"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  );
};

export default FilterPill;
