import { useEffect, useState } from "react";

function useDebounce(callback, delay) {
  const [debouncedValue, setDebouncedValue] = useState(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedValue !== null) {
        callback(debouncedValue);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedValue, delay, callback]);

  return setDebouncedValue;
}

export default useDebounce;
