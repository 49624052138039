import React, { useEffect, useState } from "react";

import axios from "../../api/axios";

const useFetchSetupClaims = () => {
  const [setupClaims, setSetupClaims] = useState([]);

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    individualCustomerProductName: "",
    setupClaimStatus: "",
  });

  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    totalPages: 1,
    totalElements: 0,
  });

  const clearFilters = () => {
    setFilters({
      individualCustomerProductName: "",
      setupClaimStatus: "",
    });
  };

  const applyFilters = () => {
    fetchSetupClaims(filters, {
      ...pagination,
      page: 1,
    });
  };

  const fetchSetupClaims = (filtersToApply, paginationToApply) => {
    setLoading(true);

    const params = {
      ...filtersToApply,
      ...paginationToApply,
    };

    axios
      .get("/individual-customer-product-setup-claims", { params })
      .then((res) => {
        setSetupClaims(res.data.content);
        setPagination({
          page: res.data.page,
          size: 10,
          totalPages: res.data.totalPages,
          totalElements: res.data.totalElements,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return {
    setupClaims,
    loading,
    filters,
    pagination,
    setFilters,
    setPagination,
    clearFilters,
    applyFilters,
    fetchSetupClaims,
  };
};

export default useFetchSetupClaims;
