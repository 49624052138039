import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  PhoneInput,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import AuthHeader from "@/components/Auth/AuthHeader";
import OtpVerifyDialog from "@/components/Auth/Login/OtpVerifyDialog";

import LoginPageBannerImage from "@/assets/images/loginpagebanner.png";
import LoginPageBannerImageRu from "@/assets/images/loginpagebannerru.png";

import * as yup from "yup";

import { register } from "@/lib/api/auth";
import axios from "@/lib/api/axios";
import { parseError } from "@/lib/utils";
import DialCodes from "@/lib/utils/data/dialCodes";

export default function Register() {
  const { toast } = useToast();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [openOtpVerify, setOpenOtpVerify] = useState(false);

  const registerFormSchema = yup.object().shape({
    firstName: yup.string().required(t("common.formErrors.nameRequired")),
    lastName: yup.string().required(t("common.formErrors.surnameRequired")),
    email: yup
      .string(t("common.formErrors.emailInvalid"))
      .email(t("common.formErrors.emailInvalid"))
      .required(t("common.formErrors.emailRequired")),
    phone: yup.string().required(t("common.formErrors.phoneRequired")),
    membershipAgreement: yup
      .boolean()
      .oneOf([true], t("common.formErrors.termsRequired")),
    commercialCommunicationAgreement: yup.boolean(),
    countryCode: yup
      .string()
      .required(t("common.formErrors.countryCodeRequired")),
  });

  const form = useForm({
    resolver: yupResolver(registerFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      countryCode: "TR",
      countryValue: "TR",
      regionValue: "TR",
      membershipAgreement: false,
      commercialCommunicationAgreement: false,
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await register(data);

      setOpenOtpVerify(true);
    } catch (err) {
      const errorText = parseError(err.response?.data?.errors);
      toast({
        description: errorText,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOtpVerification = async (otpCode) => {
    try {
      const response = await axios.post("/otp/login", {
        phone: form.getValues("phone"),
        countryCode: form.getValues("countryCode"),
        otpCode: otpCode,
      });
      localStorage.setItem("token", response.data.jwtToken.substring(7));
      toast({
        description: t("authPages.registerSuccessOtp"),
        variant: "success",
      });
      setTimeout(() => {
        window.location.href = location.state?.from || "/";
      }, 1000);
    } catch (err) {
      const errorText = parseError(err.response?.data?.errors);
      toast({
        description: errorText,
        variant: "error",
      });
    }
  };

  const getIP = async () => {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    form.setValue("ip", data.ip);
  };

  useEffect(() => {
    getIP();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("authPages.egarantiRegister")}</title>
        <meta
          name="description"
          content="egaranti platformundan en iyi şekilde yararlanmanıza ve satış sonrası deneyiminizi artırmanıza yardımcı olacak içeriğe erişin."
        />
      </Helmet>
      <div className="flex min-h-screen w-full bg-white">
        <div className="flex w-full flex-col items-center overflow-auto px-7 pb-12 pt-6">
          <AuthHeader />
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="mt-6 flex max-w-[360px] flex-col gap-3">
                <div className="mb-1 flex flex-col gap-1">
                  <h2 className="text-[36px] font-semibold">
                    {t("authPages.register")}
                  </h2>
                  <h3 className="text-[16px] font-normal text-[#677389]">
                    {t("authPages.createAnAccountToContinue")}
                  </h3>
                </div>
                <div className="flex flex-col items-center gap-4 md:flex-row">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>{t("authPages.name")}</Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>{t("authPages.surname")}</Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>{t("authPages.email")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>{t("authPages.phoneNumber")}</Label>
                      <FormControl>
                        <PhoneInput
                          defaultCountry="TR"
                          onChange={(country, value) => {
                            form.setValue("phone", value);
                            form.setValue("countryCode", country);
                          }}
                          value={form.getValues("phone")}
                          onBlur={field.onBlur}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="membershipAgreement"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div>
                          <Label
                            htmlFor="membershipAgreement"
                            className="flex cursor-pointer items-center gap-1 text-gray-500"
                          >
                            <Checkbox
                              {...field}
                              id="membershipAgreement"
                              name="membershipAgreement"
                              control={form.control}
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                            {i18n.language === "tr" ? (
                              <span className="ml-2 text-[14px] font-normal text-[#677389]">
                                <a
                                  className="text-[#0F6FFF] underline"
                                  href="https://egaranti.com/tr/policy/usage-terms/"
                                  target={"_blank"}
                                >
                                  Üyelik sözleşmesi
                                </a>
                                'ni okudum, kabul ediyorum.
                                <br />
                                <a
                                  className="text-[#0F6FFF] underline"
                                  href="https://egaranti.com/tr/policy/privacy/"
                                  target={"_blank"}
                                >
                                  Kişisel Veri Aydınlatma Formu
                                </a>
                                'nu okudum.
                              </span>
                            ) : i18n.language === "ru" ? (
                              <span className="text-sm font-normal text-[#677389]">
                                Я ознакомлен(а) и принял(а){" "}
                                <a
                                  className="text-[#0F6FFF] underline"
                                  href="https://egaranti.com/ru/policy/usage-terms/"
                                  target={"_blank"}
                                >
                                  соглашение об участии
                                </a>
                                . Я ознакомлен(а) с{" "}
                                <a
                                  className="text-[#0F6FFF] underline"
                                  href="https://egaranti.com/ru/policy/privacy"
                                  target={"_blank"}
                                >
                                  правилами обработки персональных данных.
                                </a>
                              </span>
                            ) : (
                              <span className="text-sm font-normal text-[#677389]">
                                I've read the{" "}
                                <a
                                  className="text-[#0F6FFF] underline"
                                  href="https://egaranti.com/tr/policy/privacy"
                                  target={"_blank"}
                                >
                                  Personal Data Disclosure Form.
                                </a>
                              </span>
                            )}
                          </Label>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="commercialCommunicationAgreement"
                  render={({ field }) => (
                    <FormItem className="flex cursor-pointer items-center gap-1">
                      <FormControl>
                        <div className="flex">
                          <Checkbox
                            {...field}
                            control={form.control}
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <span className="ml-2 text-[14px] font-normal text-[#677389]">
                            <a
                              className="text-[#0F6FFF] underline"
                              href={`https://egaranti.com/${i18n.language}/policy/ticari-elektronik-ileti/`}
                              target={"_blank"}
                            >
                              {t("authPages.commercialElectronicMessage")}
                            </a>{" "}
                            {t("authPages.acceptCommercialElectronicMessage")}
                          </span>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button disabled={loading}>
                  {loading ? t("common.loading") : t("authPages.register")}
                </Button>
                <span
                  className="text-center text-[14px] font-medium text-[#667085]"
                  href="/login"
                >
                  {t("authPages.haveAnAccount")}
                  <a className="ml-1 text-[#0F6FFF]" href="/login">
                    {t("authPages.login")}
                  </a>
                </span>
              </div>
            </form>
          </Form>
        </div>
        <div className="hidden w-full flex-col items-end justify-end gap-12 bg-gradient-to-b from-[#799AE3] to-[#0B338B] pl-28 lg:flex">
          <div className="flex flex-col">
            <h2 className="text-sm font-bold uppercase text-gray-50">
              {t("authPages.egarantiAwaitsYou")}
            </h2>
            <h4 className="pr-4 text-[30px] font-medium text-white lg:text-[28px]">
              {t("authPages.oneStepToCollect")}
            </h4>
          </div>
          <img
            className="sr-hidden max-h-[600px] w-full object-contain"
            src={
              i18n.language === "ru"
                ? LoginPageBannerImageRu
                : LoginPageBannerImage
            }
            alt="egaranti kayıt ol"
          />
        </div>
      </div>
      {openOtpVerify && (
        <OtpVerifyDialog
          open={openOtpVerify}
          onOpenChange={setOpenOtpVerify}
          phone={form.getValues("phone")}
          countryCode={form.getValues("countryCode")}
          onVerify={handleOtpVerification}
        />
      )}
    </>
  );
}
