import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@egaranti/components";

import { useTranslation } from "react-i18next";

import { ReactComponent as LogoutIcon } from "@/assets/svgs/logoutmodalicon.svg";

const LogoutDialog = ({ open, onOpenChange }) => {
  const { t } = useTranslation();
  const handleLogout = () => {
    localStorage.removeItem("token");

    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  return (
    <AlertDialog onOpenChange={onOpenChange} open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            <div className="flex h-full flex-col items-center justify-center">
              <LogoutIcon />
              <div className="mt-8 flex flex-col items-center">
                <h2 className="text-lg font-medium text-[#101828]">
                  {t("navbar.logout")}
                </h2>
                <span className="text-md mt-2 text-center font-normal text-[#677389]">
                  {t("navbar.areYouSureToLogout")}
                </span>
              </div>
            </div>
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex flex-col gap-2">
          <AlertDialogCancel>{t("navbar.cancel")}</AlertDialogCancel>
          <AlertDialogAction onClick={handleLogout}>
            {t("navbar.logout")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default LogoutDialog;
