import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Drawer,
  DrawerContent,
} from "@egaranti/components";
import { DrawerTrigger } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

const ExtendedWarrantyModal = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 640px)");
  const navigate = useNavigate();

  if (isMobile)
    return (
      <Drawer>
        <DrawerTrigger>
          {t("services.extendedWarrantyModal.trigger", "Şimdi Keşfet")}
        </DrawerTrigger>
        <DrawerContent className="flex flex-col gap-4 border-none bg-gradient-to-b from-[#0037AD] to-[#6692F0] p-7">
          <h1 className="text-center text-lg font-semibold text-[#F9FAFC]">
            Uzatılmış Garanti Paketi
          </h1>
          <h3 className="text-center font-medium text-[#CDD5E0]">
            egaranti’ye kaydettiğiniz telefonlarınıza 1 yıllık uzatılmış garanti
            paketi tanımlayarak ömrünü uzatabilirsiniz. ✨
          </h3>
          <div className="mb-6 mt-4 flex flex-col gap-3 rounded-lg bg-white p-6 md:shadow-sm">
            <h1 className="text-center text-xl font-medium">Teminat Kapsamı</h1>
            <ul className="mt-2 flex flex-col items-center gap-4 text-sm font-medium text-[#364153]">
              <li className="flex items-center gap-2">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 13.125H9.0075M6.15 16.5H11.85C12.6901 16.5 13.1101 16.5 13.431 16.3365C13.7132 16.1927 13.9427 15.9632 14.0865 15.681C14.25 15.3601 14.25 14.9401 14.25 14.1V3.9C14.25 3.05992 14.25 2.63988 14.0865 2.31901C13.9427 2.03677 13.7132 1.8073 13.431 1.66349C13.1101 1.5 12.6901 1.5 11.85 1.5H6.15C5.30992 1.5 4.88988 1.5 4.56901 1.66349C4.28677 1.8073 4.0573 2.03677 3.91349 2.31901C3.75 2.63988 3.75 3.05992 3.75 3.9V14.1C3.75 14.9401 3.75 15.3601 3.91349 15.681C4.0573 15.9632 4.28677 16.1927 4.56901 16.3365C4.88988 16.5 5.30992 16.5 6.15 16.5ZM9.375 13.125C9.375 13.3321 9.20711 13.5 9 13.5C8.79289 13.5 8.625 13.3321 8.625 13.125C8.625 12.9179 8.79289 12.75 9 12.75C9.20711 12.75 9.375 12.9179 9.375 13.125Z"
                    stroke="#0049E6"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Üretici Kusurları
              </li>
              <li className="flex items-center gap-2">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.7235 5.72353C11.4264 5.42652 11.2779 5.27801 11.2223 5.10676C11.1734 4.95613 11.1734 4.79387 11.2223 4.64324C11.2779 4.47199 11.4264 4.32348 11.7235 4.02647L13.8522 1.89769C13.2873 1.64222 12.6602 1.5 11.9999 1.5C9.51464 1.5 7.49993 3.51472 7.49993 6C7.49993 6.36828 7.54417 6.72623 7.62762 7.06882C7.71699 7.43568 7.76168 7.61911 7.75374 7.735C7.74544 7.85632 7.72735 7.92087 7.6714 8.02884C7.61796 8.13197 7.51557 8.23436 7.31079 8.43914L2.62493 13.125C2.00361 13.7463 2.00361 14.7537 2.62493 15.375C3.24625 15.9963 4.25361 15.9963 4.87493 15.375L9.56079 10.6891C9.76557 10.4844 9.86796 10.382 9.97109 10.3285C10.0791 10.2726 10.1436 10.2545 10.2649 10.2462C10.3808 10.2382 10.5642 10.2829 10.9311 10.3723C11.2737 10.4558 11.6316 10.5 11.9999 10.5C14.4852 10.5 16.4999 8.48528 16.4999 6C16.4999 5.33969 16.3577 4.71261 16.1022 4.14769L13.9735 6.27647C13.6764 6.57348 13.5279 6.72199 13.3567 6.77763C13.2061 6.82658 13.0438 6.82658 12.8932 6.77763C12.7219 6.72199 12.5734 6.57348 12.2764 6.27647L11.7235 5.72353Z"
                    stroke="#0049E6"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Servis Hizmeti
              </li>
            </ul>
          </div>
          <Button
            onClick={() => {
              navigate("/services/get-extended-warranty/step1");
            }}
            size="lg"
            variant="secondaryColor"
          >
            Şimdi Uzatılmış Garanti Satın Al
          </Button>
        </DrawerContent>
      </Drawer>
    );

  return (
    <Dialog>
      <DialogTrigger>
        {t("services.extendedWarrantyModal.trigger", "Şimdi Keşfet")}
      </DialogTrigger>
      <DialogContent className="border-none bg-gradient-to-b from-[#0037AD] to-[#6692F0] p-8">
        <h1 className="text-center text-lg font-semibold text-[#F9FAFC]">
          Uzatılmış Garanti Paketi
        </h1>
        <h3 className="text-center font-medium text-[#CDD5E0]">
          egaranti’ye kaydettiğiniz telefonlarınıza 1 yıllık uzatılmış garanti
          paketi tanımlayarak ömrünü uzatabilirsiniz. ✨
        </h3>
        <div className="mb-6 mt-4 flex flex-col gap-3 rounded-lg bg-white p-6 md:shadow-sm">
          <h1 className="text-center text-xl font-medium">Teminat Kapsamı</h1>
          <ul className="flex flex-col items-center gap-4 text-sm font-medium text-[#364153]">
            <li className="flex items-center gap-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 13.125H9.0075M6.15 16.5H11.85C12.6901 16.5 13.1101 16.5 13.431 16.3365C13.7132 16.1927 13.9427 15.9632 14.0865 15.681C14.25 15.3601 14.25 14.9401 14.25 14.1V3.9C14.25 3.05992 14.25 2.63988 14.0865 2.31901C13.9427 2.03677 13.7132 1.8073 13.431 1.66349C13.1101 1.5 12.6901 1.5 11.85 1.5H6.15C5.30992 1.5 4.88988 1.5 4.56901 1.66349C4.28677 1.8073 4.0573 2.03677 3.91349 2.31901C3.75 2.63988 3.75 3.05992 3.75 3.9V14.1C3.75 14.9401 3.75 15.3601 3.91349 15.681C4.0573 15.9632 4.28677 16.1927 4.56901 16.3365C4.88988 16.5 5.30992 16.5 6.15 16.5ZM9.375 13.125C9.375 13.3321 9.20711 13.5 9 13.5C8.79289 13.5 8.625 13.3321 8.625 13.125C8.625 12.9179 8.79289 12.75 9 12.75C9.20711 12.75 9.375 12.9179 9.375 13.125Z"
                  stroke="#0049E6"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Üretici Kusurları
            </li>
            <li className="flex items-center gap-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7235 5.72353C11.4264 5.42652 11.2779 5.27801 11.2223 5.10676C11.1734 4.95613 11.1734 4.79387 11.2223 4.64324C11.2779 4.47199 11.4264 4.32348 11.7235 4.02647L13.8522 1.89769C13.2873 1.64222 12.6602 1.5 11.9999 1.5C9.51464 1.5 7.49993 3.51472 7.49993 6C7.49993 6.36828 7.54417 6.72623 7.62762 7.06882C7.71699 7.43568 7.76168 7.61911 7.75374 7.735C7.74544 7.85632 7.72735 7.92087 7.6714 8.02884C7.61796 8.13197 7.51557 8.23436 7.31079 8.43914L2.62493 13.125C2.00361 13.7463 2.00361 14.7537 2.62493 15.375C3.24625 15.9963 4.25361 15.9963 4.87493 15.375L9.56079 10.6891C9.76557 10.4844 9.86796 10.382 9.97109 10.3285C10.0791 10.2726 10.1436 10.2545 10.2649 10.2462C10.3808 10.2382 10.5642 10.2829 10.9311 10.3723C11.2737 10.4558 11.6316 10.5 11.9999 10.5C14.4852 10.5 16.4999 8.48528 16.4999 6C16.4999 5.33969 16.3577 4.71261 16.1022 4.14769L13.9735 6.27647C13.6764 6.57348 13.5279 6.72199 13.3567 6.77763C13.2061 6.82658 13.0438 6.82658 12.8932 6.77763C12.7219 6.72199 12.5734 6.57348 12.2764 6.27647L11.7235 5.72353Z"
                  stroke="#0049E6"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Servis Hizmeti
            </li>
          </ul>
        </div>
        <Button
          onClick={() => {
            navigate("/services/get-extended-warranty/step1");
          }}
          size="lg"
          variant="secondaryColor"
        >
          Şimdi Uzatılmış Garanti Satın Al
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ExtendedWarrantyModal;
