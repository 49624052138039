import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import HomePageBanner from "@/components/Services/HomePageBanner";
import MyServices from "@/components/Services/MyServices";
import Opportunities from "@/components/Services/Opportunities";
import PhoneInsurances from "@/components/Services/PhoneInsurances";

const Services = () => {
  const { t } = useTranslation();

  const userCountry = localStorage.getItem("userCountry");

  return (
    <div className="relative">
      {userCountry === "UZ" && (
        <div className="absolute inset-0 z-10 flex items-center justify-center bg-blue-200 bg-opacity-50">
          <div className="pointer-events-auto mx-auto mt-[-500px] max-w-md rounded-lg bg-white p-8 text-center shadow-lg md:mt-[-400px]">
            <h2 className="mb-4 text-2xl font-semibold">
              {t("services.notActiveTitle")}
            </h2>
            <p className="mb-6">{t("services.notActiveDescription")}</p>
            <div className="flex flex-col gap-4">
              <Link
                to="/claims/service"
                className="rounded bg-[#0040C9] px-4 py-2 text-white transition hover:bg-[#0033A0]"
              >
                {t("claimsPage.serviceClaims")}
              </Link>
              <Link
                to="/claims/setup"
                className="rounded bg-[#0040C9] px-4 py-2 text-white transition hover:bg-[#0033A0]"
              >
                {t("claimsPage.setupClaims")}
              </Link>
            </div>
          </div>
        </div>
      )}
      <main className="flex min-h-screen flex-col gap-6 p-6 md:bg-[#F9FAFC]">
        <HomePageBanner />
        <MyServices />
        <Opportunities />
        <PhoneInsurances />
      </main>
    </div>
  );
};

export default Services;
