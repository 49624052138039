import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "../../lib/api/axios";
import { ReactComponent as NotificationIcon } from "../../assets/svgs/infoemptyicon.svg";
import Loading from "../../components/common/Loading";
import useOnClickOutside from "../../lib/utils/hooks/useOnClickOutside.jsx";
import NotificationItem from "./NotificationItem";

const NotificationModal = ({
  setIsNotificationModalOpen,
  unreadNotificationsCount,
  getUnreadNotificationsCount,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState({
    page: 0,
    size: 10,
    totalElements: 0,
    totalPages: 0,
  });

  const handleReadOneNotification = async (id) => {
    // /api/notifications/readOne?user={{uid}}&notification={{id}}
    axios.put(`/individual-customer-notifications/${id}/read`).then((res) => {
      getAllNotifications();
    });
  };

  const handleReadAllNotifications = async () => {
    // /api/notifications/readAll?user={{uid}}
    axios.put(`/individual-customer-notifications/read-all`).then((res) => {
      getAllNotifications();
      getUnreadNotificationsCount();
    });
  };

  const getAllNotifications = async () => {
    setLoading(true);
    axios
      .get(`/individual-customer-notifications`)
      .then((res) => {
        setNotifications(res.data.content);
        setPager({
          page: res.data.page,
          size: res.data.size,
          totalElements: res.data.totalElements,
          totalPages: res.data.totalPages,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight) {
      // if has next page then fetch next page
      if (pager.page < pager.totalPages) {
        setLoading(true);
        axios
          .get(`/individual-customer-notifications?page=${pager.page + 1}`)
          .then((res) => {
            setNotifications([...notifications, ...res.data.content]);
            setPager({
              page: res.data.page,
              size: res.data.size,
              totalElements: res.data.totalElements,
              totalPages: res.data.totalPages,
            });
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  useOnClickOutside(ref, () => setIsNotificationModalOpen(false));

  return (
    <div className="fixed right-0 top-0 z-50 flex h-full w-full items-start justify-center bg-black/5 md:items-start md:justify-end">
      <div
        ref={ref}
        className="m-5 mt-16 flex max-h-[550px] w-full flex-col justify-between overflow-auto rounded-lg border border-[#E3E7EF] bg-white pb-4 shadow-default md:mt-20 md:max-h-[600px] md:max-w-sm"
        onScroll={handleScroll}
      >
        <header className="flex items-center justify-between p-4">
          <h4 className="text-sm font-semibold text-[#111729]">
            {t("navbar.notifications")}
          </h4>
          {unreadNotificationsCount > 0 && (
            <button
              onClick={handleReadAllNotifications}
              className="text-xs text-[#0049E6]"
            >
              {t("navbar.markReadAll")}
            </button>
          )}
        </header>
        {!loading && notifications?.length === 0 && (
          <div className="flex h-full items-center justify-center gap-4 py-12">
            <NotificationIcon className="h-8 w-8" />
            <p className="text-sm font-medium text-[#111729]">
              {t("navbar.noNotifications")}
            </p>
          </div>
        )}
        {loading && <Loading />}
        {notifications?.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            handleReadOneNotification={handleReadOneNotification}
            getAllNotifications={getAllNotifications}
            getUnreadNotificationsCount={getUnreadNotificationsCount}
          />
        ))}
      </div>
    </div>
  );
};

export default NotificationModal;
