import React from "react";

const VideoSection = ({ product, t }) => {
  return (
    <div className="mt-8 flex w-full flex-col items-center justify-center px-6 pb-8">
      <div className="flex w-full justify-between">
        <h3 className="text-base font-bold uppercase text-[#677389]">
          {t("Videolar")}
        </h3>
      </div>
      <div className="mt-4 flex w-full flex-col gap-4">
        {product?.videoData?.map((item, index) => (
          <a
            href={item.videoURL}
            key={index}
            target="_blank"
            className="flex w-full items-center gap-3 rounded-lg border p-4 hover:bg-gray-100"
          >
            <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-gray-200 text-gray-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-circle-play"
              >
                <circle cx="12" cy="12" r="10" />
                <polygon points="10 8 16 12 10 16 10 8" />
              </svg>
            </div>
            <p className="mt-2 text-sm font-medium text-[#111729]">
              {item.videoTitle.length > 50
                ? item.videoTitle.substring(0, 50) + "..."
                : item.videoTitle}
            </p>
          </a>
        ))}
      </div>
    </div>
  );
};

export default VideoSection;
