import { Button } from "@egaranti/components";
import {
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  PasswordInput,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import axios from "../../lib/api/axios";
import AuthHeader from "../../components/Auth/AuthHeader";

import Slot from "@/components/common/shared/slot";

import { OTPInput } from "input-otp";
import * as yup from "yup";

import { parseError } from "@/lib/utils";
import DialCodes from "@/lib/utils/data/dialCodes";

export default function VerifyTrendyol() {
  const token = new URLSearchParams(window.location.search).get("hash");
  const { t } = useTranslation();
  const { toast } = useToast();

  const [step, setStep] = useState(1);
  const [showSuccess, setShowSuccess] = useState(true);
  const [formState, setFormState] = useState({
    codeSent: false,
    timer: 179,
    code: "",
    password: "",
    repeatpassword: "",
    phone: "",
    countryCode: "TR",
    ip: "0",
  });

  const schema = yup.object().shape({
    phone: yup.string().required(t("common.formErrors.phoneRequired")),

    password: yup
      .string()
      .required(t("common.formErrors.passwordRequired"))
      .min(8, t("common.formErrors.passwordMin"))
      .max(50, t("common.formErrors.passwordMax")),
    repeatpassword: yup
      .string()
      .min(8, t("common.formErrors.confirmPasswordMin"))
      .max(50, t("common.formErrors.confirmPasswordMax"))
      .required(t("common.formErrors.confirmPasswordRequired"))
      .oneOf(
        [yup.ref("password")],
        t("common.formErrors.confirmPasswordMatch"),
      ),
    membershipAgreement: yup
      .boolean()
      .required(t("common.formErrors.termsRequired"))
      .oneOf([true], t("common.formErrors.termsRequired")),
    commercialCommunicationAgreement: yup.boolean(),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "",
      password: "",
      repeatpassword: "",
      membershipAgreement: false,
      commercialCommunicationAgreement: false,
      countryCode: "TR",
    },
  });

  useEffect(() => {
    if (!token) {
      setShowSuccess(false);
    }
  }, [token]);

  if (!showSuccess) {
    return (
      <div className="flex min-h-screen w-full bg-[#F9FAFC]">
        <div className="flex w-full flex-1 flex-col items-center justify-between px-7 pt-6">
          <AuthHeader />
          <div className="flex h-full w-full items-center justify-center">
            <div className="flex w-full max-w-[400px] flex-col gap-5 rounded-lg md:bg-white md:p-6 md:shadow-default">
              <div className="mb-2 flex flex-col items-center gap-3">
                <h2 className="text-[24px] font-semibold">Oppss</h2>
                <p className="text-center text-[16px] font-normal text-[#677389]">
                  {t("verifyPage.trendyolVerificationFail")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleSendCode = () => {
    axios
      .post("/send-phone-verification-trendyol", {
        phone: form.getValues().phone,
        countryCode: form.getValues().countryCode,
      })
      .then(() => {
        setStep(2);
      })
      .catch((err) => {
        toast({
          title: err.response.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  return (
    <div className="flex min-h-screen w-full bg-[#F9FAFC]">
      <div className="flex w-full flex-1 flex-col items-center justify-between px-7 pt-6">
        <AuthHeader />
        <div className="flex h-full w-full items-center justify-center">
          {step === 1 && <Step1 handleSendCode={handleSendCode} form={form} />}
          {step === 2 && (
            <Step2
              setStep={setStep}
              setFormState={setFormState}
              formState={formState}
              token={token}
              handleSendCode={handleSendCode}
              form={form}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const Step1 = ({ handleSendCode, form }) => {
  const { t, i18n } = useTranslation();

  const handleSubmit = (data) => {
    handleSendCode();
  };

  return (
    <div className="flex w-full max-w-[400px] flex-col gap-5 rounded-lg md:bg-white md:p-6 md:shadow-default">
      <div className="mb-2 flex flex-col gap-3">
        <h2 className="text-[24px] font-semibold">
          {t("verifyPage.selectNewPassword")}
        </h2>
        <h3 className="text-[16px] font-normal text-[#677389]">
          {t("verifyPage.minEightChar")}
        </h3>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="flex flex-col gap-4"
        >
          <div className="flex gap-2">
            <div className="min-w-[110px]">
              <FormField
                control={form.control}
                name="countryCode"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("authPages.countryCode")}</Label>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {DialCodes.map((item, index) => (
                          <SelectItem key={index} value={item.value}>
                            {item.icon} {item.countryCode}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("authPages.phoneNumber")}</Label>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="w-full">
                <Label>{t("authPages.password")}</Label>
                <FormControl>
                  <PasswordInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="repeatpassword"
            render={({ field }) => (
              <FormItem className="w-full">
                <Label>{t("authPages.confirmPassword")}</Label>
                <FormControl>
                  <PasswordInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="membershipAgreement"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div>
                    <Label
                      htmlFor="membershipAgreement"
                      className="flex cursor-pointer items-center gap-1 text-gray-500"
                    >
                      <Checkbox
                        {...field}
                        id="membershipAgreement"
                        name="membershipAgreement"
                        control={form.control}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      {i18n.language === "tr" ? (
                        <span className="ml-2 text-[14px] font-normal text-[#677389]">
                          <a
                            className="text-[#0F6FFF] underline"
                            href="https://egaranti.com/tr/policy/usage-terms/"
                            target={"_blank"}
                          >
                            Üyelik sözleşmesi
                          </a>
                          'ni okudum, kabul ediyorum.
                          <br />
                          <a
                            className="text-[#0F6FFF] underline"
                            href="https://egaranti.com/tr/policy/privacy/"
                            target={"_blank"}
                          >
                            Kişisel Veri Aydınlatma Formu
                          </a>
                          ’nu okudum.
                        </span>
                      ) : (
                        <span className="ml-2 text-[14px] font-normal text-[#677389]">
                          I've read and accepted the{" "}
                          <a
                            className="text-[#0F6FFF] underline"
                            href="https://egaranti.com/tr/policy/usage-terms/"
                            target={"_blank"}
                          >
                            Membership Agreement.
                          </a>{" "}
                          I've read the{" "}
                          <a
                            className="text-[#0F6FFF] underline"
                            href="https://egaranti.com/tr/policy/privacy/"
                            target={"_blank"}
                          >
                            Personal Data Disclosure Form
                          </a>
                        </span>
                      )}
                    </Label>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="commercialCommunicationAgreement"
            render={({ field }) => (
              <FormItem className="flex cursor-pointer items-center gap-1">
                <FormControl>
                  <div className="flex">
                    <Checkbox
                      {...field}
                      control={form.control}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    {i18n.language === "tr" ? (
                      <span className="ml-2 text-[14px] font-normal text-[#677389]">
                        <a
                          className="text-[#0F6FFF] underline"
                          href="https://egaranti.com/tr/policy/ticari-elektronik-ileti/"
                          target={"_blank"}
                        >
                          Ticari Elektronik İleti{" "}
                        </a>{" "}
                        almayı kabul ediyorum.
                      </span>
                    ) : (
                      <span className="ml-2 text-[14px] font-normal text-[#677389]">
                        I accept receiving{" "}
                        <a
                          className="text-[#0F6FFF] underline"
                          href="https://egaranti.com/tr/policy/ticari-elektronik-ileti/"
                          target={"_blank"}
                        >
                          Commercial Electronic Message
                        </a>{" "}
                      </span>
                    )}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button onClick={form.handleSubmit(handleSubmit)}>
            {t("common.send")}
          </Button>
        </form>
      </Form>
    </div>
  );
};

const Step2 = ({ formState, setFormState, token, handleSendCode, form }) => {
  const { toast } = useToast();
  const navigateTo = useNavigate();
  const [code, setCode] = useState("");
  const { t } = useTranslation();
  const [clientIp, setClientIp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const schema = yup.object().shape({
      code: yup
        .string()
        .min(6, t("common.formErrors.codeMin"))
        .max(6, t("common.formErrors.codeMax"))
        .required(t("common.formErrors.codeRequired")),
    });
    schema
      .validate({ code }, { abortEarly: false })
      .then(() => {
        setLoading(true);
        axios
          .post(`/onboard/trendyol`, {
            countryCode: form.getValues().countryCode,
            phone: form.getValues().phone,
            password: form.getValues().password,
            passwordConfirmation: form.getValues().repeatpassword,
            hash: token,
            code: code,
            commercialCommunicationAgreement:
              form.getValues().commercialCommunicationAgreement,
            membershipAgreement: form.getValues().membershipAgreement,
            ip: clientIp,
          })
          .then(() => {
            setLoading(false);
            navigateTo("/login");
          })
          .catch((err) => {
            const errorText = parseError(err.response.data.errors);
            toast({
              description: errorText,
              variant: "error",
            });
          });
      })
      .catch((err) => {
        console.log(err);
        const errorText = parseError(err.inner);
        toast({
          description: errorText,
          variant: "error",
        });
      });
  };

  const getIP = async () => {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    setClientIp(data.ip);
  };

  useEffect(() => {
    setFormState({ ...formState, codeSent: true });
    getIP();
  }, []);

  useEffect(() => {
    let interval = null;
    if (formState.codeSent) {
      interval = setInterval(() => {
        setFormState((formState) => ({
          ...formState,
          timer: formState.timer - 1,
        }));
      }, 1000);
    } else if (!formState.codeSent) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [formState.codeSent]);

  const handleResendCode = () => {
    handleSendCode();
    setFormState({ ...formState, timer: 179 });
  };

  return (
    <div className="flex w-full max-w-[400px] flex-col gap-5 rounded-lg md:bg-white md:p-6 md:shadow-default">
      <div className="mb-2 flex flex-col gap-3">
        <h2 className="text-[24px] font-semibold">
          {t("verifyPage.phoneNumberVerification")}
        </h2>
        <h3 className="text-[16px] font-normal text-[#677389]">
          {t("verifyPage.pleaseEnterVerificationCode")}
        </h3>
      </div>
      <div className="flex flex-col items-center gap-2">
        <div className="flex w-full max-w-[200px] flex-col items-center gap-2">
          <span className="text-sm text-gray-500">
            {t("verifyPage.verificationCode")}
          </span>
          <div className="flex gap-2">
            <OTPInput
              value={code}
              onChange={(c) => setCode(c)}
              maxLength={6}
              containerClassName="flex justify-center gap-3 mt-5"
              render={({ slots }) => (
                <div className="flex gap-3">
                  {slots.slice(0, 6).map((slot, idx) => (
                    <Slot key={idx} {...slot} />
                  ))}
                </div>
              )}
            />
          </div>
        </div>
        {formState.timer > 0 ? (
          <div className="flex items-center gap-2">
            <p className="text-sm text-gray-500">
              {t("verifyPage.remainingTime")} :
            </p>
            <p className="text-sm font-medium text-[#0049E6]">
              {formState.timer}
            </p>
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <button
              onClick={handleResendCode}
              className="text-sm font-medium text-[#0049E6]"
            >
              {t("verifyPage.sendAgain")}
            </button>
          </div>
        )}
      </div>
      {!loading && (
        <Button onClick={handleSubmit}>{t("common.confirm")}</Button>
      )}
    </div>
  );
};
