import { Button } from "@egaranti/components";

import { useTranslation } from "react-i18next";

const FeaturesSection = ({ triggerLoginModal }) => {
  const { t } = useTranslation();

  const features = [
    {
      id: 1,
      title: t("login.features.insuranceAndWarranty.title"),
      description: t("login.features.insuranceAndWarranty.description"),
      icon: (
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#AAC2F7" />
          <rect
            x="4.5"
            y="4"
            width="48"
            height="48"
            rx="24"
            stroke="#CCDBFA"
            stroke-width="8"
          />
          <path
            d="M25.5 27.5004L27.5 29.5004L32 25.0004M36.5 28.0004C36.5 32.9088 31.146 36.4788 29.198 37.6152C28.9766 37.7444 28.8659 37.809 28.7097 37.8425C28.5884 37.8685 28.4116 37.8685 28.2903 37.8425C28.1341 37.809 28.0234 37.7444 27.802 37.6152C25.854 36.4788 20.5 32.9088 20.5 28.0004V23.218C20.5 22.4184 20.5 22.0187 20.6308 21.6751C20.7463 21.3715 20.934 21.1006 21.1777 20.8859C21.4535 20.6428 21.8278 20.5024 22.5764 20.2217L27.9382 18.211C28.1461 18.1331 28.25 18.0941 28.357 18.0786C28.4518 18.0649 28.5482 18.0649 28.643 18.0786C28.75 18.0941 28.8539 18.1331 29.0618 18.211L34.4236 20.2217C35.1722 20.5024 35.5465 20.6428 35.8223 20.8859C36.066 21.1006 36.2537 21.3715 36.3692 21.6751C36.5 22.0187 36.5 22.4184 36.5 23.218V28.0004Z"
            stroke="#0040C9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 2,
      title: t("login.features.spareParts.title"),
      description: t("login.features.spareParts.description"),
      icon: (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#AAC2F7" />
          <rect
            x="4"
            y="4"
            width="48"
            height="48"
            rx="24"
            stroke="#CCDBFA"
            stroke-width="8"
          />
          <path
            d="M22 22L26.5 26.5M22 22H19L18 19L19 18L22 19V22ZM35.259 18.741L32.6314 21.3686C32.2354 21.7646 32.0373 21.9627 31.9632 22.191C31.8979 22.3918 31.8979 22.6082 31.9632 22.809C32.0373 23.0373 32.2354 23.2354 32.6314 23.6314L32.8686 23.8686C33.2646 24.2646 33.4627 24.4627 33.691 24.5368C33.8918 24.6021 34.1082 24.6021 34.309 24.5368C34.5373 24.4627 34.7354 24.2646 35.1314 23.8686L37.5893 21.4107C37.854 22.0549 38 22.7604 38 23.5C38 26.5376 35.5376 29 32.5 29C32.1338 29 31.7759 28.9642 31.4298 28.8959C30.9436 28.8001 30.7005 28.7521 30.5532 28.7668C30.3965 28.7824 30.3193 28.8059 30.1805 28.8802C30.0499 28.9501 29.919 29.081 29.657 29.343L22.5 36.5C21.6716 37.3284 20.3284 37.3284 19.5 36.5C18.6716 35.6716 18.6716 34.3284 19.5 33.5L26.657 26.343C26.919 26.081 27.0499 25.9501 27.1198 25.8195C27.1941 25.6807 27.2176 25.6035 27.2332 25.4468C27.2479 25.2995 27.1999 25.0564 27.1041 24.5702C27.0358 24.2241 27 23.8662 27 23.5C27 20.4624 29.4624 18 32.5 18C33.5055 18 34.448 18.2698 35.259 18.741ZM28.0001 30.9999L33.5 36.4999C34.3284 37.3283 35.6716 37.3283 36.5 36.4999C37.3284 35.6715 37.3284 34.3283 36.5 33.4999L31.9753 28.9753C31.655 28.945 31.3427 28.8872 31.0408 28.8043C30.6517 28.6975 30.2249 28.7751 29.9397 29.0603L28.0001 30.9999Z"
            stroke="#0040C9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 3,
      title: t("login.features.buyback.title"),
      description: t("login.features.buyback.description"),
      icon: (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#AAC2F7" />
          <rect
            x="4"
            y="4"
            width="48"
            height="48"
            rx="24"
            stroke="#CCDBFA"
            stroke-width="8"
          />
          <path
            d="M36 33H20M20 33L24 29M20 33L24 37M20 23H36M36 23L32 19M36 23L32 27"
            stroke="#0040C9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  ];

  return (
    <div className="mt-24 flex flex-col items-center gap-4 pb-12">
      <h2 className="text-center text-2xl font-semibold text-[#111729] md:text-3xl">
        {t("login.features.title")}
      </h2>
      <h4 className="text-center  font-medium text-[#64626A]">
        {t("login.features.description")}
      </h4>
      <div className="mt-9 grid grid-cols-1 gap-12 md:grid-cols-3">
        {features.map((feature) => (
          <div key={feature.id} className="flex flex-col items-center gap-4">
            {feature.icon}
            <h3 className="text-center text-xl font-semibold text-[#101828]">
              {feature.title}
            </h3>
            <p className="text-center  text-[#677389]">{feature.description}</p>
          </div>
        ))}
      </div>
      <Button className="mt-12" onClick={triggerLoginModal}>
        {t("login.features.button")}
      </Button>
    </div>
  );
};

export default FeaturesSection;
