import { Button, Tag } from "@egaranti/components";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Breadcrumb, { BreadcrumbItem } from "@/components/common/shared/breadcrumbs";

import Image from "@/assets/images/overview/insurance.png";

import axios from "@/lib/api/axios";

const GetExtendedWarranty = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get("/unverified-product-extended-warranties").then((res) => {
      setData(res.data.content);
    });
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem link="/services">
          {t("services.services")}
        </BreadcrumbItem>
        <BreadcrumbItem link="/services/get-extended-warranty" active>
          {t("extendedWarrantyPage.title")}
        </BreadcrumbItem>
      </Breadcrumb>
      <main className="flex max-w-4xl flex-col gap-5 p-6">
        {data?.length === 0 && (
          <div className="flex h-96 flex-col items-center justify-center gap-6 rounded-lg bg-white">
            <span className="font-medium text-[#364153]">
              {t("extendedWarrantyPage.noData")}
            </span>
            <Button asChild>
              <Link to="/services/get-extended-warranty/step1">
                {t("extendedWarrantyPage.getExtendedWarranty ")}
              </Link>
            </Button>
          </div>
        )}
        {data.map((item) => (
          <div className="flex flex-col items-center justify-between gap-12 rounded-lg border bg-white p-6 md:flex-row">
            <div className="flex items-center gap-3">
              <img
                className="h-20 w-20 rounded-lg object-cover"
                src={Image}
                alt="Sigorta"
              />
              <div className="flex flex-col gap-2">
                <h1 className="text-lg font-semibold text-[#111729]">
                  {item?.externalProductName}
                </h1>
                <span className="font-medium text-[#364153]">
                  {t("extendedWarrantyPage.totalPrice")}:{" "}
                  <b className="text-[#0049E6]">{item?.offerPrice}₺</b>
                </span>
                <span className="text-sm font-medium text-[#677389]">
                  {item?.warrantyStartDate} - {item?.warrantyExpireDate}
                </span>
                {
                  <Tag
                    size="sm"
                    variant={
                      item?.warrantyExpireDate < new Date().toISOString()
                        ? "green"
                        : "red"
                    }
                  >
                    {item?.warrantyExpireDate < new Date().toISOString()
                      ? t("extendedWarrantyPage.active")
                      : t("extendedWarrantyPage.inactive")}
                  </Tag>
                }
              </div>
            </div>
            <div className="flex flex-col items-center gap-2 text-blue-600">
              <span className="text-[#111729]">
                {t("extendedWarrantyPage.productName")}:{" "}
                <b className="font-semibold">{item?.unverifiedProductName}</b>
              </span>
              <span className="text-[#111729]">
                {t("extendedWarrantyPage.policyNo")}:{" "}
                <b className="font-semibold">{item?.externalPolicyNo}</b>
              </span>
              <a
                href={item?.policyDocumentUrl}
                target="_blank"
                rel="noreferrer"
                className="font-medium"
              >
                {t("extendedWarrantyPage.download")}
              </a>
            </div>
          </div>
        ))}
      </main>
    </div>
  );
};

export default GetExtendedWarranty;
