import { Button } from "@egaranti/components";

import React, { useEffect } from "react";

import useStore from "../../store/useProductStore";
import EmptyData from "../common/EmptyData";
import ProductCard from "./ProductCard";
import ProductSkeleton from "./ProductSkeleton";
import UnVerifiedProductDetailSheet from "./UnVerifiedProductDetailSheet";

import { motion } from "framer-motion";
import { t } from "i18next";

function UnverifiedProductsList({ unverifiedProducts, fetchProducts }) {
  const {
    selectedUnverifiedProduct,
    setSelectedUnverifiedProduct,
    openUnverifiedDetailSheet,
    setOpenUnverifiedDetailSheet,
    setOpenAddProductSheet,
    loading,
  } = useStore();

  const handleDetailModal = (product) => {
    setSelectedUnverifiedProduct(product);
    setOpenUnverifiedDetailSheet(true);
  };

  if (loading) {
    return (
      <div className="flex w-full flex-col">
        <h2 className="mb-4 text-sm font-medium text-gray-500 md:text-base md:text-gray-800">
          {t("productsPage.unapprovedProducts")}
        </h2>
        <div className="grid grid-cols-1 items-start gap-5 md:grid-cols-3">
          {[...Array(6)].map((_, index) => (
            <ProductSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <h2 className="mb-4 text-sm font-medium text-gray-500 md:text-base md:text-gray-800">
        {t("productsPage.unapprovedProducts")}
      </h2>
      {Array.isArray(unverifiedProducts) && unverifiedProducts.length > 0 ? (
        <motion.div className="grid grid-cols-1 items-start gap-5 md:grid-cols-3">
          {unverifiedProducts.map((product, index) => (
            <ProductCard
              key={index}
              onClick={() => handleDetailModal(product)}
              product={product}
              color="3"
              verified={false}
            />
          ))}
        </motion.div>
      ) : (
        <EmptyData
          className="mx-auto max-w-[400px]"
          title={t("productsPage.noData")}
          description={t("productsPage.noDataDesc2")}
        >
          <Button
            onClick={() => {
              setOpenAddProductSheet(true);
            }}
          >
            {t("productsPage.addUnapprovedProduct")}
          </Button>
        </EmptyData>
      )}
      <UnVerifiedProductDetailSheet
        open={openUnverifiedDetailSheet}
        onOpenChange={setOpenUnverifiedDetailSheet}
        selectedProduct={selectedUnverifiedProduct}
        fetchProducts={fetchProducts}
      />
    </div>
  );
}

export default UnverifiedProductsList;
