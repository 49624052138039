import * as yup from "yup";

export const addressSchema = yup.object({
  title: yup.string().required("Adres başlığı boş bırakılamaz"),
  addressLine: yup.string().required("Adres boş bırakılamaz"),
});
export const addressEditSchema = yup.object({
  title: yup.string().required("Adres başlığı boş bırakılamaz"),
  addressLine: yup.string().required("Adres boş bırakılamaz"),
});
