import { Pagination, Tag } from "@egaranti/components";

import React, { useEffect, useState } from "react";

import axios from "../../../lib/api/axios";

import EmptyData from "@/components/common/EmptyData";

import { t } from "i18next";

const ProductList = ({
  setSelectedProduct,
  setFormData,
  formData,
  setStep,
  paramsFromUrl,
}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState({
    name: "",
    mainCategory: {},
    category: {},
    subCategory: {},
    subCategoryBrand: {},
    brand: "",
  });

  const [pager, setPager] = useState({
    size: 10,
    totalElements: 2,
    totalPages: 1,
  });

  // params for filtering products
  const [params, setParams] = useState({
    page: 1,
    size: 10,
    name: "",
    model: "",
    brand: "",
    merchantName: "",
  });

  const getVerifiedProducts = (parameters) => {
    setLoading(true);

    axios
      .get("/individual-customer-products", {
        params: {
          ...parameters,
        },
      })
      .then((res) => {
        setProducts(res.data.content);
        setPager({
          page: res.data.page,
          size: res.data.pageSize,
          totalPages: res.data.totalPages,
          totalElements: res.data.totalElements,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleClearFilter = () => {
    setParams({
      page: 1,
      size: 10,
      name: "",
      model: "",
      brand: "",
      merchantName: "",
    });

    setSelectedFilters({
      name: "",
      mainCategory: {},
      category: {},
      subCategory: {},
      subCategoryBrand: {},
      brand: "",
    });

    getVerifiedProducts({
      page: 1,
      size: 10,
      name: "",
      model: "",
      brand: "",
      merchantName: "",
    });
  };

  useEffect(() => {
    getVerifiedProducts(params);
  }, []);

  // if there is params in url, set params to state and get products with params in url pass step 1
  useEffect(() => {
    if (
      products.length > 0 &&
      paramsFromUrl.get("individualCustomerProductId")
    ) {
      setSelectedProduct(
        products.find(
          (product) =>
            product.id ===
            Number(paramsFromUrl.get("individualCustomerProductId")),
        ),
      );

      setFormData({
        ...formData,
        individualCustomerProductId: Number(
          paramsFromUrl.get("individualCustomerProductId"),
        ),
      });

      setStep(1);
    }
  }, [paramsFromUrl, products]);

  const isHasFilter =
    selectedFilters.name !== "" ||
    selectedFilters.mainCategory.id !== undefined ||
    selectedFilters.category.id !== undefined ||
    selectedFilters.subCategory.id !== undefined ||
    selectedFilters.subCategoryBrand.id !== undefined ||
    selectedFilters.brand !== "";

  const handleSelectProduct = async (product) => {
    setSelectedProduct(product);
    setFormData({
      productName: product.name,
      ...formData,

      individualCustomerProductId: product.id,
    });
    setStep(1);

    // /merchant-service-claim-activations/{individualCustomerProductId}

    await axios
      .get(`/merchant-service-claim-activations/${product.id}`)
      .then((res) => {
        setFormData((prev) => ({
          ...prev,
          activeStatus: {
            installed: res.data.installed,
            active: res.data.active,
            type: res.data.type,
            redirectValue: res.data.redirectValue,
          },
        }));
      });
  };

  return (
    <div className="mx-auto mt-6 flex w-full max-w-[1000px] flex-col gap-5 rounded-lg bg-white p-6 md:border md:border-[#EAECF0]">
      <div className="flex items-center justify-between">
        <h2 className="font-medium">{t("claimsPage.productSelection")}</h2>
      </div>
      {products.length === 0 && !loading && (
        <EmptyData title={t("productsPage.productNotFound")} />
      )}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {products.map((product) => (
          <button
            className="flex w-full cursor-pointer flex-col gap-3 rounded-lg border px-6 py-4 shadow-sm hover:border hover:border-[#6692F0]"
            onClick={() => {
              handleSelectProduct(product);
            }}
            aria-label="Bu ürünü seç"
            key={product.id}
          >
            <h2>{product.name}</h2>
            <span className="text-[#677389]">{product.brand}</span>
            <Tag size="sm" variant={"yellow"}>
              {product.category.split(">").pop()}
            </Tag>
          </button>
        ))}
      </div>
      <div className="mt-2 flex justify-center">
        <Pagination
          totalPages={pager.totalPages}
          currentPage={pager.page}
          pageSize={params.size}
          onPageChange={(i) => {
            setPager({ ...pager, page: i });
            setParams({ ...params, page: i });
            getVerifiedProducts({ ...params, page: i });
          }}
        />
      </div>
    </div>
  );
};

export default ProductList;
