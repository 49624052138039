import { Button, Checkbox } from "@egaranti/components";
import { PasswordInput, useToast } from "@egaranti/components";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import axios from "../../lib/api/axios";
import AuthHeader from "../../components/Auth/AuthHeader";

import * as yup from "yup";

import { parseError } from "@/lib/utils";

export default function VerifyPhone() {
  const navigateTo = useNavigate();
  const { toast } = useToast();
  const token = new URLSearchParams(window.location.search).get("hash");
  const { t, i18n } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(true);
  const [formState, setFormState] = useState({
    password: "",
    repeatpassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (!token) {
      setShowSuccess(false);
    }
  }, [token]);

  const handleSubmit = () => {
    const schema = yup.object().shape({
      password: yup
        .string()
        .required(t("common.formErrors.passwordRequired"))
        .min(8, t("common.formErrors.passwordMin"))
        .max(50, t("common.formErrors.passwordMax")),
      repeatpassword: yup
        .string()
        .required("common.formErrors.confirmPasswordRequired")
        .min(8, t("common.formErrors.confirmPasswordMin"))
        .max(50, t("common.formErrors.confirmPasswordMax"))
        .oneOf(
          [yup.ref("password"), null],
          t("common.formErrors.confirmPasswordMatch"),
        ),
      terms: yup
        .boolean()
        .required()
        .oneOf([true], t("common.formErrors.termsRequired")),
      terms2: yup.boolean(),
    });

    schema
      .validate(formState, { abortEarly: false })
      .then(() => {
        setLoading(true);
        axios
          .post(`/onboard/phone`, {
            hash: token,
            password: formState.password,
            passwordConfirmation: formState.repeatpassword,
            membershipAgreement: formState.terms,
            commercialCommunicationAgreement: formState.terms2,
            ip: formState.ip,
          })
          .then((res) => {
            toast({
              variant: "success",
              description: t("verifyPage.passwordUpdated"),
            });
            setLoading(false);
            setTimeout(() => {
              navigateTo("/login");
            }, 1500);
          })
          .catch((err) => {
            setLoading(false);
            const errorText = parseError(err.response.data.errors);
            toast({
              description: errorText,
              variant: "error",
            });
          });
      })
      .catch((err) => {
        const errors = {};
        err.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setFormErrors(errors);
        toast({
          description: t("verifyPage.pleaseFillAllFields"),
          variant: "error",
        });
      });
  };

  const getIP = async () => {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    setFormState({ ...formState, ip: data.ip });
  };

  useEffect(() => {
    getIP();
  }, []);

  if (!showSuccess) {
    return (
      <div className="flex min-h-screen w-full bg-[#F9FAFC]">
        <div className="flex w-full flex-1 flex-col items-center justify-between px-7 pt-6">
          <AuthHeader />
          <div className="flex h-full w-full items-center justify-center">
            <div className="flex w-full max-w-[400px] flex-col gap-5 rounded-lg md:bg-white md:p-6 md:shadow-default">
              <div className="mb-2 flex flex-col items-center gap-3">
                <h2 className="text-[24px] font-semibold">Oppss</h2>
                <p className="text-center text-[16px] font-normal text-[#677389]">
                  {t("verifyPage.phoneVerificationFail")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen w-full bg-[#F9FAFC]">
      <div className="flex w-full flex-1 flex-col items-center justify-between px-7 pt-6">
        <AuthHeader />
        <div className="flex h-full w-full items-center justify-center">
          <div className="flex w-full max-w-[400px] flex-col gap-5 rounded-lg md:bg-white md:p-6 md:shadow-default">
            <div className="mb-2 flex flex-col gap-3">
              <h2 className="text-[24px] font-semibold">
                {t("verifyPage.selectNewPassword")}
              </h2>
              <h3 className="text-[16px] font-normal text-[#677389]">
                {t("verifyPage.minEightChar")}
              </h3>
            </div>
            <PasswordInput
              placeholder={t("verifyPage.password")}
              name="password"
              value={formState.password}
              onChange={(e) => {
                setFormState({ ...formState, password: e.target.value });
              }}
            />
            <PasswordInput
              placeholder={t("verifyPage.confirmPassword")}
              name="password"
              value={formState.repeatpassword}
              onChange={(e) => {
                setFormState({ ...formState, repeatpassword: e.target.value });
              }}
            />
            <label className="mt-2 flex">
              <Checkbox
                onCheckedChange={(value) => {
                  setFormState({ ...formState, terms: value });
                }}
                checked={formState.terms}
              />
              {i18n.language === "tr" ? (
                <span className="ml-2 text-[14px] font-normal text-[#677389]">
                  <a
                    className="text-[#0F6FFF] underline"
                    href="https://egaranti.com/tr/policy/usage-terms/"
                    target={"_blank"}
                  >
                    Üyelik sözleşmesi
                  </a>
                  'ni okudum, kabul ediyorum.
                  <br />
                  <a
                    className="text-[#0F6FFF] underline"
                    href="https://egaranti.com/tr/policy/privacy/"
                    target={"_blank"}
                  >
                    Kişisel Veri Aydınlatma Formu
                  </a>
                  ’nu okudum.
                </span>
              ) : (
                <span className="ml-2 text-[14px] font-normal text-[#677389]">
                  I've read and accepted the{" "}
                  <a
                    className="text-[#0F6FFF] underline"
                    href="https://egaranti.com/tr/policy/usage-terms/"
                    target={"_blank"}
                  >
                    Membership Agreement.
                  </a>{" "}
                  I've read the{" "}
                  <a
                    className="text-[#0F6FFF] underline"
                    href="https://egaranti.com/tr/policy/privacy/"
                    target={"_blank"}
                  >
                    Personal Data Disclosure Form
                  </a>
                </span>
              )}
            </label>
            <label className="mt-2 flex">
              <Checkbox
                onCheckedChange={(value) => {
                  setFormState({ ...formState, terms2: value });
                }}
                checked={formState.terms2}
              />
              {i18n.language === "tr" ? (
                <span className="ml-2 text-[14px] font-normal text-[#677389]">
                  <a
                    className="text-[#0F6FFF] underline"
                    href="https://egaranti.com/tr/policy/ticari-elektronik-ileti/"
                    target={"_blank"}
                  >
                    Ticari Elektronik İleti{" "}
                  </a>{" "}
                  almayı kabul ediyorum.
                </span>
              ) : (
                <span className="ml-2 text-[14px] font-normal text-[#677389]">
                  I accept receiving{" "}
                  <a
                    className="text-[#0F6FFF] underline"
                    href="https://egaranti.com/tr/policy/ticari-elektronik-ileti/"
                    target={"_blank"}
                  >
                    Commercial Electronic Message
                  </a>{" "}
                </span>
              )}
            </label>
            {!loading && (
              <Button onClick={handleSubmit}>{t("common.send")}</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
