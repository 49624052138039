import axios from "@/lib/api/axios";

class PaymentService {
  constructor() {
    this.api = axios;
  }

  async getStoredCards() {
    const { data } = await this.api.get("/store-cards", {
      params: {
        page: 1,
        size: 5,
      },
    });

    return data.content;
  }

  async addStoredCard(data) {
    const response = await this.api.post("/store-cards", data);

    return response;
  }

  async deleteStoredCard(id) {
    const response = await this.api.delete(`/store-cards/${id}`);

    return response;
  }

  async getPastPayments() {
    const { data } = await this.api.get("/payments", {
      params: {
        page: 1,
        size: 10,
      },
    });

    return data.content;
  }

  async postCheckout(data) {
    const response = await this.api.post("/payments/3ds/init", data);

    return response;
  }

  async getPayment(paymentId) {
    const { data } = await this.api.get(`/payments/3ds/${paymentId}`);

    return data;
  }
}

export default new PaymentService();
