import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
  useToast,
} from "@egaranti/components";

import { useTranslation } from "react-i18next";

import { deleteAddress } from "../../lib/api/address";
import { ReactComponent as TrashModalIcon } from "../../assets/svgs/trashmodalicon.svg";

import { parseError } from "@/lib/utils";

/* eslint-disable react/prop-types */
const DeleteAddressDialog = ({ onOpenChange, open, id }) => {
  const { t } = useTranslation();
  const { toast } = useToast();

  const handleDeleteAddress = () => {
    deleteAddress(id)
      .then((res) => {
        onOpenChange();
      })
      .catch((err) => {
        const errorText = parseError(err.response.data.errors);
        toast({
          description: errorText,
          variant: "error",
        });
      });
  };

  return (
    <AlertDialog onOpenChange={onOpenChange} open={open}>
      <AlertDialogContent className="max-w-[388px] gap-0 px-4">
        <div className="flex h-full w-full flex-col items-center justify-center gap-2 p-6">
          <TrashModalIcon />
          <h2 className="text-center text-xl font-semibold">
            {t("accountPage.deleteAddressTitle")}
          </h2>
          <h3 className="text-center text-base font-medium text-[#677389]">
            {t("accountPage.deleteAddressDesc")}
          </h3>
        </div>
        <AlertDialogFooter className="m-0 mt-6">
          <AlertDialogCancel className="w-full">
            {t("common.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button className="w-full" onClick={() => handleDeleteAddress(id)}>
              {t("common.delete")}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteAddressDialog;
