import { Tag } from "@egaranti/components";
import { Input, Label } from "@egaranti/components";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DetailPageHeader from "../../../components/common/DetailPageHeader";
import Loading from "../../../components/common/Loading";
import axios from "../../../lib/api/axios";

import Breadcrumb, {
  BreadcrumbItem,
} from "@/components/common/shared/breadcrumbs";

import { t } from "i18next";

const SetupClaimDetailPage = () => {
  const navigateTo = useNavigate();
  const [claim, setClaim] = useState({});
  const [availableDates, setAvailableDates] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const getClaimDetails = () => {
    axios
      .get(`/individual-customer-product-setup-claims/${id}`)
      .then((res) => {
        setClaim(res.data);

        if (res.data.status === "PENDING") {
          axios
            .get(
              `/individual-customer-product-setup-claims/${id}/available-dates`,
            )
            .then((res) => {
              setAvailableDates(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    // /individual-customer-product-setup-claims/{setupClaimId}/available-dates
  };

  useEffect(() => {
    getClaimDetails();
  }, [id]);

  const STATUS_TYPES = {
    WAITING_MERCHANT: {
      label: t("claimsPage.waitingForAnswerFromCompany"),
      variant: "yellow",
      value: "WAITING_MERCHANT",
    },
    WAITING_CUSTOMER: {
      label: t("claimsPage.waitingForAnswerFromCustomer"),
      variant: "yellow",
      value: "WAITING_CUSTOMER",
    },
    APPROVED: {
      label: t("claimsPage.accepted"),
      variant: "green",
      value: "APPROVED",
    },
    REJECTED: {
      label: t("claimsPage.rejected"),
      variant: "red",
      value: "REJECTED",
    },
    PENDING: {
      label: t("claimsPage.pending"),
      variant: "yellow",
      value: "PENDING",
    },
  };

  const partOfDay = {
    MORNING: "08.00 - 12.00",
    AFTERNOON: "12.00 - 15.00",
    EVENING: "16.00 - 20.00",
  };

  if (loading) return <Loading />;

  const AvailableDatePills = () => {
    const groupedDates = availableDates.reduce((acc, cur) => {
      const date = cur.setupDate;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(cur.partOfDay);
      return acc;
    }, {});

    return (
      <div className="flex flex-wrap gap-2">
        {Object.keys(groupedDates).map((date) => (
          <div className="flex  rounded-lg border bg-[#F1F6FE] p-2 px-3 py-2 text-sm font-medium text-[#2A4ED0]">
            {date}
            {" /"}
            <span>
              {groupedDates[date].map((partOfDayx) => {
                return (
                  <span>
                    {partOfDayx !== groupedDates[date][0] && ", "}
                    {partOfDay[partOfDayx]}
                  </span>
                );
              })}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex w-full flex-col">
      <DetailPageHeader
        title={t("claimsPage.setupClaimDetails")}
        returnPath="/claims/setup"
      />
      <Breadcrumb>
        <BreadcrumbItem link="/claims/setup">
          {t("claimsPage.myClaims")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("claimsPage.setupDetails")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="min-h-[calc(100vh_-_64px)] w-full bg-[#F9FAFC] p-4">
        <div className="mx-auto mt-6 flex max-w-[1000px] flex-col gap-5 rounded-lg border border-[#EAECF0] bg-white p-6">
          <h2 className="font-medium">{t("claimsPage.setupInfo")}</h2>
          <div className="flex flex-col gap-2">
            <Label>{t("claimsPage.productName")}</Label>
            <Input value={claim.individualCustomerProductName} disabled />
          </div>
          <div className="flex flex-col gap-2">
            <Label>{t("claimsPage.note")}</Label>
            <Input value={claim.customerNote} disabled />
          </div>
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="flex flex-1 flex-col gap-2">
              <Label>{t("claimsPage.requestCreationDate")}</Label>
              <Input value={claim.createdAt} disabled />
            </div>
            <div className="flex flex-1 flex-col gap-2">
              <Label>{t("claimsPage.lastUpdateDate")}</Label>
              <Input value={claim.updatedAt} disabled />
            </div>
          </div>
          {claim.status === "PENDING" && (
            <div className="flex flex-col flex-wrap  justify-between gap-2 rounded-lg border bg-[#F2F5F9] px-2 py-3">
              <h4 className="600 text-sm font-medium text-[#677389]">
                {t("claimsPage.requestedDates")}:
              </h4>
              <AvailableDatePills />
            </div>
          )}
          {/*not shown when REJECTED  */}
          {claim.status !== "REJECTED" && (
            <div className="flex flex-col gap-4 rounded-xl border border-dashed border-blue-600 bg-blue-700/10 p-3 md:flex-row">
              <div className="flex flex-1 flex-col gap-2">
                <Label>{t("claimsPage.setupDate")}</Label>
                <Input
                  value={claim?.setupDate || t("claimsPage.pending")}
                  disabled
                />
              </div>
              <div className="flex flex-1 flex-col gap-2">
                <Label>{t("claimsPage.setupHour")}</Label>
                <Input
                  value={partOfDay[claim?.partOfDay] || t("claimsPage.pending")}
                  disabled
                />
              </div>
            </div>
          )}
          <h2 className="font-medium">{t("claimsPage.addressInfo")}</h2>
          <div className="flex flex-col gap-2">
            <Label>{t("claimsPage.heading")}</Label>
            <Input value={claim?.address?.title} disabled />
          </div>
          <div className="flex flex-col gap-2">
            <Label>{t("claimsPage.address")}</Label>
            <Input value={claim?.address?.addressLine} disabled />
          </div>
          <Tag variant={STATUS_TYPES[claim.status]?.variant}>
            {STATUS_TYPES[claim.status]?.label}
          </Tag>
          <div className="flex flex-col gap-2">
            <Label>{t("claimsPage.noteFromCompany")}</Label>
            <Input value={claim?.merchantNote} disabled />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupClaimDetailPage;
