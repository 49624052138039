import addRequestInterceptor from "./requestInterceptor";
import addResponseInterceptor from "./responseInterceptor";

import axios from "axios";

const instance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.defaults.paramsSerializer = (params) =>
  new URLSearchParams(params).toString();

addRequestInterceptor(instance);
addResponseInterceptor(instance);

export default instance;
