import {
  Button,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as InfoIcon } from "@/assets/svgs/infoemptyicon.svg";

import { t } from "i18next";

import axios from "@/lib/api/axios";
import DialCodes from "@/lib/utils/data/dialCodes";

const CreateForm = ({
  formData,
  setFormData,
  addresses,
  handleCreateClaim,
}) => {
  const navigateTo = useNavigate();
  if (!formData.individualCustomerProductId) return null;

  useEffect(() => {
    axios
      .get(
        "/merchant-service-claim-options" +
          `?individualCustomerProductId=${formData.individualCustomerProductId}`,
      )
      .then((res) => {
        setFormData({
          ...formData,
          productClaimReasons: res.data,
        });
      });
  }, [formData.individualCustomerProductId]);

  return (
    <div className="mx-auto mt-6 flex max-w-[1000px] flex-col gap-5 rounded-lg border border-[#EAECF0] bg-white p-6">
      <h1 className="text-lg font-semibold text-[#111729]">
        {t("claimsPage.enterClaimDetails")}
      </h1>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div className="flex w-full items-center justify-between gap-2">
          <div className="w-[115px]">
            <Label>{t("claimsPage.countryCode")}</Label>
            <Select
              onValueChange={(value) => {
                setFormData({
                  ...formData,
                  countryCode: value,
                });
              }}
              defaultValue="TR"
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {DialCodes.map((category, index) => {
                  return (
                    <SelectItem key={index} value={category.value}>
                      {category.label}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-col gap-2">
            <Label>{t("claimsPage.phoneNumber")}</Label>
            <Input
              value={formData.phone}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  phone: e.target.value,
                })
              }
            />
          </div>
        </div>
        {addresses.length > 0 ? (
          <div className="flex flex-col gap-2">
            <Label>{t("claimsPage.address")}</Label>
            <Select
              onValueChange={(value) => {
                setFormData({
                  ...formData,
                  individualCustomerAddressId: value,
                });
              }}
              defaultValue="TR"
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {addresses
                  .map((address) => ({
                    label: address.title,
                    value: address.id,
                  }))
                  .map((category, index) => {
                    return (
                      <SelectItem key={index} value={category.value}>
                        {category.label}
                      </SelectItem>
                    );
                  })}
              </SelectContent>
            </Select>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-4 p-4">
            <InfoIcon className="w-12" />
            <p className="text-center text-sm font-medium text-[#364153]">
              {t("claimsPage.noAddress")}
            </p>
            <Button onClick={() => navigateTo("/account/address/add")}>
              {t("claimsPage.addAddress")}
            </Button>
          </div>
        )}
      </div>

      {formData.productClaimReasons.length > 0 && (
        <div className="flex flex-col gap-2">
          <Label>{t("claimsPage.requestReason")}</Label>
          <Select
            onValueChange={(e) => {
              if (e === "OTHER") {
                setFormData({
                  ...formData,
                  option: "OTHER",
                  description: "",
                });
              } else {
                setFormData({
                  ...formData,
                  option: formData?.productClaimReasons.find(
                    (option) => option?.id === e,
                  ).option,
                  description: formData?.productClaimReasons.find(
                    (option) => option?.id === e,
                  ).option,
                });
              }
            }}
            defaultValue="OTHER"
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {formData?.productClaimReasons
                .map((option) => ({
                  label: option.option,
                  value: option.id,
                }))
                .map((category, index) => {
                  return (
                    <SelectItem key={index} value={category.value}>
                      {category.label}
                    </SelectItem>
                  );
                })}
              <SelectItem value="OTHER">{t("claimsPage.other")}</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}
      {formData.option === "OTHER" && (
        <div className="flex flex-col gap-2">
          <Label>{t("claimsPage.requestReason")}</Label>
          <Input
            value={formData.description}
            onChange={(e) =>
              setFormData({
                ...formData,
                description: e.target.value,
              })
            }
          />
        </div>
      )}
      <div className="mx-auto mt-4 flex w-full max-w-[1000px] justify-end">
        <Button onClick={handleCreateClaim}>
          {t("claimsPage.createClaim")}
        </Button>
      </div>
    </div>
  );
};

export default CreateForm;
