import {
  Button,
  Checkbox,
  Label,
  RadioGroup,
  RadioGroupItem,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import AddAddressDialog from "@/components/Payment/addAddressDialog";
import CreditCardForm from "@/components/Payment/creditCardForm";
import Stepper from "@/components/Services/GetExtendedWarranty/Stepper";
import Breadcrumb, {
  BreadcrumbItem,
} from "@/components/common/shared/breadcrumbs";

import PaperIcon from "@/assets/images/overview/insurance.png";
import { ReactComponent as MasterCardIcon } from "@/assets/svgs/masterCard.svg";
import { ReactComponent as TroyIcon } from "@/assets/svgs/troy.svg";
import { ReactComponent as VisaIcon } from "@/assets/svgs/visa.svg";

import * as yup from "yup";

import { getAllAddress } from "@/lib/api/address";
import paymentService from "@/lib/api/payment";

const icon = {
  MASTER_CARD: <MasterCardIcon className="h-8 w-8" />,
  VISA: <VisaIcon className="h-8 w-8" />,
  TROY: <TroyIcon className="h-8 w-8" />,
};

const schema = (t) =>
  yup.object().shape({
    cardHolderName: yup
      .string()
      .min(1, t("payment.addAddressDialog.form.msg1")),
    cardNumber: yup.string().min(1, t("payment.addAddressDialog.form.msg2")),
    expireDate: yup.string().min(1, t("payment.addAddressDialog.form.msg3")),
    cvc: yup.string().min(1, t("payment.addAddressDialog.form.msg4")),
  });

// with stored card
const storedCardSchema = (t) =>
  yup.object().shape({
    selectedStoredCard: yup
      .object()
      .required(t("payment.addAddressDialog.form.msg6")),
  });

const GetExtendedWarrantyStep3 = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const location = useLocation();
  const [onOpenAddressDialog, setOpenAddressDialog] = useState(false);
  const [address, setAddress] = useState([]);
  const [storedCards, setStoredCards] = useState([]);
  const [payWithStoredCard, setPayWithStoredCard] = useState(false);

  const form = useForm({
    resolver: yupResolver(payWithStoredCard ? storedCardSchema(t) : schema(t)),
    defaultValues: {
      cardHolderName: "",
      cardNumber: "",
      expireDate: "",
      cvc: "",
      focus: "",
      selectedStoredCard: null,
      storeCardAfterSuccessPayment: false,
      addressId: null,
      cardAlias: "",
    },
  });

  const onSubmit = (data) => {
    const payload = {
      currency: "TRY",
      isThreeDS: true,
      addressId: data.addressId,
      card: {
        cardUserKey: data.selectedStoredCard?.cardUserKey,
        cardToken: data.selectedStoredCard?.cardToken,
        cardAlias: data.cardAlias || data.cardHolderName,
        cardHolderName: data.cardHolderName,
        cardNumber: data.cardNumber.replace(/\s/g, ""),
        expireYear: data.expireDate.split("/")[1],
        expireMonth: data.expireDate.split("/")[0],
        cvc: data.cvc,
        storeCardAfterSuccessPayment: data.storeCardAfterSuccessPayment,
      },
      elements: [
        {
          name: "extendedwarranty-" + Math.random().toString(36).substring(7),
          price: location.state.selectedPackage.offerPrice,
          quantity: 1,
        },
      ],
      additionalParameters: {
        externalProductId: location.state.selectedPackage.externalProductId,
        imei: location.state.imei,
        identityNumber: location.state.tc,
      },
    };

    paymentService
      .postCheckout(payload)
      .then((res) => {
        if (res.data.htmlContent) {
          window.document.write(atob(res.data.htmlContent));
        }
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  const getStoredCards = () => {
    paymentService.getStoredCards().then((res) => setStoredCards(res));
  };

  useEffect(() => {
    // get all state in location.state set in form
    getStoredCards();
    getAllAddress().then((res) => setAddress(res.data));
    form.setValue("product", location.state.product);
    form.setValue("selectedPackage", location.state.selectedPackage);
    form.setValue("imei", location.state.imei);
    form.setValue("tc", location.state.tc);
    form.setValue("birthDate", location.state.birthDate);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getAllAddress().then((res) => setAddress(res.data));
    }, 1000);
  }, [onOpenAddressDialog]);

  const onChangePayWithStoredCard = () => {
    setPayWithStoredCard(!payWithStoredCard);
    form.setValue("selectedStoredCard", null);
    form.setValue("cardHolderName", "");
    form.setValue("cardNumber", "");
    form.setValue("expireDate", "");
    form.setValue("cvc", "");
  };

  return (
    <div className="min-h-screen md:bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/services">
          {t("services.services")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("extendedWarrantyPage.title")}
        </BreadcrumbItem>
      </Breadcrumb>
      <main className="mx-auto max-w-6xl p-5 md:py-12">
        <Stepper
          steps={[
            { title: t("extendedWarrantyPage.steps.step1") },
            { title: t("extendedWarrantyPage.steps.step2") },
            { title: t("extendedWarrantyPage.steps.step3") },
          ]}
          step={2}
        />
        <div className="flex flex-col gap-6 md:flex-row">
          <div className="flex flex-grow flex-col gap-6">
            <ProductCard
              price={form.watch("selectedPackage")?.offerPrice}
              title={form.watch("selectedPackage")?.offerName}
            />
            <div className="flex-grow rounded-lg border bg-white p-6 shadow-sm">
              <AddressSelectSection
                form={form}
                address={address}
                t={t}
                setOpenAddressDialog={setOpenAddressDialog}
              />
              <div className="flex items-center justify-between pb-4">
                <h2 className="text-lg font-medium text-[#364153]">
                  {t("extendedWarrantyPage.step3.section1.paymentInfo")}
                </h2>
                {storedCards.length > 0 && (
                  <Button
                    variant="secondaryColor"
                    onClick={() => onChangePayWithStoredCard()}
                  >
                    {payWithStoredCard
                      ? t("extendedWarrantyPage.step3.section1.newCard")
                      : t("extendedWarrantyPage.step3.section1.useStoredCard")}
                  </Button>
                )}
              </div>
              {!payWithStoredCard ? (
                <CreditCardForm
                  form={form}
                  onSubmit={onSubmit}
                  actionText={t("extendedWarrantyPage.step3.section1.pay")}
                />
              ) : (
                <SelectStoredCardSection
                  storedCards={storedCards}
                  form={form}
                  onSubmit={onSubmit}
                  t={t}
                />
              )}
            </div>
          </div>
          <Summary price={form.watch("selectedPackage")?.offerPrice} />
        </div>
      </main>
      <AddAddressDialog
        open={onOpenAddressDialog}
        onOpenChange={setOpenAddressDialog}
      />
    </div>
  );
};

export default GetExtendedWarrantyStep3;

const Summary = ({ price }) => {
  const { t } = useTranslation();
  return (
    <div className="h-full w-full rounded-lg border bg-white p-6 shadow-sm md:w-1/3">
      <h2 className="font-semibold text-[#677389]">
        {t("extendedWarrantyPage.step3.section1.summary")}
      </h2>
      <div className="mt-6 flex flex-col items-center justify-between rounded-lg border">
        <div className="flex w-full items-center justify-between px-8 py-4">
          <span className="font-medium text-[#677389]">
            {t("extendedWarrantyPage.step3.section1.subtotal")}
          </span>
          <span className="font-semibold text-[#111729]">{price}₺</span>
        </div>
        <div className="flex w-full items-center justify-between border-t px-8 py-4">
          <span className="font-medium text-[#677389]">
            {t("extendedWarrantyPage.step3.section1.total")}
          </span>
          <span className="font-semibold text-[#111729]">{price}₺</span>
        </div>
      </div>
    </div>
  );
};

const ProductCard = ({ title, price }) => {
  return (
    <div className="flex items-center justify-between rounded-lg border bg-white p-6 shadow-sm">
      <div className="flex items-center gap-2">
        <img className="h-20" src={PaperIcon} aria-hidden />
        <h1 className="text-xl font-medium text-[#111729]">{title}</h1>
      </div>
      <span className="text-xl font-semibold text-[#111729]">{price}₺</span>
    </div>
  );
};

const SelectStoredCardSection = ({ storedCards, form, onSubmit, t }) => {
  return (
    <div className="flex flex-col">
      <RadioGroup
        onValueChange={(e) =>
          form.setValue("selectedStoredCard", JSON.parse(e))
        }
        value={JSON.stringify(form.watch("selectedStoredCard"))}
        className="mt-4 rounded-lg shadow"
      >
        {storedCards?.map((card) => (
          <div
            key={card.id}
            className="flex items-center space-x-3 border-b  border-b-[#F2F5F9] px-4"
          >
            <RadioGroupItem value={JSON.stringify(card)} id={card.id} />
            <Label
              className="flex w-full cursor-pointer items-center justify-between py-3 text-sm font-medium text-[#344054]"
              htmlFor={card.id}
            >
              <h3>{card.cardAlias}</h3>
              <span className="flex items-center gap-3 text-sm font-medium text-[#344054]">
                <span>**** **** **** {card.lastFourDigits}</span>
                {icon[card.cardAssociation]}
              </span>
            </Label>
          </div>
        ))}
      </RadioGroup>
      <div>
        <Label className="mt-4 flex items-start gap-2 text-sm text-[#364153]">
          <Checkbox
            checked={form.watch("techpoint")}
            onClick={() => {
              form.setValue("techpoint", !form.watch("techpoint"));
            }}
          />
          {t("payment.addCreditCard.techpoint")}
          <a
            className="text-sm font-semibold text-[#0049E6]"
            href="https://cdn.egaranti.com/static/E-Garanti_Uzat%C4%B1lm%C4%B1s%CC%A7_I%CC%87htiyari_Garanti_O%CC%88nbilgilendirme_Formu.pdf"
            target="_blank"
          >
            {t("extendedWarrantyPage.download")}
          </a>
        </Label>
      </div>
      <Button
        disabled={
          !form.watch("selectedStoredCard") ||
          !form.watch("addressId") ||
          !form.watch("techpoint")
        }
        onClick={form.handleSubmit(onSubmit)}
        className="mt-4"
      >
        {t("extendedWarrantyPage.step3.section1.pay")}
      </Button>
    </div>
  );
};

const AddressSelectSection = ({ form, address, t, setOpenAddressDialog }) => {
  return (
    <div className="mb-8 flex flex-col">
      <Label>{t("extendedWarrantyPage.step3.section1.title")}</Label>
      <RadioGroup
        onValueChange={(e) => form.setValue("addressId", e)}
        value={form.watch("addressId")}
        className="mt-4 rounded-lg shadow"
        error={form.formState.errors.addressId?.message}
      >
        {address.map((item) => (
          <div
            key={item.id}
            className="flex items-center space-x-3 border-b  border-b-[#F2F5F9] px-4"
          >
            <RadioGroupItem value={item.id} id={item.id} />
            <Label
              className="w-full cursor-pointer py-3 text-sm font-medium text-[#344054]"
              htmlFor={item.id}
            >
              {item.title}{" "}
              <span className="text-xs  text-[#677389]">
                {item.addressLine.substring(0, 26)}...
              </span>
            </Label>
          </div>
        ))}
        <button
          onClick={() => setOpenAddressDialog(true)}
          className="flex w-full items-center gap-2 rounded-lg rounded-t-none px-4 py-3 text-sm font-medium text-[#0049E6]"
        >
          {/* <BluePlusIcon /> */}
          {t("extendedWarrantyPage.step3.section1.addAddress")}
        </button>
      </RadioGroup>
    </div>
  );
};
