import { useTranslation } from "react-i18next";

const NotActiveContainer = ({ formData }) => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto mt-6 flex max-w-[1000px] flex-col items-center gap-5 rounded-lg border border-[#EAECF0] bg-white p-6 text-center">
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#AAC2F7" />
        <path
          d="M22 34L34 22M34 22H26M34 22V30"
          stroke="#002573"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="4"
          y="4"
          width="48"
          height="48"
          rx="24"
          stroke="#CCDBFA"
          stroke-width="8"
        />
      </svg>
      <h2 className="text-lg font-medium text-[#111729]">
        {t("claimsPage.serviceRequestRouting")}
      </h2>
      <p className="text-sm text-[#677389]">
        {t("claimsPage.contactBrandDesc")}
      </p>
      {formData?.activeStatus?.type === "LINK" && (
        <a
          className="rounded-lg bg-[#0049E6] px-2 py-3 text-sm font-medium text-white"
          href={formData?.activeStatus?.redirectValue}
          target="_blank"
          rel="noreferrer"
        >
          {t("claimsPage.contactBrand")}
        </a>
      )}
      {formData?.activeStatus?.type === "PHONE" && (
        <a
          href={`tel:${formData.activeStatus?.redirectValue}`}
          className="text-sm text-[#677389]"
        >
          {t("claimsPage.fakirCustomerService")}{" "}
          <span className="font-semibold text-[#111729]">
            {formData.activeStatus?.redirectValue}
          </span>
        </a>
      )}
      {formData?.activeStatus?.type === "EMAIL" && (
        <a
          href={`mailto:${formData.activeStatus?.redirectValue}?subject=Servis%20Talebi&body=Merhaba,%20${formData.activeStatus?.email}%20adresine%20servis%20talebi%20oluşturulmuştur.%20Lütfen%20talebinizi%20inceleyiniz.%20Teşekkürler.`}
          className="text-sm text-[#677389]"
        >
          {t("claimsPage.fakirCustomerService")}{" "}
          <span className="font-semibold text-[#111729]">
            {formData.activeStatus?.redirectValue}
          </span>
        </a>
      )}
    </div>
  );
};

export default NotActiveContainer;
