import { useEffect } from "react";

import ProductListHeader from "../../components/Products/ProductListHeader";
import UnVerifiedProductsList from "../../components/Products/UnVerifiedProductsList";
import VerifiedProductsList from "../../components/Products/VerifiedProductsList";
import useProductStore from "../../store/useProductStore";

import FilterSheet from "@/components/Products/FilterSheet";
import AddNewProductSheet from "@/components/newaddproduct/ProductForm";

export default function Products() {
  const {
    filters,
    setFilters,
    openFilterSheet,
    setOpenFilterSheet,
    openAddProductSheet,
    setOpenAddProductSheet,
    fetchProducts,
    verifiedProducts,
    unVerifiedProducts,
  } = useProductStore();

  useEffect(() => {
    // Initial fetch
    fetchProducts(filters);
  }, []);

  return (
    <div className="p-5 pb-[100px] md:pb-8">
      <div className="flex w-full flex-col items-center justify-center gap-y-4 md:gap-y-0">
        <div className="flex w-full flex-col items-center justify-center">
          <ProductListHeader
            filters={filters}
            setFilters={setFilters}
            fetchProducts={fetchProducts}
          />
          <div className="mt-8 flex w-full flex-col gap-8 rounded-lg md:bg-white md:p-5 md:shadow-sm">
            <VerifiedProductsList
              verifiedProducts={verifiedProducts || []}
              fetchProducts={fetchProducts}
            />
            <hr className="h-px w-full bg-[#CDD5E0]" />
            <UnVerifiedProductsList
              unverifiedProducts={unVerifiedProducts}
              fetchProducts={fetchProducts}
            />
          </div>
        </div>
      </div>
      <AddNewProductSheet
        open={openAddProductSheet}
        onOpenChange={setOpenAddProductSheet}
      />
      <FilterSheet
        fetchProducts={fetchProducts}
        setFilters={setFilters}
        filters={filters}
        open={openFilterSheet}
        onOpenChange={setOpenFilterSheet}
      />
    </div>
  );
}
