import { Button } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

import egarantiLogo from "../assets/images/egarantilogo.png";
import plugImage from "../assets/images/plug.png";

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="flex h-screen w-full flex-col items-center bg-[#F9FAFC] p-[50px]">
      <img className="w-[200px]" src={egarantiLogo} alt="egaranti" />
      <div className="mt-12 flex flex-col items-center">
        <h2 className="text-8xl font-bold text-white drop-shadow-xl">404</h2>
        <p className="mt-4 text-center font-medium text-[#111729]">
          {t("notFoundPage.notFound", "Bütün ışıkları kim kapattı?")}
          <br />
          {t(
            "notFoundPage.notFoundDesc",
            "Üzgünüm, şu anda bu sayfayı bulamıyoruz.",
          )}
        </p>
        <Button
          className="mt-8"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          {t("notFoundPage.backToHome", "Anasayfaya dön")}
        </Button>
      </div>
      <img
        className="sr-hidden absolute bottom-0 right-0 max-h-[300px] w-full"
        src={plugImage}
      />
    </div>
  );
}
