import { cn } from "@/lib/utils";

const Slot = (props) => {
  return (
    <div
      className={cn(
        "flex h-8 w-8 items-center justify-center rounded-md border border-[#D3DBE9] font-medium text-[#364153] md:h-10 md:w-10",
        props.isActive && "border-[#0049E6] text-[#111729]",
      )}
      {...props}
    >
      {props.char !== null && <div>{props.char}</div>}
    </div>
  );
};

export default Slot;
