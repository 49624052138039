import { Button } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as EditIcon } from "../../../assets/svgs/editicon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/svgs/trashicon.svg";
import { getAllAddress } from "../../../lib/api/address";

import DeleteAddressDialog from "@/components/Account/DeleteAddressDialog";
import Breadcrumb, {
  BreadcrumbItem,
} from "@/components/common/shared/breadcrumbs";
import InfoTooltip from "@/components/common/shared/infoTooltip";

export default function Address() {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [addressList, setAddressList] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState([]);

  const [openDeleteAddressDialog, setOpenDeleteAddressDialog] =
    React.useState(false);

  const handleEditAddress = (id) => {
    navigateTo(`/account/address/edit/${id}`, { replace: true });
  };

  const handleDeleteAddress = (id) => {
    setSelectedAddress(id);
    setOpenDeleteAddressDialog(true);
  };

  useEffect(() => {
    setTimeout(() => {
      getAllAddress().then((res) => {
        setAddressList(res.data);
        setLoading(false);
      });
    }, 1000);
  }, [openDeleteAddressDialog]);

  return (
    <div className="flex w-full items-center justify-between">
      <main className="w-full flex-col bg-[#F9FAFC]">
        <Breadcrumb>
          <BreadcrumbItem link="/account">
            {t("accountPage.myAccount")}
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("accountPage.myAddresses")}</BreadcrumbItem>
        </Breadcrumb>
        <div className="h-full p-7">
          <div className="flex max-w-[800px] flex-col gap-6">
            <div className="flex items-center justify-between">
              <h2 className="flex items-center gap-2 font-medium text-[#97a2b6]">
                {t("accountPage.myAddresses")}
                <InfoTooltip>{t("accountPage.myAddressesDesc")}</InfoTooltip>
              </h2>
              <Button onClick={() => navigateTo("/account/address/add")}>
                {t("accountPage.addAddress")}
              </Button>
            </div>
            <div className="rounded-lg">
              <Table className="border border-[#EAECF0] bg-white shadow-sm">
                <TableHeader className="rounded-lg border border-[#E3E7EF] bg-[#F9FAFC] font-semibold text-[#364153]">
                  <TableRow>
                    <TableHead>{t("accountPage.title")}</TableHead>
                    <TableHead>{t("accountPage.address")}</TableHead>
                    <TableHead>{t("accountPage.actions")}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {addressList.map((address, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <span
                          onClick={() => {
                            navigateTo(`/account/address/${address.id}`);
                          }}
                          className="cursor-pointer text-[#111729]"
                        >
                          {address.title}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          onClick={() => {
                            navigateTo(`/account/address/${address.id}`);
                          }}
                          className="cursor-pointer text-[#677389]"
                        >
                          {address.addressLine}
                        </span>
                      </TableCell>
                      <TableCell>
                        <button
                          onClick={() => handleEditAddress(address.id)}
                          className="rounded-lg p-2 hover:bg-[#F2F5F9]"
                        >
                          <EditIcon />
                        </button>
                        <button
                          onClick={() => handleDeleteAddress(address.id)}
                          className="rounded-lg p-2 hover:bg-[#FEF3F2]"
                        >
                          <TrashIcon />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <div className="flex justify-end gap-3">
              <Button
                className="w-full md:w-auto"
                onClick={() => navigateTo("/account")}
                variant="secondaryGray"
              >
                {t("accountPage.goBack")}
              </Button>
            </div>
          </div>
        </div>
      </main>
      <DeleteAddressDialog
        onOpenChange={() => setOpenDeleteAddressDialog(false)}
        open={openDeleteAddressDialog}
        id={selectedAddress}
      />
    </div>
  );
}
