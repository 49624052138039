import { useNavigate } from "react-router-dom";

import DummyFileImage from "@/assets/images/dummyfile.png";

const DocumentSection = ({ verifiedDocuments, unverifiedDocuments, t }) => {
  const navigate = useNavigate();

  const DocumentItem = ({ item }) => {
    return (
      <div className="flex w-full items-center justify-between rounded-lg border border-[#E3E7EF] bg-[#F2F5F9] p-4">
        <div className="flex items-center gap-2">
          <img src={DummyFileImage} className="h-10 w-10" aria-hidden />
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-medium text-[#111729]">{item.name}</h3>
          </div>
        </div>
        <button
          onClick={() => navigate(`/file-preview?url=${item.documentUrl}`)}
          aria-label="İndir"
          className="flex shrink-0 items-center justify-center p-2"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.6667 8.33333L17.5 2.5M17.5 2.5H12.5M17.5 2.5V7.5M8.33333 11.6667L2.5 17.5M2.5 17.5H7.5M2.5 17.5L2.5 12.5"
              stroke="#111729"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <>
      {verifiedDocuments.filter((doc) => doc.id !== null).length === 0 ? (
        <div className="mt-8 flex w-full flex-col items-center justify-center px-6">
          <div className="flex w-full justify-between">
            <h3 className="text-base font-bold uppercase text-[#677389]">
              {t("Onaylı Belgeler")}
            </h3>
          </div>
          <div className="mt-4 flex w-full flex-col items-center justify-center gap-3 pb-8">
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* SVG content */}
            </svg>
            <p className="text-xs text-gray-500">
              {t(
                "Herhangi bir garanti belgeniz bulunmamaktadır. Lütfen satıcı ile iletişime geçiniz",
              )}
            </p>
          </div>
        </div>
      ) : (
        <div className="mt-8 flex w-full flex-col items-center justify-center px-6">
          <div className="flex w-full justify-between">
            <h3 className="text-base font-bold uppercase text-[#677389]">
              {t("productsPage.approvedDocuments")}
            </h3>
          </div>
          <div className="mt-4 flex w-full flex-col gap-3 pb-8">
            {verifiedDocuments
              .filter((doc) => doc.id !== null)
              .map((item) => (
                <DocumentItem key={item.id} item={item} />
              ))}
          </div>
        </div>
      )}
      {!unverifiedDocuments.every((doc) => doc.documentUrl === null) && (
        <div className="mt-8 flex w-full flex-col items-center justify-center px-6">
          <div className="flex w-full justify-between">
            <h3 className="text-base font-bold uppercase text-[#677389]">
              {t("productsPage.otherDocuments")}
            </h3>
          </div>
          <div className="mt-4 flex w-full flex-col gap-3 pb-8">
            {unverifiedDocuments.map(
              (item) =>
                item.documentUrl && <DocumentItem key={item.id} item={item} />,
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentSection;
