import { Button } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "../../lib/api/axios";

import EditSheet from "@/components/Account/EditSheet";
import Breadcrumb, {
  BreadcrumbItem,
} from "@/components/common/shared/breadcrumbs";

export default function Profile() {
  const { t } = useTranslation();

  const [openChangePasswordSheet, setOpenChangePasswordSheet] = useState(false);
  const [openEditSheet, setOpenEditSheet] = useState(false);
  const [openConfirmEmailDialog, setOpenConfirmEmailDialog] = useState(false);
  const [openConfirmPhoneDialog, setOpenConfirmPhoneDialog] = useState(false);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirmation: "",
  });

  const getProfile = () => {
    axios
      .get("/individual-customers/info")
      .then((res) => {
        setData({
          ...res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile();
    setFormData({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    });
  }, [openEditSheet, openConfirmPhoneDialog, openConfirmEmailDialog]);

  return (
    <main>
      <Breadcrumb>
        <BreadcrumbItem link="/account">
          {t("accountPage.myAccount")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("accountPage.profileInformation")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="max-w-3xl p-5 pb-[100px] md:pb-8">
        <div className="mb-2 flex items-center justify-between pb-3 pt-5">
          <h2 className="font-medium text-[#97a2b6]">
            {t("accountPage.profileInformation")}
          </h2>
          <Button
            onClick={() => {
              setOpenEditSheet(true);
            }}
            variant="secondaryGray"
          >
            {t("accountPage.edit")}
          </Button>
        </div>
        <div className="flex flex-col gap-4 rounded-lg border border-[#F2F5F9] bg-white p-6 shadow-sm">
          <div className="flex flex-col gap-2 border-b pb-4">
            <label className="text-sm font-medium text-[#97a2b6]">
              {t("accountPage.name")}
            </label>
            <p className="text-sm font-semibold text-[#2b2b2b]">
              {data.firstName}
            </p>
          </div>
          <div className="flex flex-col gap-2 border-b pb-4">
            <label className="text-sm font-medium text-[#97a2b6]">
              {t("accountPage.surname")}
            </label>
            <p className="text-sm font-semibold text-[#2b2b2b]">
              {data.lastName}
            </p>
          </div>
          <div className="flex items-center justify-between border-b pb-4">
            <div className="flex flex-col gap-2 ">
              <label className="text-sm font-medium text-[#97a2b6]">
                {t("accountPage.email")}
              </label>
              <p className="text-sm font-semibold text-[#0049E6] underline">
                {data.email ? data.email : t("accountPage.emailNotFound")}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-2 ">
              <label className="text-sm font-medium text-[#97a2b6]">
                {t("accountPage.phoneNumber")}
              </label>
              <p className="text-sm font-semibold text-[#0049E6] underline">
                {data.phone ? data.phone : t("accountPage.phoneNumberNotFound")}
              </p>
            </div>
          </div>
        </div>
        <EditSheet
          open={openEditSheet}
          onOpenChange={setOpenEditSheet}
          getProfile={getProfile}
          data={data}
        />
      </div>
    </main>
  );
}
