import React, { forwardRef, useRef, useState } from "react";

import classNames from "classnames";

const InputBox = forwardRef(
  (
    { className, value, onChange, placeholder, type = "text", ...rest },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const toggleFocus = (focusState) => setIsFocused(focusState);

    return (
      <div className="relative w-full">
        <label
          onClick={() => ref.current.focus()}
          className={classNames(
            "absolute left-4 cursor-text select-none text-sm font-medium text-[#677389] transition-all",
            {
              "top-1": isFocused || value,
              "top-5": !isFocused && !value,
            },
          )}
        >
          {placeholder}
        </label>
        <input
          ref={ref}
          type={type}
          className={classNames(
            "w-full rounded-md border border-[#cdd5e0] p-4 shadow-sm",
            className,
          )}
          onFocus={() => toggleFocus(true)}
          onBlur={() => toggleFocus(false)}
          value={value}
          onChange={onChange}
          {...rest}
        />
      </div>
    );
  },
);

export default InputBox;
