function useSlideDrag(sliderRef) {
  let isDown = false;
  let startX;
  let scrollLeft;

  const handleMouseDown = (e) => {
    isDown = true;
    sliderRef.current.classList.add("active");
    startX = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown = false;
    sliderRef.current.classList.remove("active");
  };

  const handleMouseUp = () => {
    isDown = false;
    sliderRef.current.classList.remove("active");
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  return { handleMouseDown, handleMouseLeave, handleMouseUp, handleMouseMove };
}

export default useSlideDrag;
