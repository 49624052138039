import React from "react";

const ServiceButtons = ({ toastInform, t }) => {
  const buttons = [
    {
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0002 12.0836V7.08363M7.50016 9.58363H12.5002M16.6668 10.0003C16.6668 14.0907 12.2052 17.0656 10.5818 18.0127C10.3973 18.1203 10.3051 18.1741 10.1749 18.2021C10.0739 18.2237 9.92646 18.2237 9.82543 18.2021C9.69524 18.1741 9.603 18.1203 9.4185 18.0127C7.79513 17.0656 3.3335 14.0907 3.3335 10.0003V6.01497C3.3335 5.34871 3.3335 5.01558 3.44246 4.72922C3.53872 4.47625 3.69515 4.25053 3.89821 4.07157C4.12808 3.869 4.43999 3.75203 5.06383 3.51809L9.532 1.84253C9.70524 1.77756 9.79187 1.74508 9.88098 1.7322C9.96003 1.72078 10.0403 1.72078 10.1193 1.7322C10.2085 1.74508 10.2951 1.77756 10.4683 1.84253L14.9365 3.51809C15.5603 3.75203 15.8722 3.869 16.1021 4.07157C16.3052 4.25053 16.4616 4.47625 16.5579 4.72922C16.6668 5.01558 16.6668 5.34871 16.6668 6.01497V10.0003Z"
            stroke="#364153"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      onClick: () => toastInform(t("productsPage.tooltips.text1")),
    },
    {
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.0262 6.3598C12.6962 6.02979 12.5311 5.86478 12.4693 5.67451C12.4149 5.50714 12.4149 5.32685 12.4693 5.15948C12.5311 4.9692 12.6962 4.8042 13.0262 4.47418L15.3915 2.10887C14.7638 1.82501 14.067 1.66699 13.3334 1.66699C10.5719 1.66699 8.33336 3.90557 8.33336 6.66699C8.33336 7.07619 8.38252 7.47391 8.47524 7.85456C8.57454 8.26219 8.62419 8.46601 8.61538 8.59477C8.60615 8.72957 8.58605 8.80129 8.52389 8.92125C8.46451 9.03584 8.35074 9.14961 8.12321 9.37714L2.91669 14.5837C2.22634 15.274 2.22634 16.3933 2.91669 17.0837C3.60705 17.774 4.72634 17.774 5.41669 17.0837L10.6232 11.8771C10.8507 11.6496 10.9645 11.5358 11.0791 11.4765C11.1991 11.4143 11.2708 11.3942 11.4056 11.385C11.5343 11.3762 11.7382 11.4258 12.1458 11.5251C12.5264 11.6178 12.9242 11.667 13.3334 11.667C16.0948 11.667 18.3334 9.42842 18.3334 6.66699C18.3334 5.93332 18.1753 5.23655 17.8915 4.60887L15.5262 6.97418C15.1962 7.3042 15.0311 7.4692 14.8409 7.53103C14.6735 7.58541 14.4932 7.58541 14.3258 7.53103C14.1356 7.4692 13.9706 7.3042 13.6405 6.97418L13.0262 6.3598Z"
            stroke="#364153"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      onClick: () => toastInform(t("productsPage.tooltips.text2")),
    },
    {
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0833 6.06527L9.99997 10.0005M9.99997 10.0005L2.91664 6.06527M9.99997 10.0005L10 17.9171M11.6667 17.4079L10.6475 17.9741C10.4112 18.1054 10.293 18.171 10.1679 18.1968C10.0571 18.2195 9.94288 18.2195 9.83213 18.1968C9.70698 18.171 9.58881 18.1054 9.35248 17.9741L3.18581 14.5481C2.93621 14.4095 2.8114 14.3401 2.72053 14.2415C2.64013 14.1543 2.57929 14.0509 2.54207 13.9382C2.5 13.8109 2.5 13.6681 2.5 13.3826V6.61835C2.5 6.33281 2.5 6.19005 2.54207 6.06271C2.57929 5.95007 2.64013 5.84667 2.72053 5.75942C2.8114 5.66081 2.93621 5.59148 3.18581 5.45281L9.35248 2.02688C9.58881 1.89558 9.70698 1.82993 9.83213 1.80419C9.94288 1.78141 10.0571 1.78141 10.1679 1.80419C10.293 1.82993 10.4112 1.89558 10.6475 2.02688L16.8142 5.4528C17.0638 5.59147 17.1886 5.66081 17.2795 5.75942C17.3599 5.84666 17.4207 5.95007 17.4579 6.06271C17.5 6.19004 17.5 6.33281 17.5 6.61835L17.5 10.4171M6.25 3.75048L13.75 7.91714M15.8333 17.5005V12.5005M13.3333 15.0005H18.3333"
            stroke="#364153"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      onClick: () => toastInform(t("productsPage.tooltips.text3")),
    },
    {
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6668 8.75033V5.66699C16.6668 4.26686 16.6668 3.5668 16.3943 3.03202C16.1547 2.56161 15.7722 2.17916 15.3018 1.93948C14.767 1.66699 14.067 1.66699 12.6668 1.66699H7.3335C5.93336 1.66699 5.2333 1.66699 4.69852 1.93948C4.22811 2.17916 3.84566 2.56161 3.60598 3.03202C3.3335 3.5668 3.3335 4.26686 3.3335 5.66699V14.3337C3.3335 15.7338 3.3335 16.4339 3.60598 16.9686C3.84566 17.439 4.22811 17.8215 4.69852 18.0612C5.2333 18.3337 5.93336 18.3337 7.3335 18.3337H10.0002M15.0002 17.5003V12.5003M12.5002 15.0003H17.5002"
            stroke="#364153"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      onClick: () => toastInform(t("productsPage.tooltips.text4")),
    },
  ];

  return (
    <div className="flex flex-col items-center gap-4 border-b py-6">
      <h2 className="text-sm font-medium text-[#111729]">
        {t("productsPage.additionalServices")}
      </h2>
      <div className="flex items-center justify-center gap-6">
        {buttons.map((button, index) => (
          <button
            key={index}
            onClick={button.onClick}
            aria-label={t(`productsPage.tooltips.text${index + 1}`)}
            className="flex h-10 w-10 items-center justify-center gap-2 rounded-lg bg-[#F5F7FA] p-2 transition-all hover:bg-slate-200"
          >
            {button.icon}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ServiceButtons;
