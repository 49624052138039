const categories = [
  {
    id: 1,
    label: "Bilgisayar / Tablet",
    value: "Bilgisayar / Tablet",
  },
  {
    id: 2,
    label: "Yazıcılar & Projeksiyon",
    value: "Yazıcılar & Projeksiyon",
  },
  {
    id: 3,
    label: "Telefon & Telefon Aksesuarları",
    value: "Telefon & Telefon Aksesuarları",
  },
  {
    id: 4,
    label: "TV, Görüntü & Ses Sistemleri",
    value: "TV, Görüntü & Ses Sistemleri",
  },
  {
    id: 5,
    label: "Beyaz Eşya",
    value: "Beyaz Eşya",
  },
  {
    id: 6,
    label: "Klima ve Isıtıcılar",
    value: "Klima ve Isıtıcılar",
  },
  {
    id: 7,
    label: "Elektrikli Ev Aletleri",
    value: "Elektrikli Ev Aletleri",
  },
  {
    id: 8,
    label: "Foto & Kamera",
    value: "Foto & Kamera",
  },
  {
    id: 9,
    label: "Oyun & Oyun Konsolları",
    value: "Oyun & Oyun Konsolları",
  },
  {
    id: 10,
    label: "Diğer",
    value: "Diğer",
  },
];

export default categories;
