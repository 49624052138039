import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import axios from "../../../lib/api/axios";
import AuthHeader from "../../../components/Auth/AuthHeader";

import * as yup from "yup";

import { parseError } from "@/lib/utils";

export default function ForgotPasswordMail() {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [isSend, setIsSend] = React.useState(false);

  const emailValidationSchema = yup.object().shape({
    email: yup
      .string(t("common.formErrors.emailInvalid"))
      .email(t("common.formErrors.emailInvalid"))
      .required(t("common.formErrors.emailRequired")),
  });

  const form = useForm({
    resolver: yupResolver(emailValidationSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data) => {
    axios
      .post(`/forgot-password/mail`, data)
      .then((res) => {
        toast({
          description: t("authPages.resetPasswordRequestSuccess"),
          variant: "success",
        });
        setIsSend(true);
      })
      .catch((err) => {
        const errorText = parseError(err.response.data.errors);
        toast({
          description: errorText,
          variant: "error",
        });
      });
  };

  if (isSend) {
    return (
      <div className="flex min-h-screen w-full bg-[#F9FAFC]">
        <div className="flex w-full flex-1 flex-col items-center justify-between px-7 pt-6">
          <AuthHeader />
          <div className="relative flex h-full w-full items-center justify-center">
            <h3 className="text-[16px] font-normal text-[#677389]">
              {t("authPages.resetPasswordRequestSent")}
            </h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen w-full bg-[#F9FAFC]">
      <div className="flex w-full flex-1 flex-col items-center justify-between px-7 pt-6">
        <AuthHeader />
        <Form
          {...form}
          className="relative flex h-full w-full items-center justify-center"
        >
          <div className="flex h-full w-full items-center justify-center">
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex w-full max-w-[400px] flex-col gap-5 rounded-lg md:bg-white md:p-6 md:shadow-default"
            >
              <div className="mb-2 flex flex-col gap-3">
                <h2 className="text-[24px] font-semibold">
                  {t("authPages.soYouForgotYourPassword")}
                </h2>
                <h3 className="text-[16px] font-normal text-[#677389]">
                  {t("authPages.enterEmailToCreateNewPassword")}
                </h3>
              </div>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("authPages.email")}</Label>
                    <FormControl>
                      <Input type="email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button disabled={!form.watch("email")} onClick={onSubmit}>
                {t("common.send")}
              </Button>
              <span className="text-center text-[14px] text-[#667085]">
                {t("authPages.wannaGoBackToLoginPage")}
                <a href="/login" className="ml-1 text-[#0049e6]">
                  {t("authPages.login")}
                </a>
              </span>
            </form>
          </div>
        </Form>
      </div>
    </div>
  );
}
