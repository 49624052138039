/* eslint-disable react/prop-types */
import { Children, cloneElement, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { cn } from "@/lib/utils";

const BreadcrumbContext = createContext();

const Breadcrumb = ({ children }) => {
  const navigate = useNavigate();
  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <BreadcrumbContext.Provider value={{ handleClick }}>
      <div className="flex h-[56px] w-full gap-2 bg-[#F2F5F9] px-6">
        {Children.map(children, (child, index) =>
          cloneElement(child, { index }),
        )}
      </div>
    </BreadcrumbContext.Provider>
  );
};

const BreadcrumbItem = ({ index, children, link, active }) => {
  const { handleClick } = useContext(BreadcrumbContext);
  return (
    <div
      className="flex items-center"
      onClick={() => !active && handleClick(link)}
    >
      {index !== 0 && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#97A2B6"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      <span
        className={cn(
          "cursor-pointer font-medium text-[#677389] underline",
          active && "text-[#0049E6] no-underline",
        )}
      >
        {" "}
        {children}
      </span>
    </div>
  );
};

export default Breadcrumb;
export { BreadcrumbItem };
