import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ReturnIcon } from "../../../assets/svgs/chevron-left.svg";

export default function PageHeader({
  title,
  returnPath,
  cartShow = false,
  isMobile = false,
}) {
  const navigateTo = useNavigate();
  return (
    <header className="sticky left-0 top-0 z-30 flex items-center justify-between border-b border-[#1018280d] bg-white px-4 py-4">
      <button
        onClick={() => navigateTo(returnPath ? returnPath : -1)}
        aria-label="geri dön"
      >
        <ReturnIcon />
      </button>
      <h2 className="font-semibold text-[#111729]">{title}</h2>
      <div></div>
    </header>
  );
}
