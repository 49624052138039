import {
  Dialog,
  DialogContent,
  Drawer,
  DrawerContent,
  DrawerFooter,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Slot from "@/components/common/shared/slot";

import { OTPInput } from "input-otp";

import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

const OtpVerifyDialog = ({ open, onOpenChange, phone, email, onVerify }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 640px)");
  const [code, setCode] = useState("");
  const [remainingTime, setRemainingTime] = useState(179);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (remainingTime === 0) {
      setIsResendDisabled(false);
    } else {
      setIsResendDisabled(true);
    }
  }, [remainingTime]);

  useEffect(() => {
    if (code.length === 6) {
      onVerify(code);
    }
  }, [code]);

  if (isMobile)
    return (
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerContent className="flex flex-col gap-4 bg-white">
          <div className="flex flex-col gap-4 p-4 py-0">
            <h2 className="text-2xl font-semibold">
              {t("authPages.verifyPhone")}
            </h2>
            <h3 className="text-[#677389]">
              {email?.length > 2
                ? t("authPages.verifyEmailDesc", { email })
                : t("authPages.verifyPhoneDesc", { phone })}
            </h3>
            <div className="flex flex-col items-center">
              <label className="text-xs font-medium text-[#677389]">
                {t("authPages.otpCode")}
              </label>
              <OTPInput
                value={code}
                onChange={(c) => setCode(c)}
                maxLength={6}
                containerClassName="flex justify-center gap-3 mt-5"
                render={({ slots }) => (
                  <div className="flex gap-3">
                    {slots.slice(0, 6).map((slot, idx) => (
                      <Slot key={idx} {...slot} />
                    ))}
                  </div>
                )}
              />
              {/* remaning time */}
              <div className="mt-4 flex items-center gap-2 ">
                <p className="text-sm text-gray-500">
                  {t("accountPage.remainingTime")} :
                </p>
                <p className="text-sm font-medium text-[#0049E6]">
                  {isResendDisabled
                    ? `${Math.floor(remainingTime / 60)}:${remainingTime % 60}`
                    : t("accountPage.resend")}
                </p>
              </div>
            </div>
          </div>
          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white">
        <div className="flex flex-col gap-4 pb-6">
          <h2 className="text-2xl font-semibold">
            {t("authPages.verifyPhone")}
          </h2>
          <h3 className="text-[#677389]">
            {email?.length > 2
              ? t("authPages.verifyEmailDesc", { email })
              : t("authPages.verifyPhoneDesc", { phone })}
          </h3>
          <div className="flex flex-col items-center">
            <label className="text-xs font-medium text-[#677389]">
              {t("authPages.otpCode")}
            </label>
            <OTPInput
              value={code}
              onChange={(c) => setCode(c)}
              maxLength={6}
              containerClassName="flex justify-center gap-3 mt-5"
              render={({ slots }) => (
                <div className="flex gap-3">
                  {slots.slice(0, 6).map((slot, idx) => (
                    <Slot key={idx} {...slot} />
                  ))}
                </div>
              )}
            />
            {/* remaning time */}
            <div className="mt-4 flex items-center gap-2 ">
              <p className="text-sm text-gray-500">
                {t("accountPage.remainingTime")} :
              </p>
              <p className="text-sm font-medium text-[#0049E6]">
                {isResendDisabled
                  ? `${Math.floor(remainingTime / 60)}:${remainingTime % 60}`
                  : t("accountPage.resend")}
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OtpVerifyDialog;
