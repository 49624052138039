import React from "react";

import { ReactComponent as EgarantiLogo } from "../../assets/svgs/egarantilogo.svg";
import LanguageSwitcher from "../common/LanguageSwitcher";

export default function AuthHeader() {
  return (
    <header className="flex w-full justify-between">
      <EgarantiLogo className="w-32" />
      <LanguageSwitcher />
    </header>
  );
}
