import { Button } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import Stepper from "@/components/Services/GetExtendedWarranty/Stepper";

import paymentApi from "@/lib/api/payment";

const StatusPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [payment, setPayment] = useState(null);

  const searchParams = new URLSearchParams(location.search);

  useEffect(
    () => {
      paymentApi
        .getPayment(searchParams.get("paymentId"))
        .then((res) => {
          setPayment(res);
        })
        .catch((err) => {
          setPayment({
            paymentResultStatus: "ERROR",
            errorMessage: err.message,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!payment) return <div>Loading...</div>;

  if (payment?.paymentResultStatus === "SUCCESS") {
    return (
      <div className="flex min-h-screen w-full flex-col items-center justify-center gap-[30px] bg-gray-50 p-6">
        <div className="mx-auto flex max-w-lg flex-col items-center gap-4">
          <Stepper
            steps={[
              { title: "Ürün Seçimi" },
              { title: "Plan Seçimi" },
              { title: "Ödeme" },
            ]}
            step={3}
          />
          <h1 className="text-center text-2xl font-semibold text-[#111729]">
            {t("payment.statusPage.title")}
          </h1>
          <p className="text-center font-medium text-[#7D7D7D]">
            {t("payment.statusPage.subtitle")}
          </p>
        </div>
        <div className="mx-auto flex max-w-lg flex-col items-center gap-5 rounded-lg bg-white p-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="121"
            fill="none"
            className="mb-5"
            viewBox="0 0 120 121"
          >
            <rect
              width="120"
              height="120"
              y="0.275"
              fill="#D1FADF"
              rx="60"
            ></rect>
            <path
              fill="#12B76A"
              fillRule="evenodd"
              d="M85.482 37.225l-35.8 34.55-9.5-10.15c-1.75-1.65-4.5-1.75-6.5-.35-1.95 1.45-2.5 4-1.3 6.05l11.25 18.3c1.1 1.7 3 2.75 5.15 2.75 2.05 0 4-1.05 5.1-2.75 1.8-2.35 36.15-43.3 36.15-43.3 4.5-4.6-.95-8.65-4.55-5.15v.05z"
              clipRule="evenodd"
            ></path>
          </svg>
          <h2 className="font-semibold text-[#020246]">
            {t("payment.statusPage.cardTitle")}
          </h2>
          <p className="text-center text-[#677389]">
            {t("payment.statusPage.cardDesc")}
          </p>
          <hr />
          <h3 className="w-full text-left font-medium text-black">
            {t("payment.statusPage.summary")}
          </h3>
          <div className="flex w-full  items-center justify-between gap-5 rounded-lg border bg-[#F9FAFC] p-10">
            <span className="font-medium text-[#364153]">
              {t("payment.statusPage.package")}
            </span>
            <span className="text-lg font-semibold text-[#364153]">
              {payment?.paidPrice}₺
            </span>
          </div>
          <Button className="w-full" asChild>
            <Link to="/services/get-extended-warranty/">
              {t("payment.statusPage.btnText")}
            </Link>
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex min-h-screen w-full flex-col items-center justify-center gap-[30px] bg-gray-50 p-6">
        <div className="mx-auto flex max-w-lg flex-col items-center gap-4">
          <h1 className="text-center text-2xl font-semibold text-[#111729]">
            {payment?.errorMessage || t("payment.statusPage.errorTitle")}
          </h1>
          <Button className="w-full" asChild>
            <Link to="/services/get-extended-warranty/step1">
              {t("payment.statusPage.errorBtnText")}
            </Link>
          </Button>
        </div>
      </div>
    );
  }
};

export default StatusPage;
