import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  PasswordInput,
  PhoneInput,
} from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

export default function LoginForm({ loginMethod = 0, form, onSubmit }) {
  const { t } = useTranslation();

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {loginMethod === 0 ? (
          <>
            <div className="flex gap-2">
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("authPages.phoneNumber")}</Label>
                    <FormControl>
                      <PhoneInput
                        defaultCountry="TR"
                        onChange={(country, value) => {
                          form.setValue("phone", value);
                          form.setValue("countryCode", country);
                        }}
                        value={form.getValues("phone")}
                        onBlur={field.onBlur}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </>
        ) : (
          loginMethod === 1 && (
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("authPages.email")}</Label>
                  <FormControl>
                    <Input type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )
        )}
      </form>
    </Form>
  );
}
