/* eslint-disable react/prop-types */
// import DealerIcon from "../../assets/icons/dealer.svg?react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@egaranti/components";

import { ReactComponent as PlusIcon } from "../../assets/svgs/plus1.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/svgs/rightArrow.svg";
import { ReactComponent as SearchIcon } from "../../assets/svgs/search.svg";
import { ReactComponent as SendIcon } from "../../assets/svgs/send.svg";

const AccordionHero = ({
  logo,
  triggerText,
  heading1,
  desc1,
  title2,
  desc2,
  title3,
  desc3,
  title4,
  desc4,
  title5,
  desc5,
  title6,
  desc6,
}) => {
  return (
    <Accordion className="bg-white" type="single" collapsible>
      <AccordionItem value="item-1">
        <AccordionTrigger className="flex min-h-[78px] justify-center gap-3 px-5">
          <span className="text-center text-2xl font-semibold text-[#364153]">
            {triggerText}
          </span>
        </AccordionTrigger>
        <AccordionContent>
          <div className="h-max w-full px-4 py-10">
            {logo}
            <p className="mx-auto mt-6 max-w-[580px] px-4 text-center text-sm text-[#364153]">
              {desc1}
            </p>
            <div className="mx-auto mt-16 flex max-w-[900px] items-center justify-between max-[1300px]:h-[800px] max-[1300px]:flex-col">
              <div className="flex h-[212px] w-[260px] flex-col items-center justify-center gap-2 rounded-md border border-gray-200 bg-white px-4 py-6 shadow-md">
                <h3 className="text-center text-xl font-semibold text-[#364153]">
                  {title2}
                </h3>
                <PlusIcon className="min-h-[28px] min-w-[28px]" />
                <p className="text-center text-sm">{desc2}</p>
              </div>
              <RightArrowIcon className="max-[1300px]:rotate-90" />
              <div className="flex h-[212px] w-[260px] flex-col items-center justify-center gap-2 rounded-md border border-gray-200 bg-white px-4 py-6 shadow-md">
                <h3 className="text-center text-xl font-semibold text-[#364153]">
                  {title3}
                </h3>
                <SendIcon className="min-h-[28px] min-w-[28px]" />
                <p className="text-center text-sm">{desc3}</p>
              </div>
              <RightArrowIcon className="max-[1300px]:rotate-90" />
              <div className="flex h-[212px] w-[260px] flex-col items-center justify-center gap-2 rounded-md border border-gray-200 bg-white px-4 py-6 shadow-md">
                <h3 className="text-center text-xl font-semibold text-[#364153]">
                  {title4}
                </h3>
                <SearchIcon className="min-h-[28px] min-w-[28px]" />
                <p className="text-center text-sm">{desc4}</p>
              </div>
            </div>
          </div>
          <div className="pb-10">
            <h2 className="text-center text-xl font-semibold text-[#364153]">
              {heading1}
            </h2>
            <div className="mt-4 flex min-h-[166px] flex-wrap items-center justify-center gap-9">
              <div className="flex min-h-[166px] w-[260px] flex-col gap-2 rounded-md border border-[#0040C9] p-6">
                <h3 className="text-center text-xl font-semibold text-[#364153]">
                  {title5}
                </h3>
                <p className="text-center text-sm text-[#364153]">{desc5}</p>
              </div>
              <div className="flex min-h-[166px] w-[260px] flex-col gap-2 rounded-md border border-[#0040C9] p-6">
                <h3 className="text-center text-xl font-semibold text-[#364153]">
                  {title6}
                </h3>
                <p className="text-center text-sm text-[#364153]">{desc6}</p>
              </div>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionHero;
