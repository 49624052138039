import { Navigate, useLocation } from "react-router-dom";

import useUser from "@/lib/utils/hooks/use-user";

const PrivateRoute = ({ children }) => {
  const { user, loading } = useUser();
  const location = useLocation();

  if (loading) {
    return <Skeleton rows={2} columns={3} height={48} />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
};

export default PrivateRoute;

const Skeleton = ({ rows = 2, columns = 3, height = 48 }) => {
  return (
    <div role="status" aria-label="Loading..." className="flex w-full flex-col">
      <div className="flex-1 flex-col gap-5 p-4">
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <div
            key={rowIndex}
            className={`grid grid-cols-${columns} mb-4 gap-4`}
          >
            {Array.from({ length: columns }).map((_, colIndex) => (
              <div
                key={colIndex}
                className={`h-${height} animate-pulse rounded bg-gray-100`}
              ></div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
