import { useEffect } from "react";

import useProductStore from "../../store/useProductStore";
import EmptyData from "../common/EmptyData";
import ProductCard from "./ProductCard";
import ProductSkeleton from "./ProductSkeleton";
import VerifiedProductDetailSheet from "./VerifiedProductDetailSheet";

import { motion } from "framer-motion";
import { t } from "i18next";

function VerifiedProductsList({ verifiedProducts, fetchProducts }) {
  const {
    selectedVerifiedProduct,
    setSelectedVerifiedProduct,
    openVerifiedDetailSheet,
    setOpenVerifiedDetailSheet,
    loading,
  } = useProductStore();

  const handleDetailModal = (product) => {
    setSelectedVerifiedProduct(product);
    setOpenVerifiedDetailSheet(true);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (loading) {
    return (
      <div className="flex w-full flex-col">
        <h2 className="mb-4 text-sm font-medium text-gray-500 md:text-base md:text-gray-800">
          {t("productsPage.approvedProducts")}
        </h2>
        <div className="grid grid-cols-1 items-start gap-5 md:grid-cols-3">
          {[...Array(6)].map((_, index) => (
            <ProductSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <h2 className="mb-4 text-sm font-medium text-gray-500 md:text-base md:text-gray-800">
        {t("productsPage.approvedProducts")}
      </h2>
      {verifiedProducts.length > 0 ? (
        <motion.div className="grid grid-cols-1 items-start gap-5 md:grid-cols-3">
          {verifiedProducts.map((product, index) => (
            <ProductCard
              product={product}
              onClick={() => handleDetailModal(product)}
              key={index}
            />
          ))}
        </motion.div>
      ) : (
        <EmptyData
          className="mx-auto max-w-[400px]"
          title={t("productsPage.noData")}
          description={t("productsPage.noDataDesc")}
        ></EmptyData>
      )}
      <VerifiedProductDetailSheet
        selectedProduct={selectedVerifiedProduct}
        open={openVerifiedDetailSheet}
        onOpenChange={setOpenVerifiedDetailSheet}
      />
    </div>
  );
}
export default VerifiedProductsList;
