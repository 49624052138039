import React from "react";
import { Link } from "react-router-dom";

export default function AccountMenuCard({ title, icon, text, link, ...props }) {
  return (
    <Link to={link}>
      <div
        className="h-full cursor-pointer rounded-lg border border-[#E3E7EF] bg-white p-6"
        {...props}
      >
        <div className="flex items-center gap-2">
          {icon}
          <h2 className="font-semibold text-[#111729]">{title}</h2>
        </div>
        <p className="mt-4 text-sm font-medium text-[#677389]">{text}</p>
      </div>
    </Link>
  );
}
