import { createRoot } from "react-dom/client";

import instance from "@/lib/api/axios";
import { isModalOpenSetter, processQueue } from "@/lib/api/requestInterceptor";
import { getUserIP } from "@/lib/utils";
import UserAgreementModal from "@/lib/utils/components/UserAgreementModal";

let isModalOpen = false;
const requestQueue = [];

// Handle User Agreement modal and process queued requests
export function handleUserAgreement(error) {
  return new Promise((resolve, reject) => {
    if (isModalOpen) {
      requestQueue.push(() =>
        handleUserAgreement(error).then(resolve).catch(reject),
      );
      return;
    }

    isModalOpen = true;
    isModalOpenSetter(true);
    showUserAgreementModal(resolve, reject, error.config);
  });
}

// Display User Agreement modal
function showUserAgreementModal(resolve, reject, originalConfig) {
  const modalRoot = createModalElement();
  const root = createRoot(modalRoot);

  root.render(
    <UserAgreementModal
      onAccept={(payload) =>
        handleAccept(root, modalRoot, resolve, reject, originalConfig, payload)
      }
      onReject={() => handleReject(root, modalRoot)}
    />,
  );
}

// Handle acceptance of User Agreement
function handleAccept(
  root,
  modalRoot,
  resolve,
  reject,
  originalConfig,
  payload,
) {
  cleanupModal(root, modalRoot);
  getUserIP().then((ip) => {
    instance
      .post("/individual-customers/agreement", {
        membershipAgreement: payload.membershipAgreement,
        commercialCommunicationAgreement:
          payload.commercialCommunicationAgreement,
        ip,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.jwtToken.substring(7));
        resolve(instance(originalConfig));
        processQueue();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error accepting user agreement:", error);
        reject(error);
      });
  });
}

// Handle rejection of User Agreement
function handleReject(root, modalRoot) {
  cleanupModal(root, modalRoot);
  window.location.href = "/login";
}

// Cleanup modal from DOM
function cleanupModal(root, modalRoot) {
  root.unmount();
  document.body.removeChild(modalRoot);
  isModalOpen = false;
  isModalOpenSetter(false);
  processQueue();
}

// Create a modal element and attach it to the DOM
function createModalElement() {
  const modalRoot = document.createElement("div");
  modalRoot.id = "user-agreement-modal";
  document.body.appendChild(modalRoot);
  return modalRoot;
}
