import {
  Button,
  Dialog,
  DialogContent,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  useToast,
} from "@egaranti/components";
import { DialogTitle } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

import LoginForm from "./LoginForm";
import LoginMethodSwitcher from "./LoginMethodSwitcher";

import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

const LoginModal = ({
  open,
  onOpenChange,
  form,
  onSubmit,
  loading,
  loginMethod,
  setLoginMethod,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 640px)");

  if (isMobile)
    return (
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerContent className="flex flex-col gap-4 bg-white">
          <div className="flex flex-col gap-4 p-5 pt-0">
            <h2 className="text-2xl font-semibold">{t("authPages.login")}</h2>
            <h3 className="text-[#677389]">{t("authPages.manageAll")}</h3>
            <LoginMethodSwitcher
              setLoginMethod={setLoginMethod}
              loginMethod={
                localStorage.getItem("userCountry") === "UZ" ? 1 : loginMethod
              }
              disabled={localStorage.getItem("userCountry") === "UZ"}
            />
            <LoginForm form={form} loginMethod={loginMethod} />
            <Button onClick={form.handleSubmit(onSubmit)} disabled={loading}>
              {t("authPages.login")}
            </Button>
          </div>
        </DrawerContent>
      </Drawer>
    );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white">
        <DialogTitle>
          <h2 className="text-2xl font-semibold">{t("authPages.login")}</h2>
        </DialogTitle>
        <h3 className="text-[#677389]">{t("authPages.manageAll")}</h3>
        <LoginMethodSwitcher
          setLoginMethod={setLoginMethod}
          loginMethod={
            localStorage.getItem("userCountry") === "UZ" ? 1 : loginMethod
          }
          disabled={localStorage.getItem("userCountry") === "UZ"}
        />
        <LoginForm form={form} loginMethod={loginMethod} />
        <Button onClick={form.handleSubmit(onSubmit)} disabled={loading}>
          {t("authPages.login")}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
