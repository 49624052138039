import { Button } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as AddIcon } from "../../assets/svgs/addplusicon.svg";
import { ReactComponent as FilterIcon } from "../../assets/svgs/filtericon.svg";
import useProductStore from "../../store/useProductStore";
import FilterPill from "../common/FilterPill";

const ProductListHeader = ({ fetchProducts }) => {
  const { t } = useTranslation();
  const { filters, setFilters, setOpenFilterSheet, setOpenAddProductSheet } =
    useProductStore();

  const filterPills = [
    {
      name: "name",
      text: filters.name,
    },
    {
      name: "mainCategory",
      text: filters.mainCategory,
    },
    {
      name: "category",
      text: filters.category,
    },
    {
      name: "subCategory",
      text: filters.subCategory,
    },
    {
      name: "brand",
      text: filters.brand,
    },
  ];

  const handleClearFilters = () => {
    setFilters({
      name: "",
      mainCategory: "",
      category: "",
      subCategory: "",
      brand: "",
    });

    fetchProducts({
      name: "",
      mainCategory: "",
      category: "",
      subCategory: "",
      brand: "",
    });
  };

  return (
    <div className="flex w-full flex-col gap-2 md:flex-row md:justify-end">
      <h2 className="font-medium text-gray-800 md:hidden">{t("Ürünler")}</h2>
      <div className="flex w-full flex-col-reverse items-center gap-4 md:flex-row md:justify-end">
        <div className="flex w-full items-center gap-2 overflow-y-auto md:justify-end">
          {filterPills.map((pill) => {
            if (!pill.text || pill.text === "{}" || pill.text === "[]")
              return null;
            return (
              <FilterPill
                onClick={() => {
                  handleClearFilters();
                }}
                key={pill.name}
                text={pill.text}
                name={pill.name}
              />
            );
          })}
        </div>
        <div className="flex w-full items-center justify-center gap-2 md:max-w-xs">
          <Button
            className="w-full"
            onClick={() => setOpenFilterSheet(true)}
            variant="secondaryGray"
          >
            <FilterIcon /> {t("filter.filter")}
          </Button>
          <Button
            className="w-full text-nowrap"
            onClick={() => setOpenAddProductSheet(true)}
          >
            <AddIcon /> {t("productsPage.addProduct")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductListHeader;
