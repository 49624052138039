import { handleUserAgreement } from "@/lib/api/userAgreementHandler";

const addResponseInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response || {};
      if (status === 403) {
        if (localStorage.getItem("token")) {
          return handleUserAgreement(error);
        } else {
          window.location.href = "/login";
        }
      }
      return Promise.reject(error);
    },
  );
};

export default addResponseInterceptor;
