import { Tag } from "@egaranti/components";

import CreateForm from "../../../components/Claims/Setup/Create/CreateForm";

import { t } from "i18next";

const SetupClaimForm = ({
  formData,
  setFormData,
  selectedProduct,
  setSelectedProduct,
  setStep,
}) => {
  return (
    <div className="flex w-full flex-col gap-6">
      <div
        role="button"
        onClick={() => setStep(0)}
        aria-label="Geri Dön"
        className="mx-auto flex w-full max-w-[320px] flex-col gap-3 rounded-lg border border-[#6692F0] bg-[#F3F5F9] px-6 py-4 font-medium shadow-sm"
      >
        <h2>{selectedProduct.name}</h2>
        <span className="text-[#677389]">{selectedProduct?.brand}</span>
        <Tag size="sm" variant={"yellow"}>
          {t(selectedProduct?.category.split(">").pop())}
        </Tag>
      </div>
      <div
        role="button"
        onClick={() => setStep(1)}
        aria-label="Geri Dön"
        className="mx-auto flex w-full max-w-[320px] gap-3 rounded-lg border border-[#6692F0] bg-[#F3F5F9] px-6 py-4 font-medium shadow-sm"
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.1316 7.63137C15.7356 7.23535 15.5376 7.03735 15.4634 6.80902C15.3981 6.60817 15.3981 6.39183 15.4634 6.19098C15.5376 5.96265 15.7356 5.76465 16.1316 5.36863L18.97 2.53026C18.2168 2.18962 17.3806 2 16.5002 2C13.1865 2 10.5002 4.68629 10.5002 8C10.5002 8.49104 10.5592 8.9683 10.6705 9.42509C10.7896 9.91424 10.8492 10.1588 10.8387 10.3133C10.8276 10.4751 10.8035 10.5612 10.7289 10.7051C10.6576 10.8426 10.5211 10.9791 10.248 11.2522L4.00023 17.5C3.1718 18.3284 3.1718 19.6716 4.00023 20.5C4.82865 21.3284 6.1718 21.3284 7.00023 20.5L13.248 14.2522C13.5211 13.9791 13.6576 13.8426 13.7951 13.7714C13.9391 13.6968 14.0251 13.6727 14.1869 13.6616C14.3414 13.651 14.586 13.7106 15.0751 13.8297C15.5319 13.941 16.0092 14 16.5002 14C19.8139 14 22.5002 11.3137 22.5002 8C22.5002 7.11959 22.3106 6.28347 21.97 5.53026L19.1316 8.36863C18.7356 8.76465 18.5376 8.96265 18.3092 9.03684C18.1084 9.1021 17.8921 9.1021 17.6912 9.03684C17.4629 8.96265 17.2649 8.76465 16.8689 8.36863L16.1316 7.63137Z"
            stroke="#667085"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {t("claimsPage.setupClaim")}
      </div>
      <CreateForm
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
      />
    </div>
  );
};

export default SetupClaimForm;
