import React from "react";
import { Route } from "react-router-dom";

import { Login, LoginJwt, Register } from "../pages/Auth";
import ForgotPasswordMail from "../pages/Auth/ForgotPassword/mail";
import ForgotPasswordVerify from "../pages/Auth/ForgotPassword/verify";
import {
  VerifyMail,
  VerifyOnboardMail,
  VerifyPhone,
  VerifyPhoneDirect,
  VerifyTrendyol,
} from "../pages/VerifyAccount";

export const authRoutes = (
  <>
    <Route path="/mail-verify" element={<VerifyMail />} />
    <Route path="/phone-verify" element={<VerifyPhoneDirect />} />
    <Route path="/onboard-phone" element={<VerifyPhone />} />
    <Route path="/onboard-email" element={<VerifyOnboardMail />} />
    <Route path="/mail-onboarding" element={<VerifyOnboardMail />} />
    <Route path="/trendyol-onboarding" element={<VerifyTrendyol />} />
    <Route path="/login" element={<Login />} />
    <Route path="/login/jwt" element={<LoginJwt />} />
    <Route path="/register" element={<Register />} />
    <Route path="/forgot-password/mail" element={<ForgotPasswordMail />} />
    <Route path="/password/reset" element={<ForgotPasswordVerify />} />
  </>
);
