import { Button } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as InsuranceIcon } from "@/assets/svgs/insurance.svg";

const HomePageBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex w-full flex-col gap-4 rounded-lg bg-white p-6 md:p-14 md:shadow-sm">
      <div className="flex items-center justify-between gap-6">
        <div className="flex flex-col gap-4">
          <h1 className="text-xl font-semibold text-[#364153] md:text-2xl">
            {t("services.homePageBanner.title")}
          </h1>
          <p className="font-medium text-[#677389]">
            {t("services.homePageBanner.subtitle")}
          </p>
          <Button
            onClick={() => {
              navigate("/services/get-extended-warranty/step1");
            }}
            className="mt-9 hidden w-full max-w-[640px] md:block"
            size="lg"
          >
            {t("services.homePageBanner.button")}
          </Button>
        </div>
        <InsuranceIcon />
      </div>
      <Button
        onClick={() => {
          navigate("/services/get-extended-warranty/step1");
        }}
        className="w-full md:mt-9 md:hidden"
        size="lg"
      >
        {t("services.homePageBanner.button")}
      </Button>
    </div>
  );
};

export default HomePageBanner;
