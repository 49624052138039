const ProductSkeleton = () => {
  return (
    <div className="animate-pulse flex h-[168px] w-full flex-col rounded-lg border border-gray-200 bg-white p-4">
      <div className="flex items-center gap-3">
        <div className="h-12 w-12 rounded-lg bg-gray-200"></div>
        <div className="flex flex-col gap-2">
          <div className="h-4 w-32 rounded bg-gray-200"></div>
          <div className="h-3 w-24 rounded bg-gray-200"></div>
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-2">
        <div className="h-3 w-full rounded bg-gray-200"></div>
        <div className="h-3 w-3/4 rounded bg-gray-200"></div>
      </div>
      <div className="mt-auto flex items-center justify-between">
        <div className="h-6 w-24 rounded-full bg-gray-200"></div>
        <div className="h-6 w-6 rounded bg-gray-200"></div>
      </div>
    </div>
  );
};

export default ProductSkeleton;
