import {
  Button,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

const FilterSheet = ({
  open,
  onOpenChange,
  setFilters,
  filters,
  fetchSetupClaims,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    individualCustomerProductName: "",
    setupClaimStatus: "",
  });

  const status = [
    { label: t("claimsPage.pending"), value: "PENDING" },
    { label: t("claimsPage.rejected"), value: "REJECTED" },
    { label: t("claimsPage.accepted"), value: "APPROVED" },
  ];

  const handleFilter = () => {
    fetchSetupClaims({
      ...filters,
      ...formData,
    });
    setFilters({
      ...filters,
      ...formData,
    });
  };

  const isMobile = useMediaQuery("(max-width: 640px)");

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className="m-4 rounded-lg bg-white p-4"
      >
        <SheetHeader>
          <SheetTitle>{t("filter.filter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Label>{t("filter.productName")}</Label>
            <Input
              onChange={(e) => {
                setFormData({
                  ...formData,
                  individualCustomerProductName: e.target.value,
                });
              }}
              value={formData.individualCustomerProductName}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label>{t("claimsPage.status")}</Label>
            <Select
              onValueChange={(value) => {
                setFormData({
                  ...formData,
                  setupClaimStatus: value,
                });
              }}
              defaultValue={formData.setupClaimStatus}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {status.map((element, index) => {
                  return (
                    <SelectItem key={index} value={element.value}>
                      {element.label}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
        </div>
        <SheetFooter className="mt-4">
          <Button
            onClick={() => {
              handleFilter();
              onOpenChange();
            }}
          >
            {t("common.save")}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
