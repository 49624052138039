import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import Footer from "../common/Footer";
import PageHeader from "../common/PageHeader";
import SideNav from "../common/SideNav";

import { motion } from "framer-motion";

function Layout() {
  const { t } = useTranslation();
  const location = useLocation();

  const headerTitle = () => {
    switch (location.pathname) {
      case "/":
        return `${t("navbar.welcome")}`;
      case "/overview/brands":
        return `${t("navbar.brands")}`;
      case "/account":
        return `${t("navbar.account")}`;
      case "/account/profile":
        return `${t("navbar.account")}`;
      case "/claims/service":
        return `${t("navbar.claims")}`;
      case "/claims/setup":
        return `${t("navbar.claims")}`;
      case "/products":
        return `${t("navbar.products")}`;
      case "/account/address":
        return `${t("navbar.account")}`;
      case "/account/new-address":
        return `${t("navbar.account")}`;
      case "/services":
        return `${t("navbar.services")}`;
      case "/services/get-extended-warranty":
        return `${t("navbar.services")}`;
      default:
        return "egaranti";
    }
  };

  return (
    <div data-tour="0" className="relative flex w-full justify-between">
      <SideNav />
      <main className="flex min-h-screen w-full flex-col overflow-auto bg-[#F9FAFC]">
        <PageHeader title={headerTitle()} />
        <div
          className="pb-24 md:pb-12"
          style={{
            WebkitOverflowScrolling: "touch",
          }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <Outlet />
          </motion.div>
        </div>
        <Footer stickyBottom={true} />
      </main>
    </div>
  );
}

export default Layout;
