import React from "react";

import { ReactComponent as InfoEmptyIcon } from "../../../assets/svgs/infoemptyicon.svg";

import { cn } from "@/lib/utils";

const EmptyData = ({
  children,
  title = "Veri bulunamadı",
  description,
  className,
  ...rest
}) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-2 py-8 text-[#111729]",
        className,
      )}
      {...rest}
    >
      <InfoEmptyIcon className="mb-4" />
      <h2 className="font-semibold">{title}</h2>
      <p className="text-center text-[#677389]">{description}</p>
      <div className="mt-4 flex flex-col items-center justify-center gap-2">
        {children}
      </div>
    </div>
  );
};

export default EmptyData;
