import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import EgarantiLogo from "../../../assets/images/egarantilogo.png";
import { ReactComponent as NotificationIcon } from "../../../assets/svgs/bellicon.svg";
import axios from "../../../lib/api/axios";
import NotificationModal from "../../Notifications/NotificationModal";

export default function PageHeader({ title }) {
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  const getUnreadNotificationsCount = async () => {
    axios.get(`/individual-customer-notifications/unread`).then((res) => {
      setUnreadNotificationsCount(res.data.count);
    });
  };
  useEffect(() => {
    //individual-customer-notifications/unread
    getUnreadNotificationsCount();
  }, []);

  return (
    <header className="flex items-center justify-between border border-[#1018280d] bg-white px-4 py-[14px] md:px-7 md:py-5">
      <div className="hidden md:block">
        <h3 className="text-2xl font-medium text-[#111729]">{title}</h3>
      </div>
      <div className="block md:hidden">
        <Link to="/">
          <img
            className="h-[32px] w-[134px]"
            src={EgarantiLogo}
            alt="egaranti"
          />
        </Link>
      </div>
      <div className="flex">
        <button
          onClick={(e) => {
            // don't effect useOnClickOutside hook in NotificationModal component
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            setIsNotificationModalOpen(!isNotificationModalOpen);
          }}
          aria-label="bildirimler"
          className="relative items-center justify-center pl-4 text-[#111729]"
        >
          <NotificationIcon />
          {unreadNotificationsCount > 0 && (
            <span className="absolute -right-2 -top-2 inline-flex h-5 w-5 items-center justify-center rounded-full bg-[#0049E6] text-[9px] font-medium text-white">
              <span className="sr-only">
                {unreadNotificationsCount} okunmamış bildirim var
              </span>
              {unreadNotificationsCount}
            </span>
          )}
        </button>
      </div>
      {isNotificationModalOpen && (
        <NotificationModal
          setIsNotificationModalOpen={setIsNotificationModalOpen}
          unreadNotificationsCount={unreadNotificationsCount}
          getUnreadNotificationsCount={getUnreadNotificationsCount}
        />
      )}
    </header>
  );
}
