import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useFetchContent from "@/lib/utils/hooks/useFetchContent";
import useSlideDrag from "@/lib/utils/hooks/useSlideDrag";

const Brands = () => {
  const { t } = useTranslation();
  const sliderRef = useRef(null);
  const { handleMouseDown, handleMouseLeave, handleMouseUp, handleMouseMove } =
    useSlideDrag(sliderRef);

  const goLeft = () => {
    sliderRef.current.scrollLeft -= 100;
  };
  const goRight = () => {
    sliderRef.current.scrollLeft += 100;
  };

  const {
    data: brands,
    loading,
    error,
  } = useFetchContent("app-home-page-brands", {
    filterString: `?sort=rank:asc`,
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content</div>;

  return (
    <div className="flex w-full flex-col gap-5 py-6">
      <div className="flex items-center justify-between">
        <h2 className="mb-4 font-medium text-gray-800">
          {t("homePage.brands")}
        </h2>
        <div className="hidden items-center justify-between gap-2 md:flex">
          <SliderButton direction="left" onClick={goLeft} />
          <SliderButton direction="right" onClick={goRight} />
        </div>
      </div>
      <div className="w-full overflow-hidden">
        <div
          ref={sliderRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          className="x-scroll flex cursor-move flex-row items-center justify-between gap-5 overflow-x-auto"
          style={{ scrollBehavior: "smooth" }}
        >
          {brands?.map((brand) => (
            <div
              key={brand.id}
              className="flex flex-shrink-0 flex-col items-center justify-center object-cover"
            >
              <img
                src={
                  "https://strapi.egaranti.co" +
                  (brand.attributes?.logo?.data?.attributes?.url || "")
                }
                alt={brand.attributes?.title || "Brand"}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SliderButton = ({ direction, ...rest }) => {
  return (
    <button
      {...rest}
      className="flex h-8 w-8 items-center justify-center rounded-full bg-[#F2F2F2]"
    >
      {direction === "left" ? (
        <svg
          fill="none"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 18L9 12L15 6"
            stroke="#0049E6"
            strokeWidth="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18L15 12L9 6"
            stroke="#0049E6"
            strokeWidth="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </button>
  );
};

export default Brands;
