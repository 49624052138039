import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArcelikLogo } from "@/assets/svgs/login/arcelik.svg";
import { ReactComponent as AygazLogo } from "@/assets/svgs/login/aygaz.svg";
import { ReactComponent as BraunLogo } from "@/assets/svgs/login/braun.svg";
import { ReactComponent as CasioLogo } from "@/assets/svgs/login/casio.svg";
import { ReactComponent as FakirLogo } from "@/assets/svgs/login/fakir.svg";
import { ReactComponent as LamyLogo } from "@/assets/svgs/login/lamy.svg";

const BrandsSection = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-medium text-[#111729]">
          {t("login.brands")}
        </h2>
        <></>
      </div>
      <div className="grid w-full grid-cols-6 place-items-center gap-4 rounded-lg bg-gradient-to-r from-[#0049E6] to-[#4E984E] p-6 md:p-10">
        <AygazLogo className="w-9 md:w-full" />
        <FakirLogo className="w-8 md:w-full" />
        <BraunLogo className="w-7 md:w-full" />
        <CasioLogo className="w-10 md:w-full" />
        <LamyLogo className="w-10 md:w-full" />
        <ArcelikLogo className="w-12 md:w-full" />
      </div>
    </div>
  );
};

export default BrandsSection;
