import React from "react";
import { Route } from "react-router-dom";

import CreateClaim from "../pages/Claims/CreateClaim.jsx";
import ServiceClaimDetailPage from "../pages/Claims/Service/ServiceClaimDetailPage.jsx";
import ServiceHomePage from "../pages/Claims/Service/index.jsx";
import SetupClaimDetailPage from "../pages/Claims/Setup/SetupClaimDetailPage.jsx";
import SetupHomePage from "../pages/Claims/Setup/index.jsx";
import PrivateRoute from "./PrivateRoute";

export const claimRoutesWithLayout = (
  <>
    <Route path="/claims/service" element={<ServiceHomePage />} />
    <Route path="/claims/setup" element={<SetupHomePage />} />
  </>
);

export const claimRoutesWithoutLayout = (
  <>
    <Route
      path="/claims/service/:id"
      element={
        <PrivateRoute>
          <ServiceClaimDetailPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims/setup/:id"
      element={
        <PrivateRoute>
          <SetupClaimDetailPage />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims/create"
      element={
        <PrivateRoute>
          <CreateClaim />
        </PrivateRoute>
      }
    />
  </>
);
