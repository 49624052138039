import {
  Button,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@egaranti/components";

import * as React from "react";
import { useTranslation } from "react-i18next";

import { format } from "date-fns";
// russian lang and english lang
import { enUS, ru, tr } from "date-fns/locale";
import { Calendar as CalendarIcon } from "lucide-react";

import i18n from "@/i18n";
import { cn } from "@/lib/utils";

interface DatePickerProps {
  selectedDate: Date | undefined;
  onDateChange: (date: Date | undefined) => void;
}

export function DatePicker({ selectedDate, onDateChange }: DatePickerProps) {
  const { t } = useTranslation();

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="secondaryGray"
            className={cn(
              "w-[280px] justify-start py-[14px] text-left",
              !selectedDate && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-1 h-4 w-4" />
            {selectedDate ? (
              format(selectedDate, "PPP")
            ) : (
              <span>{t("common.selectDate")}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto bg-white p-0">
          <Calendar
            locale={
              i18n.language === "tr" ? tr : i18n.language === "ru" ? ru : enUS
            }
            mode="single"
            selected={selectedDate}
            onSelect={onDateChange}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </>
  );
}
