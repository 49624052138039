import React from "react";
import { Route, Routes } from "react-router-dom";

import Layout from "../components/Layout";
import { Overview, Products } from "../pages";
import PrivateRoute from "./PrivateRoute";
import { accountRoutes } from "./accountRoutes";
import { authRoutes } from "./authRoutes";
import { claimRoutesWithLayout, claimRoutesWithoutLayout } from "./claimRoutes";
import { publicRoutes } from "./publicRoutes";
import {
  serviceRoutesWithLayout,
  serviceRoutesWithoutLayout,
} from "./serviceRoutes";

const AppRoutes = () => (
  <Routes>
    {authRoutes}
    {publicRoutes}
    {serviceRoutesWithoutLayout}
    {claimRoutesWithoutLayout}
    <Route path="/" element={<Layout />}>
      <Route
        index
        element={
          <PrivateRoute>
            <Overview />
          </PrivateRoute>
        }
      />
      <Route
        path="/products"
        element={
          <PrivateRoute>
            <Products />
          </PrivateRoute>
        }
      />
      {accountRoutes}
      {serviceRoutesWithLayout}
      {claimRoutesWithLayout}
    </Route>
  </Routes>
);

export default AppRoutes;
