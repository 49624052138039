import axios from "./axios";

export const getProducts = (params) => {
  const response = axios.get(`/unverified-product-templates`, { params });

  return response;
};

export const getExtendedWarraantyForProduct = async (productId) => {
  const response = await axios.get(`/extended-warranties/individual`, {
    params: { individualCustomerProductId: productId },
  });

  return response.data;
};
