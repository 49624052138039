import { Button } from "@egaranti/components";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as FilterIcon } from "../../../assets/svgs/filtericon.svg";
import FilterPill from "../../common/FilterPill";

function ProductTableHeader({
  setOpenFilterSheet,
  filters,
  setFilters,
  fetchSetupClaims,
}) {
  const { t } = useTranslation();
  const navigateTo = useNavigate();

  const handleClearFilters = () => {
    setFilters({
      individualCustomerProductName: "",
      setupClaimStatus: "",
    });

    fetchSetupClaims({
      individualCustomerProductName: "",
      setupClaimStatus: "",
    });
  };

  const status = [
    { label: t("claimsPage.pending"), value: "PENDING" },
    { label: t("claimsPage.rejected"), value: "REJECTED" },
    { label: t("claimsPage.accepted"), value: "APPROVED" },
  ];

  return (
    <div className="flex w-full flex-col gap-4 rounded-t-lg border-b border-[#E3E7EF] bg-white pr-4 pt-5 md:gap-4 md:px-6 md:pl-6">
      <h2 className="pl-4 font-medium text-gray-800 md:hidden">
        {t("Taleplerim")}
      </h2>
      <div className="flex w-full flex-col-reverse items-center gap-4 px-4 md:flex-row md:justify-end md:px-0">
        <div className="flex w-full items-center gap-2 overflow-y-auto md:justify-end">
          {filters.individualCustomerProductName && (
            <FilterPill
              text={filters.individualCustomerProductName}
              onClick={() => handleClearFilters()}
            />
          )}
          {filters.setupClaimStatus && (
            <FilterPill
              text={
                status.find((s) => s.value === filters.setupClaimStatus).label
              }
              onClick={() => handleClearFilters()}
            />
          )}
        </div>
        <div className="flex w-full items-center justify-center gap-2 md:max-w-xs">
          <Button
            variant="secondaryGray"
            onClick={() => setOpenFilterSheet(true)}
            className="w-full"
          >
            <FilterIcon />
            {t("filter.filter")}
          </Button>
          <Button
            onClick={() => navigateTo(`/claims/create`)}
            className="w-full"
          >
            {t("claimsPage.createClaim")}
          </Button>
        </div>
      </div>
      <div className="flex items-center gap-4 p-4 pb-0">
        <Link
          className="inline-block cursor-pointer pb-4 text-base font-medium leading-6 text-[#677389]"
          to="/claims/service"
        >
          {t("claimsPage.serviceClaims")}
        </Link>
        <Link
          className="inline-block cursor-pointer border-b-2 border-[#3662E3] pb-4 text-base font-medium leading-6 text-[#3662E3]"
          to="/claims/setup"
        >
          {t("claimsPage.setupClaims")}
        </Link>
      </div>
    </div>
  );
}

export default ProductTableHeader;
