import Brands from "@/components/Overview/Brands";
import CarouselBanner from "@/components/Overview/CarouselBanner";
import ExtraWarrantyBanner from "@/components/Overview/ExtraWarrantyBanner";
import OurServices from "@/components/Overview/OurServices";

const Overview = () => {
  return (
    <div className="flex w-full flex-col items-center overflow-x-hidden p-5 pb-[100px] md:pb-8">
      <CarouselBanner />
      <OurServices />
      <ExtraWarrantyBanner />
      <Brands />
    </div>
  );
};

export default Overview;
