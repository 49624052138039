import { Tag } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

const ProductCard = ({ selectedProduct, ...props }) => {
  const { t } = useTranslation();
  return (
    <div
      className="flex w-full max-w-[327px] flex-col gap-3 rounded-lg border border-[#6692F0] bg-[#F3F5F9] px-6 py-4 shadow-sm"
      {...props}
    >
      <h2 className="font-medium">{selectedProduct.name}</h2>
      <span className="text-[#677389]">{selectedProduct?.brand}</span>
      <Tag variant="yellow">
        {t(selectedProduct?.category.split(">").pop())}
      </Tag>
    </div>
  );
};

export default ProductCard;
