import React from "react";

import LoginCard from "./LoginCard";

import LanguageSwitcher from "@/components/common/LanguageSwitcher";

import bannerTexture from "@/assets/images/bannertexture.png";

const LoginHeroMobile = ({
  form,
  loginMethod,
  setLoginMethod,
  onSubmit,
  loading,
}) => {
  return (
    <div
      style={{
        background: `linear-gradient(to right, rgba(12, 31, 73, 1), rgba(16, 84, 224, 0.8), rgba(125, 156, 224, 0.8)), url(${bannerTexture})`,
      }}
      className="relative block md:hidden"
    >
      <div
        style={{
          background:
            "linear-gradient(to bottom, transparent 50%, #F9FAFC 50%)",
        }}
        className="flex h-full w-full justify-center pt-20"
      >
        <div className="absolute right-6 top-4 z-10 rounded-lg bg-white">
          <LanguageSwitcher />
        </div>
        <LoginCard
          form={form}
          loginMethod={loginMethod}
          setLoginMethod={setLoginMethod}
          onSubmit={onSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default LoginHeroMobile;
