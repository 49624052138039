import { useEffect, useState } from "react";

import strapi from "@/lib/api/strapi";

const useFetchContent = (contentType, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await strapi.getContentList(contentType, options);
        setData(response);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [contentType]);

  return { data, loading, error };
};

export default useFetchContent;
