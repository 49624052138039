/* eslint-disable react/prop-types */
import { Input, useToast } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";

import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { postAddress } from "@/lib/api/address";
import axios from "@/lib/api/axios";

const AddAddressDialog = ({ open, onOpenChange }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  const form = useForm({
    defaultValues: {
      title: "",
      firstName: "",
      lastName: "",
      cityId: "",
      districtId: "",
      addressLine: "",
      postCode: "",
    },
  });

  const getAllCities = () => {
    axios.get("/addresses/cities").then((res) => {
      setCities(res.data);
    });
  };

  const getDistricts = (id) => {
    axios.get(`/addresses/cities/${id}/districts`).then((res) => {
      setDistricts(res.data);
    });
  };

  useEffect(() => {
    getAllCities();
  }, []);

  const onSelectCity = (id) => {
    getDistricts(id);
  };

  const handleSave = (data) => {
    postAddress(data).then(() => {
      toast({
        title: "Başarılı",
        description: "Adres başarıyla eklendi.",
        variant: "success",
      });
    });

    form.reset();
    onOpenChange(false);
  };

  return (
    <AlertDialog onOpenChange={onOpenChange} open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t("payment.addAddressDialog.title")}
          </AlertDialogTitle>
        </AlertDialogHeader>
        {/* scroll area */}
        <div className="h-[500px] overflow-y-auto p-1">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSave)} className="">
              <div className="mx-auto mt-6 flex flex-col gap-5">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>{t("address.title")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex flex-col items-center gap-6 md:flex-row">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>{t("address.firstName")}</Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>{t("address.lastName")}</Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="cityId"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("address.city")}</Label>
                      <Select
                        onValueChange={(value) => {
                          onSelectCity(value);
                          field.onChange(value);
                        }}
                        defaultValue={field.cityId}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {cities.map((item) => {
                            return (
                              <SelectItem key={item.id} value={String(item.id)}>
                                {item.name}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="districtId"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("address.district")}</Label>
                      <Select onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {districts.map((item) => {
                            return (
                              <SelectItem key={item.id} value={String(item.id)}>
                                {item.name}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="addressLine"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>{t("address.addressLine")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="w-1/2">
                  <FormField
                    control={form.control}
                    name="postCode"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>{t("address.postCode")}</Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </form>
          </Form>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel id="cancel" onClick={() => onOpenChange(false)}>
            {t("common.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction onClick={form.handleSubmit(handleSave)}>
            {t("common.save")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AddAddressDialog;
