import React from "react";
import { Route } from "react-router-dom";

import FilePreview from "../pages/FilePreview";
import NotFound from "../pages/NotFound.jsx";

export const publicRoutes = (
  <>
    <Route path="/file-preview" element={<FilePreview />} />
    <Route path="/.well-known/assetlinks.json" element={null} />
    <Route path="/*" element={<NotFound />} />
  </>
);
