import React from "react";
import { Route } from "react-router-dom";

import Services from "../pages/Services";
import GetExtendedWarranty from "../pages/Services/GetExtendedWarranty";
import GetExtendedWarrantyStep1 from "../pages/Services/GetExtendedWarranty/Step1";
import GetExtendedWarrantyStep2 from "../pages/Services/GetExtendedWarranty/Step2";
import GetExtendedWarrantyStep3 from "../pages/Services/GetExtendedWarranty/Step3";
import StatusPage from "../pages/Services/GetExtendedWarranty/statusPage";
import PrivateRoute from "./PrivateRoute";

export const serviceRoutesWithLayout = (
  <>
    <Route path="/services" element={<Services />} />
    <Route
      path="/services/get-extended-warranty"
      element={
        <PrivateRoute>
          <GetExtendedWarranty />
        </PrivateRoute>
      }
    />
  </>
);

export const serviceRoutesWithoutLayout = (
  <>
    <Route
      path="/services/get-extended-warranty/step1"
      element={
        <PrivateRoute>
          <GetExtendedWarrantyStep1 />
        </PrivateRoute>
      }
    />
    <Route
      path="/services/get-extended-warranty/step2"
      element={
        <PrivateRoute>
          <GetExtendedWarrantyStep2 />
        </PrivateRoute>
      }
    />
    <Route
      path="/services/get-extended-warranty/step3"
      element={
        <PrivateRoute>
          <GetExtendedWarrantyStep3 />
        </PrivateRoute>
      }
    />
    <Route
      path="/services/get-extended-warranty/status"
      element={
        <PrivateRoute>
          <StatusPage />
        </PrivateRoute>
      }
    />
  </>
);
