// ✅
// Verfiy mail register pageden üye olan kullanıcılar içim mailine gelen tokenın doğrulandığı sayfa
import { Button } from "@egaranti/components";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import axios from "../../lib/api/axios";
import AuthHeader from "../../components/Auth/AuthHeader";
import Loading from "../../components/common/Loading";

export default function VerifyMail() {
  const { t } = useTranslation();
  const token = new URLSearchParams(window.location.search).get("hash");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigateTo = useNavigate();

  useEffect(() => {
    if (token) {
      axios
        .get(`/mail/verify?hash=${token}`)
        .then((res) => {
          setShowSuccess(true);
        })
        .catch((err) => {
          setShowSuccess(false);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 750);
        });
    }
  }, [token]);

  if (loading) return <Loading />;

  if (!showSuccess) {
    return (
      <div className="flex min-h-screen w-full bg-[#F9FAFC]">
        <div className="flex w-full flex-1 flex-col items-center justify-between px-7 pt-6">
          <AuthHeader />
          <div className="flex h-full w-full items-center justify-center">
            <div className="flex w-full max-w-[400px] flex-col gap-5 rounded-lg md:bg-white md:p-6 md:shadow-default">
              <div className="mb-2 flex flex-col items-center gap-3">
                <h2 className="text-[24px] font-semibold">Oppss</h2>
                <p className="text-center text-[16px] font-normal text-[#677389]">
                  {t("verifyPage.emailVerificationFail")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen w-full bg-[#F9FAFC]">
      <div className="flex w-full flex-1 flex-col items-center justify-between px-7 pt-6">
        <AuthHeader />
        <div className="flex h-full w-full items-center justify-center">
          <div className="flex w-full max-w-[400px] flex-col gap-5 rounded-lg md:bg-white md:p-6 md:shadow-default">
            <div className="mb-2 flex flex-col items-center gap-3">
              <h2 className="text-[24px] font-semibold">
                {t("verifyPage.emailVerified")}
              </h2>
              <p className="text-center text-[16px] font-normal text-[#677389]">
                {t("verifyPage.youCanLogin")}
              </p>
              <Button
                className="mt-5"
                size="full"
                onClick={() => navigateTo("/login")}
              >
                {t("verifyPage.login")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
