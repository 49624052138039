import { Button, Input, Label, Tag, useToast } from "@egaranti/components";
import { FormDescription } from "@egaranti/components";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import ImeiInfoDialog from "@/components/Services/GetExtendedWarranty/ImeiInfoDialog";
import Stepper from "@/components/Services/GetExtendedWarranty/Stepper";
import Breadcrumb, { BreadcrumbItem } from "@/components/common/shared/breadcrumbs";

import { motion } from "framer-motion";
import * as yup from "yup";

import axios from "@/lib/api/axios";
import { cn } from "@/lib/utils";

const GetExtendedWarrantyStep2 = () => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [newProductName, setNewProductName] = useState("");
  const [imei, setImei] = useState("");
  const [tc, setTc] = useState("");

  if (!location.state) navigate("/services/get-extended-warranty/step1");

  const onSubmit = () => {
    navigate("/services/get-extended-warranty/step3", {
      state: {
        product: location.state.product,
        selectedPackage,
        imei,
        tc,
      },
    });
  };

  const getOffers = async (e) => {
    setLoading(true);
    setPackages([]);
    axios
      .post(`/unverified-product-extended-warranties/offer`, {
        imei: e.target.value,
        unverifiedProductId: location.state.product.id,
      })
      .then((res) => {
        setPackages(res.data.offers);
        setNewProductName(res.data.name);
      })
      .catch((err) => {
        //setError(err.response.data?.errors[0]?.message);
        toast({
          variant: "error",
          title: err.response.data?.errors[0]?.message,
        });
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }, 500);
      });
  };

  const schema = yup.object().shape({
    tc: yup
      .string()
      .matches(/^[1-9]{1}[0-9]{10}$/, t("extendedWarrantyPage.step2.tcError"))
      .required(t("extendedWarrantyPage.step2.tcRequired")),
  });

  const handleImeiChange = (e) => {
    if (e.target.value.length > 15) return;
    setImei(e.target.value);
    if (e.target.value.length === 15) getOffers(e);
  };

  const newProductNameText = t("extendedWarrantyPage.step2.section0.title", {
    newProductName: `<span class="text-[#364153] font-semibold">${newProductName}</span>`,
  });

  return (
    <div className="min-h-screen md:bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/services">
          {t("services.services")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("extendedWarrantyPage.title")}
        </BreadcrumbItem>
      </Breadcrumb>
      <main className="mx-auto max-w-4xl p-5 md:py-12">
        <Stepper
          steps={[
            { title: t("extendedWarrantyPage.steps.step1") },
            { title: t("extendedWarrantyPage.steps.step2") },
            { title: t("extendedWarrantyPage.steps.step3") },
          ]}
          step={1}
        />
        <div className="flex flex-col rounded-lg">
          <h3 className="text-lg font-medium text-[#111729]">
            {t("extendedWarrantyPage.steps.step2")}
          </h3>
          ️
          <ProductCard
            name={location.state?.product.name}
            brand={location.state?.product.brand}
            category={location.state?.product.categoryName}
          />
        </div>
        <div className="mt-6 flex flex-col rounded-lg border bg-white p-6">
          <h3 className="text-lg font-medium text-[#111729]">
            {t("extendedWarrantyPage.step2.section1.title")}
          </h3>
          <div className="mt-4 flex flex-col gap-2">
            <Label className="flex items-center gap-2" htmlFor="ime">
              IMEI <ImeiInfoDialog />
            </Label>
            <Input
              value={imei}
              onChange={handleImeiChange}
              id="ime"
              type="tel"
              disabled={loading}
            />
            {newProductName && (
              <h2
                className="text-sm text-[#677389]"
                dangerouslySetInnerHTML={{ __html: newProductNameText }}
              />
            )}
            {/* <p className="text-sm text-[#677389]">{imei.length} / 15</p> */}
          </div>
        </div>
        <div className="mt-6 flex flex-col gap-2 rounded-lg border bg-white p-6">
          <h3 className="text-lg font-medium text-[#111729]">
            {t("extendedWarrantyPage.step2.section2.title")}
          </h3>
          <p className="text-sm text-[#677389]">
            {t("extendedWarrantyPage.step2.section2.description")}
          </p>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="flex flex-col gap-2">
              <Label htmlFor="tc">
                {t("extendedWarrantyPage.step2.section2.tc")}
              </Label>
              <Input
                value={tc}
                onChange={(e) => setTc(e.target.value)}
                id="tc"
              />
            </div>
          </div>
        </div>
        {/* <div
          aria-hidden={!error}
          role="alert"
          className={cn(
            "mt-4",
            { block: error, hidden: !error },
            "rounded-lg bg-red-500 p-4 text-white",
          )}
        >
          {error}
        </div> */}
        {packages.length > 0 && (
          <div className="background-animate mt-6 flex flex-col gap-2 rounded-lg border bg-white  bg-gradient-to-r from-blue-200 via-blue-100 to-blue-50 p-6">
            <h3 className="text-lg font-medium text-[#111729]">
              {t("extendedWarrantyPage.step2.section3.title")}
            </h3>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {packages.map((pack) => (
                <PackageCard
                  key={pack.id}
                  t={t}
                  pack={pack}
                  selectedPackage={selectedPackage}
                  setSelectedPackage={setSelectedPackage}
                />
              ))}
            </div>
          </div>
        )}
        <div className="my-4 flex justify-end">
          <Button
            className="w-full md:w-auto"
            onClick={onSubmit}
            disabled={!selectedPackage || !schema.isValidSync({ tc })}
          >
            {t("common.next")}
          </Button>
        </div>
      </main>
      {loading && (
        <div
          className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-white bg-opacity-80"
          aria-live="assertive"
          aria-atomic="true"
          role="status"
        >
          <motion.svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            fill="none"
            viewBox="0 0 25 25"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          >
            <motion.path
              stroke="#0049E6"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.222"
              d="M14.5 2.7v4.13c0 .56 0 .84.109 1.054a1 1 0 00.437.437c.214.109.494.109 1.054.109h4.13m-10.73 8l2 2 4.5-4.5m-1.5-11.5H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.31C4.5 4.71 4.5 5.55 4.5 7.23v10.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.31c.642.328 1.482.328 3.162.328h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311c.327-.642.327-1.482.327-3.162v-9.2l-6-6z"
              initial={{ pathLength: 0, stroke: "gray" }}
              animate={{ pathLength: 1, stroke: "#0049E6" }}
              transition={{ duration: 3, ease: "easeInOut", repeat: Infinity }}
            ></motion.path>
          </motion.svg>
        </div>
      )}
    </div>
  );
};

export default GetExtendedWarrantyStep2;

const ProductCard = ({ name, brand, category }) => {
  const navigate = useNavigate();
  return (
    <div
      role="button"
      onClick={() => navigate("/services/get-extended-warranty/step1")}
      aria-label="Ürün seçimine geri dön"
      className={cn(
        "flex flex-col gap-4 rounded-lg border border-[#0040C9] bg-[#F2F5F9] p-6 hover:bg-gray-50",
      )}
    >
      <h3 className="font-medium text-[#111729]">{name}</h3>
      <h4 className="text-sm text-[#677389]">{brand}</h4>
      <Tag size="sm" variant="yellow">
        {category}
      </Tag>
    </div>
  );
};

const PackageCard = ({ pack, selectedPackage, setSelectedPackage, t }) => {
  const GreenCheck = () => {
    return (
      <svg
        className="h-10 w-10"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4319 6.67058L8.96877 12.908L7.25371 11.0756C6.93777 10.7777 6.44131 10.7597 6.08024 11.0124C5.7282 11.2742 5.62891 11.7345 5.84555 12.1046L7.87655 15.4084C8.07513 15.7153 8.41815 15.9049 8.80629 15.9049C9.17639 15.9049 9.52843 15.7153 9.72701 15.4084C10.052 14.9841 16.2533 7.5913 16.2533 7.5913C17.0657 6.76084 16.0818 6.02968 15.4319 6.66155V6.67058Z"
          fill="#12B76A"
        />
      </svg>
    );
  };
  return (
    <div
      key={pack.id}
      className={cn(
        "flex cursor-pointer justify-between gap-2  rounded-lg border bg-white  p-4 transition-all hover:bg-slate-50",
        selectedPackage === pack && "border-[#0040C9]",
      )}
      role="button"
      aria-label={t("extendedWarrantyPage.step2.selectPackage")}
      aria-selected={selectedPackage === pack}
      onClick={() => setSelectedPackage(selectedPackage === pack ? null : pack)}
    >
      <div className="flex flex-col gap-2">
        <h4 className="text-sm font-medium text-[#111729]">{pack.offerName}</h4>
        <span className="text-xs font-medium text-[#677389]">
          {pack.extendedWarrantyDate}
        </span>
      </div>
      <div className="flex flex-col items-end justify-between gap-2">
        <h4 className="text-sm font-medium text-[#111729]">
          {pack.offerPrice}₺
        </h4>
      </div>
    </div>
  );
};
