import { Button } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

export default function FilePreview() {
  const url = new URL(window.location.href);
  const fileUrl = url.searchParams.get("url");
  const { t } = useTranslation();
  return (
    <div className="flex h-screen flex-col ">
      <header className="flex items-center justify-between border-b p-4 shadow-sm">
        {/* go back button */}
        <Button onClick={() => window.history.back()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          {t("common.back")}
        </Button>
        <div className="flex gap-2">
          <Button onClick={() => window.open(fileUrl, "_blank")}>
            {t("common.download")}
          </Button>
        </div>
      </header>
      <iframe
        src={fileUrl}
        title="file-preview"
        width={"100%"}
        height={"90vh"}
        className="m-4 mx-auto ml-4 flex-1"
      />
    </div>
  );
}
