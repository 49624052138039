import { useEffect, useState } from "react";

import axios from "../../api/axios";

const useUser = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/individual-customers/info")
      .then((res) => {
        setUser(
          res.data || {
            name: "Guest",
            email: "guest@example.com",
            phone: "081234567890",
            address: "Jl. Imam Bonjol",
          },
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { user, loading };
};

export default useUser;
