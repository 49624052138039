import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function parseError(errors: any[], defaultMessage: string) {
  if (errors.length > 0) {
    return errors.map((error) => <p key={error.message}>• {error.message}</p>);
  }
  return defaultMessage;
}

export async function getUserIP() {
  const response = await fetch("https://api.ipify.org?format=json");
  const data = await response.json();
  return data.ip;
}
