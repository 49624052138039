import React from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/lib/utils";

const LoginMethodSwitcher = ({ loginMethod, setLoginMethod, disabled }) => {
  const { t } = useTranslation();

  if (disabled) return null;

  return (
    <div className="mt-2 flex items-center justify-center rounded-xl bg-[#F2F5F9] p-1">
      <button
        onClick={() => setLoginMethod(0)}
        className={`flex  w-full cursor-pointer  items-center justify-center rounded-lg py-2 transition-all ${
          loginMethod === 0 && "bg-white"
        }`}
      >
        <span
          className={cn(
            "whitespace-nowrap text-xs font-medium text-[#677389] transition-all md:text-base",
            loginMethod === 0 && "text-[#111729]",
          )}
        >
          {t("authPages.loginWithPhone")}
        </span>
      </button>
      <button
        onClick={() => setLoginMethod(1)}
        className={`flex  w-full cursor-pointer  items-center justify-center rounded-lg py-2 transition-all ${
          loginMethod === 1 && "bg-white"
        }`}
      >
        <span
          className={cn(
            "whitespace-nowrap text-xs font-medium text-[#677389] transition-all md:text-base",
            loginMethod === 1 && "text-[#111729]",
          )}
        >
          {t("authPages.loginWithEmail")}
        </span>
      </button>
    </div>
  );
};

export default LoginMethodSwitcher;
