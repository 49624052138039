import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  useToast,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "../../../lib/api/axios";
import DocumentSection from "./DocumentSection";
import ProductDetails from "./ProductDetails";
import ServiceButtons from "./ServiceButtons";
import VideoSection from "./VideoSection";
import WarrantyDetails from "./WarrantyDetails";

import { getExtendedWarraantyForProduct } from "@/lib/api/product";
import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

const VerifiedProductDetailSheet = ({
  open,
  onOpenChange,
  selectedProduct,
}) => {
  const { t, i18n } = useTranslation();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [unverifiedDocuments, setUnverifiedDocuments] = useState([]);
  const [verifiedDocuments, setVerifiedDocuments] = useState([]);
  const { toast } = useToast();
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (open && selectedProduct?.id) {
      fetchProductDetails();
      fetchVerifiedDocuments();
    }

    return () => {
      setProduct({});
      setUnverifiedDocuments([]);
      setVerifiedDocuments([]);
    };
  }, [open, selectedProduct?.id]); // Add ?.id to dependency array

  const fetchProductDetails = async () => {
    setLoading(true); // Set loading true before fetch
    try {
      const res = await axios.get(
        `/individual-customer-products/${selectedProduct?.id}`,
      );

      if (res?.data) {
        const extendedWarrantyRes = await getExtendedWarraantyForProduct(
          selectedProduct?.id,
        );

        setProduct({
          ...res.data,
          extendedWarranty: extendedWarrantyRes,
        });
      } else {
        setProduct({});
      }

      setUnverifiedDocuments([
        {
          id: 1,
          name: t("productsPage.warrantyDocument"),
          documentUrl: res?.data?.warrantyFileUrl,
        },
        {
          id: 2,
          name: t("productsPage.serviceDocument"),
          documentUrl: res?.data?.serviceFileUrl,
        },
        {
          id: 3,
          name: t("productsPage.billingDocument"),
          documentUrl: res?.data?.billingFileUrl,
        },
      ]);
    } catch (err) {
      console.log(err);
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 750));
      setLoading(false);
    }
  };

  const fetchVerifiedDocuments = async () => {
    try {
      const res = await axios.get(
        `/merchant-product-document-histories?individualCustomerProductId=${selectedProduct?.id}`,
      );
      let documents = res.data;

      if (!documents.some((doc) => doc.type === "WARRANTY_DOCUMENT")) {
        documents.push({
          id: 100,
          name: t("productsPage.warrantyDocument"),
          type: "WARRANTY_DOCUMENT",
          documentUrl: `https://cdn.egaranti.com/default-warranty?individualCustomerProductId=${selectedProduct?.id}&lang=${i18n.language}`,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
      setVerifiedDocuments(documents);
    } catch (err) {
      console.log(err);
    }
  };

  const toastInform = (msg) => {
    toast({
      description: msg,
      variant: "primary",
    });
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className="rounded-lg bg-white p-4 md:m-4"
      >
        <SheetHeader>
          <SheetTitle>{t("productsPage.productDetail")}</SheetTitle>
        </SheetHeader>
        <div className="flex w-full flex-col">
          <ProductDetails loading={loading} product={product} />
          <ServiceButtons toastInform={toastInform} t={t} />
          <WarrantyDetails loading={loading} product={product} t={t} />
          <DocumentSection
            verifiedDocuments={verifiedDocuments}
            unverifiedDocuments={unverifiedDocuments}
            t={t}
          />
          {product?.videoData?.length > 0 && (
            <VideoSection product={product} t={t} />
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default VerifiedProductDetailSheet;
