import { Tag, useToast } from "@egaranti/components";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/common/shared/Carousel";

import axios from "@/lib/api/axios";

const MyServices = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get("/unverified-product-extended-warranties")
      .then((res) => {
        setData(res.data.content);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (data?.length === 0 || loading) return <></>;

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-semibold text-[#364153]">
        {t("extendedWarrantyPage.myServices")}
      </h2>
      <Carrousel data={data} />
    </div>
  );
};

export default MyServices;

const Carrousel = ({ data }) => {
  const { toast } = useToast();
  const { t } = useTranslation();

  return (
    <Carousel>
      <CarouselContent>
        {data.map((item) => (
          <CarouselItem className="md:basis-1/2">
            <div className="flex flex-col items-center justify-between gap-8 rounded-lg border bg-white p-5 md:flex-row md:gap-16">
              <div className="flex items-center gap-3">
                <div className="flex flex-col gap-2">
                  <h1 className="text-lg font-semibold text-[#111729]">
                    {item?.externalProductName}
                  </h1>
                  <span className="font-medium text-[#364153]">
                    {t("extendedWarrantyPage.totalPrice")}:{" "}
                    <b className="text-[#0049E6]">{item?.offerPrice}₺</b>
                  </span>
                  <span className="text-sm font-medium text-[#677389]">
                    {item?.warrantyStartDate} - {item?.warrantyExpireDate}
                  </span>
                  {
                    <Tag
                      size="sm"
                      variant={
                        item?.warrantyExpireDate < new Date().toISOString()
                          ? "green"
                          : "red"
                      }
                    >
                      {item?.warrantyExpireDate < new Date().toISOString()
                        ? t("extendedWarrantyPage.active")
                        : t("extendedWarrantyPage.inactive")}
                    </Tag>
                  }
                </div>
              </div>
              <div className="flex flex-col items-center gap-2 text-blue-600">
                <span className="text-[#111729]">
                  {t("extendedWarrantyPage.productName")}:{" "}
                  <b className="font-semibold">{item?.unverifiedProductName}</b>
                </span>
                <span className="text-[#111729]">
                  {t("extendedWarrantyPage.policyNo")}:{" "}
                  <b className="font-semibold">{item?.externalPolicyNo}</b>
                </span>
                <a
                  href={item?.policyDocumentUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium"
                >
                  {t("extendedWarrantyPage.download")}
                </a>
              </div>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};
