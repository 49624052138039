import useFetchContent from "@/lib/utils/hooks/useFetchContent";

const ExtraWarrantyBanner = () => {
  const {
    data: bannerData,
    loading,
    error,
  } = useFetchContent("app-home-page-banner");

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content</div>;

  return (
    <div className="my-6 flex w-full items-center justify-between rounded-lg bg-gradient-to-r from-[#0049E6] to-[#4E984E] p-6 md:p-10">
      <h4 className="text-lg font-semibold leading-8 text-white md:text-4xl">
        {bannerData?.attributes.text}
      </h4>
      <img
        src={
          bannerData?.attributes.image.data.attributes.url
            ? "https://strapi.egaranti.co" +
              bannerData.attributes.image.data.attributes.url
            : ""
        }
        alt={bannerData?.attributes.text}
      />
    </div>
  );
};

export default ExtraWarrantyBanner;
