import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "@egaranti/components";
import { DrawerTitle } from "@egaranti/components";
import { DrawerTrigger } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

import RecordGif from "@/assets/images/recimei.gif";

import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

const ImeiInfoDialog = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 640px)");

  if (isMobile)
    return (
      <Drawer>
        <DrawerTrigger aria-label="IMEI numaranızı nereden bulabilirim?">
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.49967 10.6667V8.00001M8.49967 5.33334H8.50634M15.1663 8.00001C15.1663 11.6819 12.1816 14.6667 8.49967 14.6667C4.81778 14.6667 1.83301 11.6819 1.83301 8.00001C1.83301 4.31811 4.81778 1.33334 8.49967 1.33334C12.1816 1.33334 15.1663 4.31811 15.1663 8.00001Z"
              stroke="#677389"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </DrawerTrigger>
        <DrawerContent className="flex flex-col gap-4 bg-white">
          <DrawerHeader className="text-left">
            <DrawerTitle>
              {t("services.getExtendedWarranty.imeiInfoDialog.title")}
            </DrawerTitle>
          </DrawerHeader>
          <img className="mx-auto h-56" src={RecordGif}></img>
          <ul className="list-disc px-10 text-center font-medium text-[#677389]">
            <li>{t("services.getExtendedWarranty.imeiInfoDialog.step1")}</li>
            <li>{t("services.getExtendedWarranty.imeiInfoDialog.step2")}</li>
            <li>{t("services.getExtendedWarranty.imeiInfoDialog.step3")}</li>
          </ul>
          <DrawerFooter className="pt-2">
            <DrawerClose asChild>
              <Button variant="secondaryColor">{t("common.back")}</Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );

  return (
    <Dialog>
      <DialogTrigger aria-label="IMEI numaranızı nereden bulabilirim?">
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.49967 10.6667V8.00001M8.49967 5.33334H8.50634M15.1663 8.00001C15.1663 11.6819 12.1816 14.6667 8.49967 14.6667C4.81778 14.6667 1.83301 11.6819 1.83301 8.00001C1.83301 4.31811 4.81778 1.33334 8.49967 1.33334C12.1816 1.33334 15.1663 4.31811 15.1663 8.00001Z"
            stroke="#677389"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </DialogTrigger>
      <DialogContent className="bg-white">
        <h2 className="text-lg font-semibold text-[#111729]">
          {t("services.getExtendedWarranty.imeiInfoDialog.title")}
        </h2>
        <img className="mx-auto h-56" src={RecordGif}></img>
        <ul className="list-disc px-10 text-center font-medium text-[#677389]">
          <li>{t("services.getExtendedWarranty.imeiInfoDialog.step1")}</li>
          <li>{t("services.getExtendedWarranty.imeiInfoDialog.step2")}</li>
          <li>{t("services.getExtendedWarranty.imeiInfoDialog.step3")}</li>
        </ul>
      </DialogContent>
    </Dialog>
  );
};

export default ImeiInfoDialog;
