import { Button } from "@egaranti/components";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as InsuranceIcon } from "@/assets/svgs/insurance.svg";
import { ReactComponent as RightArrow } from "@/assets/svgs/right-arrow.svg";

const ServicesSection = ({ triggerLoginModal }) => {
  const { t } = useTranslation();
  const userCountry = localStorage.getItem("userCountry");
  const data = [
    //  Insurance kartını sadece userCountry !== "UZ" olduğunda ekle
    ...(userCountry === "UZ"
      ? []
      : [
          {
            title: t("overview.ourServices.insurance"),
            description: t("overview.ourServices.insuranceDescription"),
            icon: <InsuranceIcon className="h-24 w-24" />,
            link: "/services/get-extended-warranty",
          },
        ]),
    {
      title: t("overview.ourServices.service"),
      description: t("overview.ourServices.serviceDescription"),
      icon: (
        <svg
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.7996 69.6974L23.7181 75.7487C22.5838 77.4304 20.9984 78.6426 19.2248 79.3335C17.4066 80.0416 15.3937 80.197 13.4719 79.7443L11.5367 79.2882L12.6467 77.6429L17.8022 69.9995L15.4584 64.2121L9.18877 64.1875L4.03219 71.8325L2.92288 73.4771L1.77538 71.854C0.635468 70.2416 0.0254822 68.316 0.000968933 66.3623C-0.0226898 64.4612 0.507011 62.5388 1.64083 60.8575L5.72262 54.8062C7.20929 52.6019 9.46349 51.2061 11.8974 50.7331C14.0526 50.3143 16.35 50.6215 18.3803 51.7345L51.1296 18.985C50.6706 17.7099 50.4799 16.3491 50.5762 14.9976C50.6923 13.3671 51.2218 11.7469 52.1957 10.3026L56.2775 4.2513C57.4117 2.56955 58.9972 1.35738 60.7709 0.666458C62.5892 -0.0416183 64.6019 -0.196991 66.5234 0.255676L68.4586 0.711815L67.3489 2.35712L62.1936 10.0005L64.5372 15.7879L70.8068 15.8125L75.9634 8.16753L77.0727 6.52287L78.2204 8.14603C79.3601 9.75813 79.9698 11.684 79.9944 13.6377C80.018 15.5388 79.4885 17.4612 78.3545 19.1425L74.2727 25.1938V25.1943C72.786 27.3981 70.5318 28.7939 68.0979 29.2669C65.9429 29.6857 63.6453 29.3785 61.6153 28.2655L28.8657 61.015C29.3247 62.2901 29.5154 63.6511 29.4193 65.0022C29.303 66.6329 28.7737 68.2533 27.7996 69.6974Z"
            fill="#CDD5E0"
          />
          <path
            d="M18.4148 51.7002L51.129 18.9863L61.6123 28.2689L28.8793 61.002L18.4148 51.7002Z"
            fill="#0040C9"
          />
          <path
            d="M52.0449 24.9051L55.1029 27.9631C55.9039 28.7638 55.9039 30.0743 55.1029 30.8752L30.8952 55.083C30.0942 55.8839 28.784 55.8837 27.983 55.083L24.9251 52.025C24.1241 51.2243 24.1243 49.9138 24.9251 49.1128L49.1328 24.9051C49.9337 24.1042 51.244 24.1042 52.0449 24.9051Z"
            fill="#002E90"
          />
          <path
            d="M68.9152 62.1328L69.5778 65.6325L70.2406 69.1322L67.5413 71.456L64.8414 73.7797L61.4795 72.6036L58.1174 71.4278L57.4547 67.9281L56.7919 64.4284L59.4914 62.1047L62.1911 59.7809L65.5531 60.9568L68.9152 62.1328ZM23.1446 18.9912L14.2959 20.5501L6.84659 11.8963C2.85625 17.8018 8.29284 24.7818 13.8683 27.0824C15.6688 27.8254 17.6129 28.1959 19.5612 28.1428L20.4918 28.1176L52.0898 64.8246C51.5287 68.1131 51.4692 70.5541 54.7306 74.3431C57.9922 78.1319 66.2269 79.7429 71.079 75.5659C75.9311 71.3892 75.5627 63.0053 72.3018 59.2175C69.0411 55.4295 66.6226 55.1268 63.2899 55.19L31.6888 18.4791L31.8523 17.5625C32.1945 15.6438 32.1177 13.6666 31.651 11.7756C30.2045 5.91596 24.101 -0.466136 17.6886 2.58448L25.1276 11.2263L23.1446 18.9912Z"
            fill="#447AED"
          />
          <path
            d="M31.8677 27.202C31.2067 26.4342 30.0484 26.3478 29.2806 27.0087C28.5128 27.6696 28.4264 28.8278 29.0872 29.5956L51.4719 55.5997C52.1326 56.3675 53.2908 56.4538 54.0586 55.7929C54.8263 55.1322 54.9129 53.9741 54.2522 53.2063L31.8677 27.202Z"
            fill="#0040C9"
          />
          <path
            d="M69.6039 68.9076L69.0045 65.7431L69.578 65.6333L69.0018 65.7424C68.9991 65.729 68.9973 65.716 68.9956 65.7028L68.4033 62.5742L65.3606 61.5101L65.553 60.9577L65.3595 61.5111C65.3422 61.5051 65.3254 61.4983 65.3089 61.4909L62.319 60.4449L59.874 62.5498L57.4287 64.6548L58.0278 67.8192L57.4545 67.929L58.0307 67.8199C58.033 67.8331 58.035 67.8463 58.0367 67.8595L58.629 70.9881L61.672 72.0522L61.4796 72.6046L61.673 72.0512C61.6901 72.0572 61.7073 72.064 61.7236 72.0714L64.7133 73.1172L67.1586 71.0125L69.6039 68.9076ZM70.1517 65.5254L70.8038 68.9693C70.8658 69.183 70.8025 69.4228 70.6231 69.5774L67.9236 71.9011L65.2254 74.2239L65.224 74.2249C65.0704 74.3567 64.853 74.4055 64.6482 74.3338L64.8416 73.7806L64.6476 74.3311L61.338 73.1736C61.3206 73.169 61.3031 73.1641 61.2859 73.1579L61.2863 73.1566L57.9635 71.9945C57.754 71.9373 57.5845 71.7653 57.5413 71.5378L58.1173 71.4287L57.5423 71.5368L56.8872 68.0768C56.8839 68.0639 56.8812 68.0511 56.8787 68.0381L56.88 68.0379L56.8798 68.0369H56.8808L56.2288 64.593C56.1668 64.3793 56.2298 64.1395 56.4094 63.9849L59.1089 61.6612L61.8071 59.3384L61.8084 59.3374C61.9619 59.2053 62.1795 59.1568 62.3843 59.2283L62.1909 59.7817L62.3848 59.2312L65.6945 60.3887C65.7119 60.3933 65.7293 60.3982 65.7466 60.4042L65.746 60.4057L69.069 61.5678C69.2786 61.625 69.448 61.7968 69.4912 62.0245L68.9152 62.1336L69.49 62.0255L70.1453 65.4856C70.1486 65.4982 70.1513 65.5112 70.1538 65.5242L70.1526 65.5244V65.5252L70.1517 65.5254Z"
            fill="#0049E6"
          />
          <path
            d="M7.69028 11.976C7.47907 11.7307 7.10893 11.703 6.8634 11.9142C6.61809 12.1254 6.5904 12.4958 6.80161 12.7413L13.8519 20.9313C14.0007 21.1043 14.2282 21.169 14.4355 21.1185L23.2465 19.5663L23.2467 19.5675C23.4999 19.5229 23.6863 19.3218 23.7244 19.0814L25.6937 11.3694L25.1278 11.2252L25.696 11.3702C25.7451 11.1774 25.6927 10.9825 25.5722 10.8424L18.5219 2.65198C18.3107 2.40666 17.9406 2.37897 17.695 2.59019C17.4497 2.8014 17.422 3.17154 17.6333 3.41706L24.4861 11.3783L22.673 18.479L14.5237 19.9145L7.69028 11.976Z"
            fill="#0049E6"
          />
        </svg>
      ),
      link: "/claims/service",
    },
    {
      title: t("overview.ourServices.packet"),
      description: t("overview.ourServices.packetDescription"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="116"
          height="80"
          fill="none"
          viewBox="0 0 116 80"
        >
          <path
            fill="#447AED"
            d="M62.877 25.506v54.493H18.79V25.506h44.087z"
          ></path>
          <path
            fill="#0040C9"
            d="M62.876 79.996V25.503h32.03v54.493h-32.03z"
          ></path>
          <mask
            id="mask0_8597_33843"
            style={{ maskType: "alpha" }}
            width="53"
            height="16"
            x="63"
            y="10"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#0055C9"
              d="M63.322 25.91h31.562l20.206-15.606H83.528L63.322 25.91z"
            ></path>
          </mask>
          <g mask="url(#mask0_8597_33843)">
            <path
              fill="#0040C9"
              d="M62.278 26.427c-1.429 1.103-.8 2 1.403 2H87.26c2.203 0 5.152-.897 6.58-2l15.029-11.608c1.429-1.104.8-2-1.403-2H83.888c-2.204 0-5.153.896-6.581 2L62.278 26.427z"
            ></path>
          </g>
          <mask
            id="mask1_8597_33843"
            style={{ maskType: "alpha" }}
            width="52"
            height="16"
            x="0"
            y="10"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#0055C9"
              d="M51.768 25.641H20.206L0 10.034H31.56l20.207 15.607z"
            ></path>
          </mask>
          <g mask="url(#mask1_8597_33843)">
            <path
              fill="#0040C9"
              d="M48.413 24.196c1.428 1.104.8 2-1.404 2H23.432c-2.204 0-5.153-.896-6.581-2L1.82 12.588c-1.428-1.103-.799-2 1.404-2h23.578c2.203 0 5.152.897 6.58 2l15.03 11.608z"
            ></path>
          </g>
          <path
            fill="#FEDF89"
            d="M52.235 6.761l-5.609 5.61-5.609-5.61 5.609-5.609 5.609 5.61z"
            opacity="0.201"
          ></path>
          <path
            fill="#FEC84B"
            d="M63.168 12.351l-2.566 2.567-2.567-2.567 2.567-2.567 2.566 2.567z"
            opacity="0.487"
          ></path>
          <path
            fill="#FEC84B"
            d="M58.975 2.567l-2.567 2.567-2.567-2.567L56.408 0l2.567 2.567z"
            opacity="0.974"
          ></path>
        </svg>
      ),
      link: "/claims/setup",
    },
  ];
  return (
    <div className="flex w-full flex-col py-6">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="mb-4 text-lg font-medium text-[#111729]">
          {t("navbar.services")}
        </h2>
        <Button
          size="sm"
          variant="secondaryGray"
          onClick={() => {
            triggerLoginModal();
          }}
        >
          {t("overview.ServicesSectionButton")}
        </Button>
      </div>
      <div className="grid w-full grid-cols-3 gap-4 md:flex md:gap-16">
        {data.map((item, index) => (
          <Card
            link={item.link}
            triggerLoginModal={triggerLoginModal}
            key={index}
            title={item.title}
            description={item.description}
            icon={item.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;
const Card = ({ title, icon, description, triggerLoginModal }) => {
  return (
    <Link
      onClick={() => triggerLoginModal()}
      className="flex w-full flex-col items-center justify-between gap-2"
    >
      <div className="flex w-full flex-col gap-3 rounded-lg bg-white p-4 shadow-md md:h-[240px]">
        <h3 className="hidden font-semibold text-[#364153] md:block">
          {title}
        </h3>
        <p className="hidden text-sm font-medium text-[#677389] md:block">
          {description}
        </p>
        <div
          aria-hidden="true"
          className="mt-auto flex w-full items-center justify-center md:justify-between"
        >
          {icon}
          <RightArrow className="hidden md:block" />
        </div>
      </div>
      {/* mobile title  */}
      <h3 className="text-sm font-semibold text-[#364153] md:hidden">
        {title}
      </h3>
    </Link>
  );
};
