import i18n from "../../i18n";

let isModalOpen = false;
const requestQueue = [];

// Function to add request interceptors
const addRequestInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    const language = i18n.language;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (language) {
      config.headers["x-language"] = language;
    }

    config.headers["X-Country"] = localStorage.getItem("userCountry") || "TR";
    return config;
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      if (isModalOpen) {
        return new Promise((resolve) =>
          requestQueue.push(() => resolve(config)),
        );
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
};

// Function to process queued requests
export const processQueue = () => {
  const queuedRequests = [...requestQueue];
  requestQueue.length = 0;
  queuedRequests.forEach((request) => request());
};

// Exports
export let isModalOpenSetter = (status) => {
  isModalOpen = status;
};
export default addRequestInterceptor;
