import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as EnglishFlag } from "../../../assets/svgs/english.svg";
import { ReactComponent as RussianFlag } from "../../../assets/svgs/russia.svg";
import { ReactComponent as TurkishFlag } from "../../../assets/svgs/turkey.svg";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [hoverMenusState, setHoverMenusState] = React.useState(false);

  const changeLanguage = (e) => {
    i18n.changeLanguage(e);
    localStorage.setItem("i18nextLng", e);
    setHoverMenusState({ ...hoverMenusState, language: false });
    window.location.reload();
  };

  return (
    <div className="mt-2 max-w-sm bg-white">
      <Select onValueChange={(e) => changeLanguage(e)} value={i18n.language}>
        <SelectTrigger>
          <SelectValue>
            <span className="flex items-center">
              {i18n.language === "tr" && (
                <>
                  <TurkishFlag className="mr-2 rounded-full" />
                  <span>Türkçe</span>
                </>
              )}
              {i18n.language === "en" && (
                <>
                  <EnglishFlag className="mr-2" />
                  <span>English</span>
                </>
              )}
              {i18n.language === "ru" && (
                <>
                  <RussianFlag className="mr-2 h-5 w-5 rounded-full" />
                  <span>Русский</span>
                </>
              )}
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="tr">Türkçe</SelectItem>
          <SelectItem value="en">English</SelectItem>
          <SelectItem value="ru">Русский</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};

export default LanguageSwitcher;
