import { Button, Tag } from "@egaranti/components";
import { Pagination } from "@egaranti/components";

import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//import AddProductSheet from "@/components/Products/AddProductSheet";
import Stepper from "@/components/Services/GetExtendedWarranty/Stepper";
import EmptyData from "@/components/common/EmptyData";
import Breadcrumb, {
  BreadcrumbItem,
} from "@/components/common/shared/breadcrumbs";
import AddNewProductSheet from "@/components/newaddproduct/ProductForm";

import PhonePhoto from "@/assets/images/overview/phone-photo.png";

import axios from "@/lib/api/axios";
import { cn } from "@/lib/utils";

const GetExtendedWarrantyStep1 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openAddProductSheet, setOpenAddProductSheet] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [pagination, setPagination] = useState({});

  const fetchProductsForPhoneInsurance = (
    pagination = { page: 1, size: 6, totalPages: 1 },
  ) => {
    axios
      .get("/unverified-products/extended-warranty", {
        params: {
          page: pagination.page,
          size: pagination.size,
        },
      })
      .then((res) => {
        setProducts(res.data.content);
        setPagination({
          page: res.data.page,
          size: res.data.size,
          totalPages: res.data.totalPages,
          totalElements: res.data.totalElements,
        });
      });
  };

  useEffect(() => {
    fetchProductsForPhoneInsurance();
  }, [openAddProductSheet]);

  return (
    <div className="min-h-screen md:bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/services">
          {t("services.services")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("extendedWarrantyPage.title")}
        </BreadcrumbItem>
      </Breadcrumb>
      <main className="mx-auto max-w-4xl pt-0 md:py-12">
        <div className="flex items-center justify-between gap-3 rounded-lg bg-gradient-to-b from-[#0037AD] to-[#447AED] p-6">
          <div className="flex flex-col gap-2">
            <h2 className="text-md font-semibold leading-8 text-white md:text-xl">
              {t("extendedWarrantyPage.banner.title")}
            </h2>
            <p className="md:text-md text-sm font-semibold text-[#E3E7EF]">
              {t("extendedWarrantyPage.banner.description")}
            </p>
          </div>
          <img src={PhonePhoto} alt="Phone" className="w-12" />
        </div>
        <Stepper
          steps={[
            { title: t("extendedWarrantyPage.steps.step1") },
            { title: t("extendedWarrantyPage.steps.step2") },
            { title: t("extendedWarrantyPage.steps.step3") },
          ]}
          step={0}
        />
        <div className="m-5 mt-6 flex flex-col gap-6 rounded-lg p-6 md:m-0 md:border md:bg-white">
          <header className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-[#111729]">
              {t("extendedWarrantyPage.steps.step1")}
            </h3>
            <button
              onClick={() => setOpenAddProductSheet(true)}
              className="font-medium text-[#0040C9] underline"
            >
              {t("productsPage.addProduct")}
            </button>
          </header>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {products.length === 0 && (
              <EmptyData
                className="col-span-2 mx-auto max-w-[600px]"
                title={t("productsPage.noData")}
                description={t("productsPage.noDataDesc2")}
              ></EmptyData>
            )}
            {products.map((product) => (
              <ProductCard
                key={product.id}
                onClick={() => {
                  setSelectedProduct(product.id);
                  navigate("/services/get-extended-warranty/step2", {
                    state: { product: product },
                  });
                }}
                name={product.name}
                category={product.categoryName}
                brand={product.brand}
                selected={product.id === selectedProduct}
              />
            ))}
            {/* if pagination.totalPages is higher than 6, show the pagination component */}
            <div className="md:col-span-2">
              {pagination.totalElements > 6 && (
                <Pagination
                  totalPages={pagination.totalPages}
                  currentPage={pagination.page}
                  pageSize={pagination.size}
                  onPageChange={(i) => {
                    fetchProductsForPhoneInsurance({ ...pagination, page: i });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </main>
      {/* <AddProductSheet
        open={openAddProductSheet}
        onOpenChange={setOpenAddProductSheet}
      /> */}
      <AddNewProductSheet
        open={openAddProductSheet}
        onOpenChange={setOpenAddProductSheet}
      />
    </div>
  );
};

export default GetExtendedWarrantyStep1;

const ProductCard = ({ name, brand, category, selected, onClick }) => {
  return (
    <button
      onClick={onClick}
      aria-selected={selected}
      aria-label={name}
      tabIndex={0}
      className={cn(
        "flex cursor-pointer flex-col gap-4 rounded-lg border p-6 shadow-md",
        selected ? "border-[#447AED]" : "border-[#E3E7EF]",
      )}
    >
      <h3 className="font-medium text-[#111729]">{name}</h3>
      <h4 className="text-sm text-[#677389]">{brand}</h4>
      <Tag size="sm" variant="yellow">
        {category}
      </Tag>
    </button>
  );
};
