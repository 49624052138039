import { Button } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/common/shared/Carousel";

import Autoplay from "embla-carousel-autoplay";

import useFetchContent from "@/lib/utils/hooks/useFetchContent";

const CarouselBanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const {
    data: carouselContent,
    loading,
    error,
  } = useFetchContent("app-home-page-slides", {
    filterString: `?filters[countries][code][$contains]=${localStorage.getItem(
      "userCountry",
    )}&sort=rank:asc`,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading)
    return (
      <div className="w-full overflow-hidden rounded-xl border border-[#EAECF0] bg-white shadow-sm">
        <div className="flex w-full flex-col items-center lg:flex-row lg:bg-[#fafafa]">
          <div className=" h-64 w-full bg-gray-200 lg:w-1/2" />
          <div className="mx-5 mt-4 flex w-full max-w-[400px] flex-col items-center justify-center gap-2 lg:max-w-[500px] lg:gap-6 lg:px-4">
            <div className=" h-8 w-full bg-gray-200" />
            <div className=" h-4 w-full bg-gray-200" />
            <div className=" h-4 w-3/4 bg-gray-200" />
            <div className=" h-10 w-full bg-gray-200" />
          </div>
        </div>
      </div>
    );
  if (error) return <div>Error loading content</div>;

  return (
    <Carousel
      plugins={[
        Autoplay({
          delay: 5000,
        }),
      ]}
      className="max-w-full overflow-hidden rounded-xl border border-[#EAECF0] bg-white shadow-sm"
    >
      <CarouselContent>
        {carouselContent?.map((item, index) => (
          <CarouselItem
            key={index}
            className="flex w-full flex-col items-center -justify-start lg:flex-row lg:bg-[#fafafa]"
          >
            <img
              className="md:max-h-[300px] lg:-ml-4 lg:w-1/2"
              src={
                "https://strapi.egaranti.co" +
                item.attributes.image.data[0].attributes.url
              }
              alt="banner"
            />
            <div className="mx-5 mt-4 flex max-w-[400px] flex-col items-center justify-center gap-2 lg:max-w-[500px] lg:gap-6 lg:px-4">
              <h4 className="w-full text-left text-sm font-semibold text-[#111729] lg:text-2xl 2xl:text-3xl">
                {item.attributes.title}
              </h4>
              <h6 className="self-start text-sm font-medium text-[#677389] lg:text-base">
                {item.attributes.desc}
              </h6>
              {item.attributes.buttonText && (
                <Button
                  variant="secondaryGray"
                  onClick={() => {
                    if (item.attributes.action) {
                      if (item.attributes.action.external) {
                        window.open(item.attributes.action.to, "_blank");
                      } else {
                        navigate(item.attributes.action.to);
                      }
                    }
                  }}
                  className="w-full text-xs"
                >
                  <span className="text-sm font-medium text-[#364153]">
                    {item.attributes.buttonText}
                  </span>
                </Button>
              )}
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      {!isMobile && <CarouselPrevious />}
      {!isMobile && <CarouselNext />}
    </Carousel>
  );
};

export default CarouselBanner;
