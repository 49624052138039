const DialCodes = [
  {
    label: "TR",
    value: "TR",
    countryCode: "+90",
    icon: "🇹🇷",
    id: 1,
  },
  {
    label: "UZ",
    value: "UZ",
    countryCode: "+998",
    icon: "🇺🇿",
    id: 2,
  },
];

export default DialCodes;
